import { createApp } from 'vue';
import { createPinia } from 'pinia';
import { i18n } from '../../plugins/i18n.js';

import { onCLS, onFCP, onINP, onLCP, onTTFB } from 'web-vitals';

import App from './App.vue';
import router from './router';
import mitt from 'mitt';

import VCalendar from 'v-calendar';
import 'v-calendar/style.css';

import directives from '@directives';

import { formatCurrency } from '@utils/formatCurrency';
import { formatProductNumber } from '@utils/numberFormatter';

import { makeServer, makeServerForCypress } from '@plugins/server';
import registerSW from '@/registerServiceWorker.js';

registerSW();

if (import.meta.env.VITE_APP_ENDPOINT_MODE === 'mck') {
	makeServer();
}

const app = createApp(App);

if (window.Cypress) {
	Cypress.on(
		'uncaught:exception',
		() =>
			// returning false here prevents Cypress from
			// failing the test
			false
	);

	makeServerForCypress();
}

// Envía las métricas de rendimiento a google analytics.
if (window.dataLayer) {
	const sendToGoogleAnalytics = ({ name, delta, value, id }) => {
		window.dataLayer.push({
			event: 'coreWebVitals',
			webVitalsMeasurement: {
				name,
				id,
				value,
				delta,
				valueRounded: Math.round(name === 'CLS' ? value * 1000 : value),
				deltaRounded: Math.round(name === 'CLS' ? delta * 1000 : delta),
			},
		});
	};

	onCLS(sendToGoogleAnalytics);
	onFCP(sendToGoogleAnalytics);
	onINP(sendToGoogleAnalytics);
	onLCP(sendToGoogleAnalytics);
	onTTFB(sendToGoogleAnalytics);
}

Object.entries(directives).forEach(([name, directive]) => {
	app.directive(name, directive);
});

app.config.globalProperties.$nc = formatCurrency;
app.config.globalProperties.$pn = formatProductNumber;

const pinia = createPinia();

const emitter = mitt();

pinia.use(({ store }) => {
	store.$emitter = emitter;
});

app.use(pinia);

app.use(i18n);

app.use(router);

app.use(VCalendar);

app.mount('#app');

// Simular Vuex 'dispatch' en la propiedad global $store para cualquier acción en hibridacion
const store = {
	dispatch(action, payload) {
		const [storeName, methodName] = action.split('/'); // Separamos el store y la acción
		const realStoreName = `m-${storeName}`;
		const store = pinia._s.get(realStoreName);
		if (typeof store[methodName] === 'function') {
			store[methodName](payload);
		}
	},
};

// Si es una app híbrida Android, limitar el número de eventos
if (useAppStore().isHybridSky && !useAppStore().isIOS) {
	const originalAddEventListener = window.addEventListener;

	// Objeto para rastrear los contadores de eventos
	window.eventListenersCount = {};

	// Objeto para definir el número máximo de eventos permitidos por tipo
	window.maxEventListeners = {
		'native-hide-loading': 2,
		'native-copy-text': 2,
		'native-open-external-link': 2,
		'native-save-file': 2,
		'native-share-file': 2,
		'native-exit-app': 2,
		'native-open-whatsapp': 2,
		'native-open-email': 2,
		'native-open-telephone': 2,
		'native-set-theme': 2,
		'native-get-wallet-status': 2,
		'native-get-wallet-state': 2,
		'native-get-wallet-data': 2,
		'native-add-card-to-wallet': 2,
		'native-start-card-provisioning': 2,
	};

	window.addEventListener = function (type, listener, options) {
		if (window.maxEventListeners[type] !== undefined) {
			// Inicializar el contador si no existe
			if (!window.eventListenersCount[type]) {
				window.eventListenersCount[type] = 0;
			}

			// Si se alcanzó el límite, salir
			if (window.eventListenersCount[type] >= window.maxEventListeners[type]) {
				return;
			}

			// Incrementar el contador solo si el evento tiene un límite
			window.eventListenersCount[type] += 1;
		}

		// Registrar el evento de manera normal
		originalAddEventListener.call(window, type, listener, options);
	};
}

window.__app__ = { ...app, $store: store };
