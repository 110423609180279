import { defineStore } from 'pinia';
import { useServiceStore } from '@modules/service/m-service';
import { useProfilesStore } from '../profiles/m-profiles';

export const useContractsStore = defineStore('m-contracts', {
	state: () => ({
		connectedContract: null,
		isSegurosContract: false,
	}),

	actions: {
		setConnectedContract(contract) {
			this.connectedContract = contract;
		},

		setIsSegurosContract(value) {
			this.isSegurosContract = value;
		},

		reset() {
			this.connectedContract = null;
		},

		get() {
			const serviceStore = useServiceStore();
			return serviceStore
				.request({
					service: {
						request: {
							url: '/contracts',
							method: 'GET',
						},
					},
				})
				.then(({ data }) => data)
				.catch((err) => err);
		},

		set(contract) {
			const { connectedContract } = this;

			if (connectedContract?.id === contract?.id) {
				return;
			}

			const { id, description } = contract;
			const lowerCaseDescription = description?.toLowerCase();

			if (lowerCaseDescription) {
				this.setIsSegurosContract(lowerCaseDescription === 'seguros');
			}

			const url = `/contracts/${id}`;
			const method = 'PATCH';

			const serviceStore = useServiceStore();

			return serviceStore
				.request({
					service: {
						request: {
							url,
							method,
						},
					},
				})
				.then(({ data }) => {
					const profilesStore = useProfilesStore();
					profilesStore.get();
					this.setConnectedContract(data);
					return data;
				})
				.catch((error) => Promise.reject(error));
		},

		getInterveners(idContract) {
			const serviceStore = useServiceStore();

			return serviceStore
				.request({
					service: {
						request: {
							url: `/contracts/${idContract}/interveners`,
							method: 'GET',
						},
					},
				})
				.then(({ data }) => data)
				.catch(() => {});
		},
	},
});
