import { defineStore } from 'pinia';
import { useServiceStore } from '@modules/service/m-service';
import SessionCache from '@plugins/cache/index';
import { MANAGEMENT_OFFICE_CODE, VACANT_MANAGER_CODE } from '@/store/modules/service/constants';
import { i18n } from '@/plugins/i18n';

const cache = new SessionCache('sirvase');

export const useSirvaseStore = defineStore('m-sirvase', {
	state: () => ({
		description: '',
		savedPersonalManagers: [],
	}),
	getters: {
		isDisplayingCatAgent: (state) =>
			state.savedPersonalManagers.length === 0 ||
			(state.savedPersonalManagers.length === 1 &&
				state.savedPersonalManagers[0].type === MANAGEMENT_OFFICE_CODE),

		getCatAgent: () => {
			const email =
				useSessionStore().vertical === 1 ? 'cat-ingenieria@cbnk.es' : 'cat-salud@cbnk.es';
			const phoneNumber = useSessionStore().vertical === 1 ? '913109550' : '917023207';
			return {
				type: MANAGEMENT_OFFICE_CODE,
				typeDescription: 'GESTOR BANCA PRIVADA',
				isCat: true,
				managerInfo: {
					name: i18n.global.t('SIRVASE.CUSTOMER_SERVICE_CENTER'),
					phoneNumber,
					email,
				},
			};
		},

		personalManagers: (state) =>
			state.isDisplayingCatAgent
				? [state.getCatAgent]
				: state.savedPersonalManagers.filter((pm) => pm.type !== VACANT_MANAGER_CODE),
	},

	actions: {
		setDescription(value) {
			this.description = value;
		},

		reset() {
			this.description = null;
			this.savedPersonalManagers = [];
			cache.clear();
		},

		get({ paginationKey, requestId } = {}) {
			const queryParams = {};
			let url = '/customer-support/request';
			const method = 'GET';

			if (requestId) {
				url = `${url}/${requestId}`;
			}

			/* istanbul ignore next */
			if (paginationKey) {
				Object.assign(queryParams, { paginationKey });
			}

			const serviceStore = useServiceStore();

			return serviceStore
				.request({
					service: {
						request: {
							url,
							method,
						},
					},
					queryParams,
				})
				.then(({ data }) => data);
		},

		request({ payload }) {
			const url = '/customer-support/request';
			const method = 'POST';

			const serviceStore = useServiceStore();

			return serviceStore.request({
				service: {
					request: {
						url,
						method,
					},
				},
				payload,
			});
		},

		setRequestStatus({ requestId, status }) {
			const url = `/customer-support/request/${requestId}`;
			const method = 'PATCH';

			const serviceStore = useServiceStore();

			return serviceStore
				.request({
					service: {
						request: {
							url,
							method,
						},
					},
					payload: { status },
				})
				.then(({ data }) => data);
		},

		getTypologies() {
			const url = '/customer-support/typology';

			const serviceStore = useServiceStore();

			return serviceStore
				.request({
					service: {
						request: {
							url,
							method: 'GET',
						},
					},
				})
				.then(({ data }) => data);
		},

		getPersonalManagers() {
			const url = '/customer-support/current/user/personal-managers';
			const serviceStore = useServiceStore();

			const key = 'sirvase/personal-managers';
			if (cache.has(key)) {
				return Promise.resolve(cache.get(key));
			}

			return serviceStore
				.request({
					service: {
						request: {
							url,
							method: 'GET',
						},
					},
				})
				.then(({ data }) => {
					this.savedPersonalManagers = data.data.data.filter(
						(pm) => pm.type !== VACANT_MANAGER_CODE
					);
					this.savedPersonalManagers.sort((a, b) => {
						const getSortValue = (manager) => {
							if (!manager.type || typeof manager.type !== 'string') return Infinity;
							const parts = manager.type.split('_');
							return parts.length > 1 ? parseInt(parts[1], 10) || Infinity : Infinity;
						};

						return getSortValue(a) - getSortValue(b);
					});
					cache.set(key, this.savedPersonalManagers);
				})
				.catch(() => []);
		},

		ratePersonalManager(payload) {
			const url = '/customer-support/current/user/personal-managers';
			const method = 'POST';

			const serviceStore = useServiceStore();

			return serviceStore
				.request({
					service: {
						request: {
							url,
							method,
						},
					},
					payload,
				})
				.then(() => {
					this.savedPersonalManagers.map((pm) => {
						if (pm.id === payload.agentId) {
							pm.managerInfo.evaluation = payload.evaluation;
							pm.managerInfo.comment = payload.comment;
						}
						return pm;
					});
				});
		},
	},
});
