const isHybrid = navigator.userAgent.includes('Skybrid');
const home = 'global';

const commonRoutes = [
	{
		path: '/:pathMatch(.*)*', // Cambiado para Vue Router 4
		redirect: { name: 'login' },
	},
	{
		path: '/entry',
		name: 'entry',
	},
	{
		path: '/',
		name: 'login',
		component: () => import('@views/v-cbnk-login.vue'),
	},
	{
		path: '/home',
		name: 'home',
		redirect: { name: home },
	},
	{
		path: '/sso-rsi-form/:userId/:tokenSSO/:usuarioAgente?',
		props: { default: true },
		name: 'sso-rsi-form',
		components: {
			default: () => import(/* webpackChunkName: "v-sso-rsi-form" */ '@views/v-sso-rsi-form'),
		},
	},
];

export default [
	...commonRoutes,
	{
		path: '/blocking-communication/',
		name: 'blocking-communication',
		props: { default: true },
		components: {
			default: () => import('@views/v-cbnk-blocking-communication.vue'),
		},
		children: [
			{
				path: 'acceptance',
				name: 'acceptance',
				components: {
					default: () => import('@views/v-cbnk-acceptances.vue'),
				},
			},
		],
	},
	{
		path: '/main/',
		name: 'main',
		props: { default: true },
		components: {
			default: () => import('@views/v-cbnk-main.vue'),
		},
		children: [
			{
				path: 'sso-rsi',
				name: 'sso-rsi',
				props: { primary: true },
				meta: {
					isFullWidth: true,
					transition: 'fade',
				},
				components: {
					primary: () => import(/* webpackChunkName: "v-sso-rsi" */ '@views/v-sso-rsi'),
				},
			},
			{
				path: 'sso-lighthouse/:operative/:productId',
				name: 'sso-lighthouse',
				props: { primary: true },
				meta: {
					isFullWidth: true,
					transition: 'fade',
				},
				components: {
					primary: () => import('@views/v-sso-lighthouse'),
				},
			},
			{
				path: 'linea-caminos',
				name: 'linea-caminos',
				props: { primary: true },
				meta: {
					isFullWidth: true,
					transition: 'fade',
				},
				components: {
					primary: () =>
						import(/* webpackChunkName: "v-linea-caminos" */ '@views/v-linea-caminos'),
				},
			},
			{
				path: 'bolsa-caminos',
				name: 'bolsa-caminos',
				props: { primary: true },
				meta: {
					isFullWidth: true,
					transition: 'fade',
				},
				components: {
					primary: () => import('@views/v-bolsa-caminos'),
				},
			},
			// 		{
			// 			path: 'tax-info',
			// 			name: 'tax-info',
			// 			props: { primary: { action: 'InformacionFiscal' } },
			// 			meta: {
			// 				isFullWidth: true,
			// 				transition: 'fade',
			// 			},
			// 			components: {
			// 				primary: () =>
			// 					import(
			// 						/* webpackChunkName: "v-linea-caminos" */ '@views/v-linea-caminos'
			// 					),
			// 			},
			// 		},
			{
				path: 'global',
				name: 'global',
				props: { primary: true },
				meta: {
					isFullWidth: true,
					entryPoint: isHybrid,
				},
				components: {
					primary: () => import('@views/v-global.vue'),
				},
			},
			{
				path: 'investment-account/:familyId/:productId',
				name: 'investment-account',
				props: { primary: true },
				meta: {
					isFullWidth: true,
					isNavDisabled: true,
				},
				components: {
					primary: () => import('@views/v-investment-account'),
				},
			},
			{
				path: 'apple-pay/:productId',
				name: 'apple-pay',
				props: {
					primary: true,
					secondary: true,
				},
				components: {
					primary: () => import('@views/v-global'),
					secondary: () => import('@views/v-apple-pay'),
				},
			},
			{
				path: 'google-pay/:productId',
				name: 'google-pay',
				props: {
					primary: true,
					secondary: true,
				},
				components: {
					primary: () => import('@views/v-global'),
					secondary: () => import('@views/v-google-pay'),
				},
			},
			{
				path: '/product-detail/:productId',
				name: 'products-detail',
				props: {
					primary: true,
					secondary: true,
				},
				components: {
					primary: () => import('@views/v-global'),
					secondary: () => import('@views/v-fam-products-detail'),
				},
			},
			{
				path: 'marketplace',
				name: 'marketplace',
				props: { primary: true },
				meta: { isFullWidth: true },
				components: {
					primary: () => import('@views/v-marketplace'),
				},
			},
			{
				path: 'marketplace/category/:categoryId/:categoryName?',
				name: 'marketplace-category',
				props: {
					primary: true,
					secondary: true,
				},
				components: {
					primary: () => import('@views/v-marketplace'),
					secondary: () => import('@views/v-marketplace-category'),
				},
			},
			{
				path: 'marketplace/detail/:productId',
				name: 'marketplace-detail',
				props: {
					primary: true,
					secondary: true,
				},
				meta: { isProductPack: false },
				components: {
					primary: () => import('@views/v-marketplace'),
					secondary: () => import('@views/v-marketplace-detail'),
				},
			},
			{
				path: 'marketplace/detail/pack/:productId',
				name: 'marketplace-pack-detail',
				props: {
					primary: true,
					secondary: true,
				},
				meta: { isProductPack: true },
				components: {
					primary: () => import('@views/v-marketplace'),
					secondary: () => import('@views/v-marketplace-pack-detail'),
				},
			},
			{
				path: 'customer-service',
				name: 'customer-service',
				props: { primary: true },
				meta: { isFullWidth: true },
				components: {
					primary: () => import('@views/v-customer-service'),
				},
			},
			{
				path: 'customer-chat',
				name: 'customer-chat',
				props: {
					primary: true,
					secondary: true,
				},
				components: {
					primary: () => import('@views/v-customer-service'),
					secondary: () => import('@views/v-customer-chat'),
				},
			},
			{
				path: 'customer-online',
				name: 'customer-online',
				props: {
					primary: true,
					secondary: true,
				},
				components: {
					primary: () => import('@views/v-customer-service'),
					secondary: () => import('@views/v-customer-online'),
				},
			},
			{
				path: 'customer-offices',
				name: 'customer-offices',
				props: {
					primary: true,
					secondary: true,
				},
				components: {
					primary: () => import('@views/v-customer-service'),
					secondary: () => import('@views/v-customer-offices'),
				},
			},
			{
				path: 'sirvase',
				name: 'sirvase',
				props: {
					primary: true,
					secondary: true,
				},
				meta: { transition: 'fade' },
				components: {
					primary: () => import('@views/v-customer-service'),
					secondary: () => import('@views/v-sirvase'),
				},
			},
			{
				path: 'sirvase/welcome',
				name: 'sirvase-welcome',
				props: {
					primary: true,
					secondary: true,
				},
				components: {
					primary: () => import('@views/v-customer-service'),
					secondary: () => import('@views/v-sirvase-welcome'),
				},
			},
			{
				path: 'sirvase-dashboard',
				name: 'sirvase-dashboard',
				props: {
					primary: true,
					secondary: true,
				},
				components: {
					primary: () => import('@views/v-customer-service'),
					secondary: () => import('@views/v-sirvase-dashboard'),
				},
			},
			{
				path: 'sirvase-detail/:requestId',
				name: 'sirvase-detail',
				props: {
					primary: true,
					secondary: true,
				},
				components: {
					primary: () => import('@views/v-customer-service'),
					secondary: () => import('@views/v-sirvase-detail'),
				},
			},
			{
				path: 'sirvase-create/:typologyId',
				name: 'sirvase-create',
				props: {
					primary: true,
					secondary: true,
				},
				components: {
					primary: () => import('@views/v-customer-service'),
					secondary: () => import('@views/v-sirvase-create'),
				},
			},
			{
				path: 'feedback',
				name: 'feedback',
				props: { primary: true },
				meta: { isFullWidth: true },
				components: {
					primary: () => import('@views/v-feedback'),
				},
			},
			{
				path: 'product/:productType/:familyId/:productId/movement/:movementId',
				name: 'movement',
				props: {
					primary: true,
					secondary: true,
				},

				components: {
					primary: () => import('@views/v-global'),
					secondary: () => import('@views/v-movement'),
				},
			},
			{
				path: 'ontime/product/:productType/:familyId/:productId/movement/:movementId',
				name: 'ontime-movement',
				props: {
					primary: true,
					secondary: true,
				},

				components: {
					primary: () => import('@views/v-ontime-dashboard'),
					secondary: () => import('@views/v-movement'),
				},
			},
			{
				path: 'product/:familyId/:productId/withholdings',
				name: 'withholdings',
				props: {
					primary: true,
					secondary: true,
				},
				components: {
					primary: () => import('@views/v-global'),
					secondary: () => import('@views/v-withholdings'),
				},
			},
			{
				path: 'product/:familyId/:productId',
				name: 'product',
				props: {
					primary: true,
					secondary: true,
				},
				components: {
					primary: () => import('@views/v-global'),
					secondary: () => import('@views/v-product'),
				},
			},
			{
				path: 'product/:familyId/:productId/imposition/:movementId',
				name: 'imposition',
				props: {
					primary: true,
					secondary: true,
				},
				components: {
					primary: () => import('@views/v-global'),
					secondary: () => import('@views/v-imposition'),
				},
			},
			{
				path: 'product/financed-operation/:productId',
				name: 'financed-operation-detail',
				props: {
					primary: true,
					secondary: true,
				},
				components: {
					primary: () => import('@views/v-global'),
					secondary: () => import('@views/v-financed-operation-detail'),
				},
			},
			{
				path: 'product/:familyId/:productId/asset/:assetId',
				name: 'asset',
				props: {
					primary: true,
					secondary: true,
				},
				components: {
					primary: () => import('@views/v-global'),
					secondary: () => import('@views/v-asset'),
				},
			},
			{
				path: 'product/:familyId/:productId/investment-asset/:assetId',
				name: 'investment-asset',
				props: {
					primary: true,
					secondary: true,
				},
				components: {
					primary: () => import('@views/v-global'),
					secondary: () => import('@views/v-investment-asset'),
				},
			},
			{
				path: 'product/:familyId/:productId/composition/:type/:tab?',
				name: 'composition',
				props: {
					primary: true,
					secondary: true,
				},
				components: {
					primary: () => import('@views/v-global'),
					secondary: () => import('@views/v-composition'),
				},
			},
			{
				path: 'product/:familyId/search-movements/:productId/:exportType/:exporting',
				name: 'search-movements',
				props: {
					primary: true,
					secondary: true,
				},
				components: {
					primary: () => import('@views/v-global'),
					secondary: () => import('@views/v-search-movements'),
				},
			},
			{
				path: 'product/:familyId/filter-investment/:productId/',
				name: 'filter-investment',
				props: { primary: true },
				meta: { isFullWidth: true },
				components: {
					primary: () => import('@views/v-filter-investment'),
				},
			},
			{
				path: 'product/:familyId/:productId/detail',
				name: 'product-detail',
				props: {
					primary: true,
					secondary: true,
				},
				meta: { transition: 'fade' },
				components: {
					primary: () => import('@views/v-global'),
					secondary: () => import('@views/v-product-detail'),
				},
			},
			{
				path: 'product/:familyId/:productId/investment-detail',
				name: 'investment-product-detail',
				props: { primary: true },
				meta: {
					isFullWidth: true,
					transition: 'fade',
				},
				components: {
					primary: () => import('@views/v-product-detail'),
				},
			},
			{
				path: 'product/investment/:productType/:productId',
				name: 'investment-detail',
				props: { primary: true },
				meta: { isFullWidth: true },
				components: {
					primary: () => import('@views/v-investment-detail'),
				},
			},
			{
				path: 'product/:familyId/:productId/operative/:operativeType',
				name: 'card-operative',
				props: {
					primary: true,
					secondary: true,
				},
				meta: { transition: 'fade' },
				components: {
					primary: () => import('@views/v-global'),
					secondary: () => import('@views/v-card-operative'),
				},
			},
			{
				path: 'product/:familyId/:productId/simulation',
				name: 'settlement-simulation',
				props: {
					primary: true,
					secondary: true,
				},
				meta: { transition: 'fade' },
				components: {
					primary: () => import('@views/v-global'),
					secondary: () => import('@views/v-settlement-simulation'),
				},
			},
			{
				path: 'product/:familyId',
				name: 'product-group',
				props: {
					primary: true,
					secondary: true,
				},
				components: {
					primary: () => import('@views/v-global'),
					secondary: () => import('@views/v-product-group'),
				},
			},
			{
				path: 'product/:familyId/:productId/profiles',
				name: 'product-profiles',
				props: {
					primary: true,
					secondary: true,
				},
				meta: { isNavDisabled: true },
				components: {
					primary: () => import('@views/v-global'),
					secondary: () => import('@views/v-product-profiles'),
				},
			},
			{
				path: 'product/:familyId/:productId/debits',
				name: 'debits',
				props: {
					primary: true,
					secondary: true,
				},
				components: {
					primary: () => import('@views/v-global'),
					secondary: () => import('@views/v-debits'),
				},
			},
			{
				path: 'product/:familyId/:productId/debits/creditor/:creditorId',
				name: 'creditor-detail',
				props: {
					primary: true,
					secondary: true,
				},
				components: {
					primary: () => import('@views/v-global'),
					secondary: () => import('@views/v-creditor-detail'),
				},
			},
			{
				path: 'product/:familyId/:productId/debits/:movementId/:creditorId?',
				name: 'movement-debit',
				props: {
					primary: true,
					secondary: true,
				},
				components: {
					primary: () => import('@views/v-global'),
					secondary: () => import('@views/v-movement-debit'),
				},
			},
			{
				path: 'premium/:familyId',
				name: 'premium',
				props: {
					primary: true,
					secondary: true,
				},
				components: {
					primary: () => import('@views/v-global'),
					secondary: () => import('@views/v-premium'),
				},
			},
			{
				path: 'amortization-table/:productId',
				name: 'amortization-table',
				props: {
					primary: true,
					secondary: true,
				},
				components: {
					primary: () => import('@views/v-global'),
					secondary: () => import('@views/v-amortization-table'),
				},
			},
			{
				path: 'product/:familyId/:productId/receipts-table',
				name: 'receipts-table',
				props: {
					primary: true,
					secondary: true,
				},
				components: {
					primary: () => import('@views/v-global'),
					secondary: () => import('@views/v-receipts-table'),
				},
			},
			{
				path: 'personal-area',
				name: 'personal-area',
				props: { primary: true },
				meta: { isFullWidth: true },
				components: {
					primary: () => import('@views/v-personal-area'),
				},
			},
			{
				path: 'personal-area/notifications',
				name: 'notifications',
				props: {
					primary: true,
					secondary: true,
				},
				components: {
					primary: () => import('@views/v-personal-area'),
					secondary: () => import('@views/v-notifications'),
				},
			},
			{
				path: 'security-and-privacy',
				name: 'security-and-privacy',
				meta: { isFullWidth: true },
				components: {
					primary: () => import('@views/v-security-and-privacy'),
				},
			},
			{
				path: 'personal-area/personal-details',
				name: 'personal-details',
				props: {
					primary: true,
					secondary: true,
				},
				meta: { transition: 'fade' },
				components: {
					primary: () => import('@views/v-personal-area'),
					secondary: () => import('@views/v-personal-details'),
				},
			},
			{
				path: 'security-and-privacy/change-password',
				name: 'change-password',
				components: {
					primary: () => import('@views/v-security-and-privacy'),
					secondary: () => import('@views/v-change-password'),
				},
			},
			{
				path: 'security-and-privacy/gdpr',
				name: 'gdpr',
				components: {
					primary: () => import('@views/v-security-and-privacy'),
					secondary: () => import('@views/v-gdpr'),
				},
			},
			{
				path: 'security-and-privacy/block',
				name: 'block',
				props: {
					primary: true,
					secondary: true,
				},
				meta: { isNavDisabled: true, isFullWidth: true },
				components: {
					primary: () => import('@views/v-block'),
				},
			},
			{
				path: 'personal-area/devices-list',
				name: 'devices-list',
				props: {
					primary: true,
					secondary: true,
				},
				components: {
					primary: () => import('@views/v-personal-area'),
					secondary: () => import('@views/v-devices-list'),
				},
			},
			{
				path: 'personal-area/devices-list/device-sessions/:deviceId',
				name: 'device-sessions',
				props: {
					primary: true,
					secondary: true,
				},
				components: {
					primary: () => import('@views/v-personal-area'),
					secondary: () => import('@views/v-device-sessions'),
				},
			},
			{
				path: 'personal-area/language',
				name: 'language',
				props: {
					primary: true,
					secondary: true,
				},
				components: {
					primary: () => import('@views/v-personal-area'),
					secondary: () => import('@views/v-language'),
				},
			},
			{
				path: 'personal-area/mas-cbnk',
				name: 'mas-cbnk',
				meta: { isFullWidth: true, isNavDisabled: true },
				components: {
					primary: () => import('@views/v-mas-cbnk'),
				},
			},
			{
				path: 'transfers',
				name: 'transfers',
				props: { primary: true },
				meta: { isFullWidth: true },
				components: {
					primary: () => import('@views/v-transfers'),
				},
			},
			{
				path: 'transfer/:action/:type?/:transferId?/:productId?',
				name: 'transfer',
				props: { primary: true },
				meta: { isFullWidth: true, isNavDisabled: true },
				components: {
					primary: () => import('@views/v-cbnk-transfer'),
				},
			},
			{
				path: 'my-transfers/:productId?',
				name: 'my-transfers',
				props: {
					primary: true,
					secondary: true,
				},
				components: {
					primary: () => import('@views/v-transfers'),
					secondary: () => import('@views/v-my-transfers'),
				},
			},
			{
				path: 'my-transfers/:productId/:type/:transferId?',
				name: 'transfer-detail',
				props: {
					primary: true,
					secondary: true,
				},
				components: {
					primary: () => import('@views/v-transfers'),
					secondary: () => import('@views/v-transfer-detail'),
				},
			},
			{
				path: 'bizum',
				name: 'bizum',
				props: {
					primary: true,
					secondary: true,
				},
				meta: { transition: 'fade' },
				components: {
					primary: () => import('@views/v-transfers'),
					secondary: () => import('@views/v-bizum'),
				},
			},
			{
				path: 'bizum/welcome',
				name: 'bizum-welcome',
				props: {
					primary: true,
					secondary: true,
				},
				components: {
					primary: () => import('@views/v-transfers'),
					secondary: () => import('@views/v-bizum-welcome'),
				},
			},
			{
				path: 'bizum/register/:productId',
				name: 'bizum-register',
				props: {
					primary: true,
					secondary: true,
				},
				components: {
					primary: () => import('@views/v-transfers'),
					secondary: () => import('@views/v-bizum-register'),
				},
			},
			{
				path: 'bizum/settings',
				name: 'bizum-settings',
				props: {
					primary: true,
					secondary: true,
				},
				components: {
					primary: () => import('@views/v-transfers'),
					secondary: () => import('@views/v-bizum-settings'),
				},
			},
			{
				path: 'bizum/connections',
				name: 'bizum-connections',
				props: {
					primary: true,
					secondary: true,
				},
				components: {
					primary: () => import('@views/v-transfers'),
					secondary: () => import('@views/v-bizum-connections'),
				},
			},
			{
				path: 'bizum/connections-detail/:consentId',
				name: 'bizum-connections-detail',
				props: {
					primary: true,
					secondary: true,
				},
				components: {
					primary: () => import('@views/v-transfers'),
					secondary: () => import('@views/v-bizum-connections-detail'),
				},
			},
			{
				path: 'bizum/connections-history/:consentId',
				name: 'bizum-connections-history',
				props: {
					primary: true,
					secondary: true,
				},
				components: {
					primary: () => import('@views/v-transfers'),
					secondary: () => import('@views/v-bizum-connections-history'),
				},
			},
			{
				path: 'bizum/subscriptions',
				name: 'bizum-subscriptions',
				props: {
					primary: true,
					secondary: true,
				},
				components: {
					primary: () => import('@views/v-transfers'),
					secondary: () => import('@views/v-bizum-subscriptions'),
				},
			},
			{
				path: 'bizum/subscriptions/detail/:subscriptionId',
				name: 'bizum-subscriptions-detail',
				props: {
					primary: true,
					secondary: true,
				},
				components: {
					primary: () => import('@views/v-transfers'),
					secondary: () => import('@views/v-bizum-subscriptions-detail'),
				},
			},
			{
				path: 'bizum/dashboard',
				name: 'bizum-dashboard',
				props: {
					primary: true,
					secondary: true,
				},
				components: {
					primary: () => import('@views/v-transfers'),
					secondary: () => import('@views/v-bizum-dashboard'),
				},
			},
			{
				path: 'bizum/details',
				name: 'bizum-details',
				props: {
					primary: true,
					secondary: true,
				},
				meta: { transition: 'fade' },
				components: {
					primary: () => import('@views/v-transfers'),
					secondary: () => import('@views/v-bizum-details'),
				},
			},
			{
				path: 'bizum/help',
				name: 'bizum-help',
				props: {
					primary: true,
					secondary: true,
				},
				meta: { transition: 'fade' },
				components: {
					primary: () => import('@views/v-transfers'),
					secondary: () => import('@views/v-bizum-help'),
				},
			},
			{
				path: 'bizum/selae',
				name: 'bizum-selae',
				props: {
					primary: true,
					secondary: true,
				},
				meta: { transition: 'fade' },
				components: {
					primary: () => import('@views/v-transfers'),
					secondary: () => import('@views/v-bizum-selae'),
				},
			},
			{
				path: 'bizum/selae-detail',
				name: 'bizum-selae-detail',
				props: {
					primary: true,
					secondary: true,
				},
				meta: {
					transition: 'fade',
					primary: (route) => ({
						relatedCustomerData: route.state || { prueba: 'hola' },
					}),
				},

				components: {
					primary: () => import('@views/v-transfers'),
					secondary: () => import('@views/v-bizum-selae-detail'),
				},
			},
			{
				path: 'bizum/unregister',
				name: 'bizum-unregister',
				props: {
					primary: true,
					secondary: true,
				},
				components: {
					primary: () => import('@views/v-transfers'),
					secondary: () => import('@views/v-bizum-unregister'),
				},
			},
			{
				path: 'bizum/movement/:movementId',
				name: 'bizum-movement',
				props: {
					primary: true,
					secondary: true,
				},
				components: {
					primary: () => import('@views/v-transfers'),
					secondary: () => import('@views/v-bizum-movement'),
				},
			},
			{
				path: 'bizum/transfer/:action?/:movementId?',
				name: 'bizum-transfer',
				props: {
					primary: true,
					secondary: true,
				},
				components: {
					primary: () => import('@views/v-transfers'),
					secondary: () => import('@views/v-bizum-transfer'),
				},
				children: [
					{
						path: 'contacts',
						name: 'bizum-contacts',
						props: true,
						meta: { hasStateSlot: true },
						component: () => import('@views/v-bizum-contacts'),
					},
					{
						path: 'ongs',
						name: 'bizum-ongs',
						props: true,
						meta: { hasStateSlot: true },
						component: () => import('@views/v-bizum-ongs'),
					},
				],
			},
			{
				path: 'communications/:type?',
				name: 'communications',
				props: { primary: true },
				meta: { isFullWidth: true },
				components: {
					primary: () => import('@views/v-communications'),
				},
			},
			{
				path: 'communications/:type/:messageId',
				name: 'communication-detail',
				props: {
					primary: true,
					secondary: true,
				},
				components: {
					primary: () => import('@views/v-communications'),
					secondary: () => import('@views/v-communication-detail'),
				},
			},
			{
				path: 'signatures/:type/closable/:isClosable?',
				name: 'signatures',
				props: (route) => ({
					type: route.params.type || 'signed',
					isClosable: route.params.isClosable ? route.params.isClosable === 'true' : true,
				}),
				meta: { isFullWidth: true },
				components: { primary: () => import('@views/v-signatures') },
			},
			{
				path: 'blocking-campaign',
				name: 'blocking-campaign',
				props: {
					primary: true,
					secondary: true,
				},
				meta: { isNavDisabled: true },
				components: {
					primary: () => import('@views/v-blocking-campaign'),
					secondary: () => import('@views/v-dynamic-page'),
				},
			},
			{
				path: 'signatures/:type/:signatureId',
				name: 'signature-detail',
				props: {
					primary: true,
					secondary: true,
				},
				meta: { isFullWidth: true },
				components: {
					primary: () => import('@views/v-signature-detail'),
				},
			},
			{
				path: 'correos-cash-dashboard',
				name: 'correos-cash-dashboard',
				props: {
					primary: true,
					secondary: true,
				},
				meta: { transition: 'fade' },
				components: {
					primary: () => import('@views/v-transfers'),
					secondary: () => import('@views/v-correos-cash-dashboard'),
				},
			},
			{
				path: 'correos-cash-deposit',
				name: 'correos-cash-deposit',
				props: {
					primary: true,
					secondary: true,
				},
				meta: { transition: 'fade' },
				components: {
					primary: () => import('@views/v-transfers'),
					secondary: () => import('@views/v-correos-cash-deposit'),
				},
			},
			{
				path: 'correos-cash-detail/:depositId?/:qrId?',
				name: 'correos-cash-detail',
				props: {
					primary: true,
					secondary: true,
				},
				meta: { transition: 'fade' },
				components: {
					primary: () => import('@views/v-transfers'),
					secondary: () => import('@views/v-correos-cash-detail'),
				},
			},
			{
				path: 'correos-cash/qr/:depositId/:qrId',
				name: 'correos-cash-qr',
				props: {
					primary: true,
					secondary: true,
				},
				meta: { transition: 'fade' },
				components: {
					primary: () => import('@views/v-transfers'),
					secondary: () => import('@views/v-correos-cash-qr'),
				},
			},
			{
				path: 'profiles/closable/:isClosable?',
				name: 'profiles',
				props: (route) => ({
					isClosable: route.params.isClosable ? route.params.isClosable === 'true' : true,
				}),
				meta: { transition: 'fade', isFullWidth: true, isNavDisabled: true },
				components: {
					primary: () => import('@views/v-profiles'),
				},
			},
			{
				path: 'profiles/dashboard/closable/:isClosable?',
				name: 'profiles-dashboard',
				props: (route) => ({
					isClosable: route.params.isClosable ? route.params.isClosable === 'true' : true,
				}),
				meta: { isFullWidth: true },
				components: {
					primary: () => import('@views/v-profiles-dashboard'),
				},
			},
			{
				path: 'profiles/welcome',
				name: 'profiles-welcome',
				props: {
					primary: true,
					secondary: true,
				},
				meta: { isFullWidth: true, isNavDisabled: true },
				components: {
					primary: () => import('@views/v-profiles-welcome'),
				},
			},
			{
				path: 'profiles/create/:profileId?',
				name: 'profiles-create',
				props: {
					primary: true,
					secondary: true,
				},
				meta: { isFullWidth: true, isNavDisabled: true },
				components: {
					primary: () => import('@views/v-profiles-create'),
				},
			},
			{
				path: 'ontime',
				name: 'ontime',
				props: {
					primary: true,
					secondary: true,
				},
				meta: { transition: 'fade', isFullWidth: true },
				components: {
					primary: () => import('@views/v-ontime'),
				},
			},
			{
				path: 'ontime/dashboard',
				name: 'ontime-dashboard',
				props: {
					primary: true,
				},
				meta: { isFullWidth: true },
				components: {
					primary: () => import('@views/v-ontime-dashboard'),
				},
			},
			{
				path: 'ontime/welcome',
				name: 'ontime-welcome',
				props: {
					primary: true,
				},
				meta: { isFullWidth: true, isNavDisabled: true },
				components: {
					primary: () => import('@views/v-ontime-welcome'),
				},
			},
			{
				path: 'ontime/create',
				name: 'ontime-create',
				props: {
					primary: true,
				},
				meta: { isFullWidth: true, isNavDisabled: true },
				components: {
					primary: () => import('@views/v-ontime-create'),
				},
			},
			{
				path: 'ontime/filter',
				name: 'ontime-filter',
				props: {
					primary: true,
				},
				meta: { isFullWidth: true, isNavDisabled: true },
				components: {
					primary: () => import('@views/v-ontime-filter'),
				},
			},
		],
	},
];
