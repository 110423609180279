import { Factory, trait } from 'miragejs';
import { faker } from '@faker-js/faker';

export default Factory.extend({
	name: () => faker.person.firstName(),
	surname1: faker.person.lastName(),
	surname2: faker.person.lastName(),
	birthdate: '01/01/1999',
	phonePrefix: '+34',
	phone: faker.phone.number('6## ### ###'),
	email: faker.internet.email(),
	rememberToken: () => faker.string.uuid(),
	authToken: () => faker.string.uuid(),
	documentType: 'DNI',
	documentNumber: 'A00',
	password: '123456',
	loginErrorCount: 0,
	state: 'ACTIVE',
	push: true,
	deviceId: () => faker.string.uuid(),
	model: 'Iphone 10',
	notificationRestrictionStartTime: '05:59:59+01:00',
	notificationRestrictionEndTime: '23:59:59+01:00',
	smsByEmail: false,
	connectedContract: null,
	natureType: '',
	vertical: 1,
	collective: 1,
	firstTime: trait({
		passwordChange: true,
		forceSCA: true,
	}),

	sca: trait({
		forceSCA: true,
	}),

	blocked: trait({
		state: 'BLOCKED',
	}),

	bizumData: () => {},

	tableViewProd: false,
	tableViewMov: false,

	afterCreate(user, server) {
		user.update({
			documentNumber: user.documentId,
			documents: [...server.createList('document', 40)],
			messages: [...server.createList('message', 40)],
			notifications: [...server.createList('notification', 60)],
			cancellableList: [...server.createList('cancellable-list', 4)],
			limits: {
				ownAccounts: 6000,
				internalAccounts: 6000,
				externalAccounts: 6000,
				postedInternalAccounts: 6000,
				postedExternalAccounts: 6000,
			},
			devices: [
				...server.createList('device', 10, {
					deviceSessions: () => server.createList('deviceSession', 10),
				}),
			],
			signatures: [
				...server.createList('signature', 3, 'pending'),
				...server.createList('signature', 3, 'signed'),
			],
			subscriptions: [...server.createList('subscription', 50)],
			consents: [...server.createList('consent', 50)],
			connections: [...server.createList('connection', 10)],
			sirvase: [...server.createList('sirvase', 10)],
			userConsents: [...server.createList('userConsent', 1)],
			savedAccounts: [...server.createList('savedAccount', 5)],
		});

		if (!user.products.models.length && !user.empty) {
			user.update({
				products: [
					...server.createList('product', 2, 'account', {
						transfers: [
							...server.createList('transfer', 10),
							...server.createList('transfer', 10, 'scheduled'),
							...server.createList('transfer', 10, 'periodic'),
							...server.createList('transfer', 10, 'favorite'),
							...server.createList('transfer', 1, 'unknown'),
						],
					}),
				],
			});
		}

		if (!user.contracts.length) {
			user.update({
				contracts: [...server.createList('contract', 1, 'owner')],
			});
		}
	},
});
