import { Factory } from 'miragejs';

export default Factory.extend({
	consentsGDPR: [
		{
			id: 1,
			description:
				'Elaborar, en base de la información facilitada por terceros (identificados en la Privacidad de la web de Banco Caminos), un perfil comercial más completo, permitiendo ofrecerme servicios más personalizados y mejores, mediante el uso de decisiones automatizadas',
			value: 'S',
		},
		{
			id: 2,
			description:
				'Ceder mis datos a entidades del Grupo Caminos, participadas y colaboradoras (las cuales pueden consultarse en [www.bancocaminos.es](https://www.bancocaminos.es) para recibir comunicaciones comerciales, por cualquier medio, de productos y servicios comercializados por las mismas y personalizadas de acuerdo con mi perfil comercial.',
			value: 'S',
		},
		{
			id: 3,
			description:
				'Enviarme comunicaciones comerciales, por cualquier medio, sobre productos y servicios distintos de los que tengo contratados y adaptados a mi perfil comercial ofertados tanto por la Entidad como por terceros. Los tipos de productos y servicios están identificados en la [Política de Privacidad](https://www.bancocaminos.es/aviso-legal/politica-de-privacidad-y-proteccion-de-datos)',
			value: 'S',
		},
	],
});
