<template>
	<transition
		:name="name"
		v-bind="$attrs"
	>
		<slot />
	</transition>
</template>

<script>
export default {
	name: 'c-transition',

	props: {
		name: {
			type: String,
			default: 'fade',
		},
	},
};
</script>

<style lang="scss" scoped>
.fade-enter-active,
.fade-leave-active {
	transition: opacity 250ms;
}
.fade-enter,
.fade-leave-to {
	opacity: 0;
}

.placeholder-enter {
	opacity: 0;
	transform: translateY(-5px);
}

.placeholder-enter-active {
	transition:
		opacity 400ms ease-in-out,
		transform 400ms ease-in-out;
}

.placeholder-leave-active {
	opacity: 0;
	transform: translateY(40%);
	transition:
		opacity 400ms ease-in-out,
		transform 400ms ease-in-out;
	transition-delay: 400ms;
}

.placeholder-leave-active ~ .placeholder-leave-active {
	transition-delay: 200ms;
}

.placeholder-leave-active ~ .placeholder-leave-active ~ .placeholder-leave-active {
	transition-delay: 0ms;
}

.slide-in-bottom-enter,
.slide-in-bottom-leave-to {
	transform: translateY(100%);
}

.slide-in-bottom-enter-active,
.slide-in-bottom-leave-active {
	transition: transform 250ms ease-in-out;
}

.fade-in-top-enter {
	opacity: 0;
	transform: translateY(-10px);
}

.fade-in-top-leave-to {
	opacity: 0;
	transform: translateY(0px);
}

.fade-in-top-enter-active,
.fade-in-top-leave-active {
	transition:
		transform 1000ms ease,
		opacity 1000ms ease;
}

.slide-enter-active {
	will-change: transform;
	transition:
		transform 400ms 400ms ease-in-out,
		opacity 400ms 400ms;
}

.slide-immediate-enter-active {
	will-change: transform;
	transition:
		transform 400ms ease-in-out,
		opacity 400ms;
}

.slide-enter,
.slide-immediate-enter {
	opacity: 0;
	transform: translateY(-5px);
}

.slide-left-enter-active {
	will-change: transform;
	transition:
		transform 200ms ease-out,
		opacity 200ms;
}

.slide-left-enter {
	opacity: 0;
	transform: translateX(-100%);
}
</style>
