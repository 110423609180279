import { Factory, trait } from 'miragejs';
import { faker } from '@faker-js/faker';
import frequencyTypes from '@modules/move-money/frequency-types';

faker.seed(1);

const randomPastDate = () => faker.date.past().toISOString().split('T')[0];

const randomFutureDate = () => faker.date.future().toISOString().split('T')[0];

let bic;

export default Factory.extend({
	reference: () => faker.string.uuid(),
	beneficiary: {
		toAccount: {
			productNumber: {
				format: { id: 'IBAN' },
				value: () => {
					const iban = faker.finance.iban();
					bic = `BANK${iban.slice(0, 2)}XXXXX`;
					return iban;
				},
			},
			bic: () => bic,
		},
		description: () => faker.person.fullName(),
	},
	transferMode: {
		id: 'SEPA',
		name: 'SEPA',
	},
	reason: () => faker.lorem.sentence().replace('.', ''),
	amount: {
		amount: () => parseFloat(faker.finance.amount()),
		currency: {
			id: () => faker.helpers.arrayElement(['EUR', 'EUR', 'EUR', 'USD']),
		},
	},
	date: randomPastDate,
	operationDate: randomPastDate,
	status: {
		id: () => faker.helpers.arrayElement(['PAID', 'AUTHORIZED', 'REJECTED', 'RETURNED']),
	},
	isCancellable: () => faker.datatype.boolean(),
	type: 'ordered',
	favorite: trait({
		alias: () => faker.lorem.sentence(3),
		favorite: true,
		type: 'favorite',
		notification: {
			email: () => faker.internet.email(),
			enable: () => faker.datatype.boolean(),
		},
	}),
	scheduled: trait({
		nextExecutionDate: randomFutureDate,
		type: 'scheduled',
	}),
	periodic: trait({
		periodicity: {
			type: 'PERIODIC',
			firstExecutionDate: randomPastDate,
			frequency: faker.helpers.arrayElement(Object.keys(frequencyTypes)),
		},
		endExecutionDate: randomFutureDate,
		nextExecutionDate: randomFutureDate,
		type: 'periodic',
	}),
	unknown: trait({
		beneficiary: {
			toAccount: {
				productNumber: {
					format: { id: 'UNKNOWN' },
					value: () => faker.finance.accountNumber(),
				},
				bic: () => faker.finance.bic(),
			},
			description: () => faker.person.fullName(),
		},
	}),
});
