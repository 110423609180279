import { Factory } from 'miragejs';
import { faker } from '@faker-js/faker';

faker.seed(1);

export default Factory.extend({
	id: () => faker.string.uuid(),
	creditorName: () => faker.lorem.words(2),
	creditorId: () => faker.finance.bic(),
	amount: {
		amount: () => parseFloat(faker.finance.amount(1, 1000)),
		currency: { id: 'EUR' },
	},
	operationDate: () => faker.date.past(2).toISOString(),
});
