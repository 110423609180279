import { Factory } from 'miragejs';
import { faker } from '@faker-js/faker';

faker.seed(1);

export default Factory.extend({
	id: () => faker.string.uuid(),
	name: () => faker.lorem.words(2),
	creditorTaxNumber: () => faker.finance.bic(),
	creditorStatus: () => faker.helpers.arrayElement(['ACTIVE', 'INACTIVE']),
	operationsEnable: true,
});
