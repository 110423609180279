import { defineStore } from 'pinia';

/**
 * Returns a random session device id.
 */
const generateUUID = () => {
	const lut = Array.from({ length: 256 }, (a, i) => (i < 16 ? '0' : '') + i.toString(16));

	 
	const d0 = (Math.random() * 0xffffffff) | 0;
	const d1 = (Math.random() * 0xffffffff) | 0;
	const d2 = (Math.random() * 0xffffffff) | 0;
	const d3 = (Math.random() * 0xffffffff) | 0;

	return (
		 
		lut[d0 & 0xff] +
		lut[(d0 >> 8) & 0xff] +
		lut[(d0 >> 16) & 0xff] +
		lut[(d0 >> 24) & 0xff] +
		'-' +
		lut[d1 & 0xff] +
		lut[(d1 >> 8) & 0xff] +
		'-' +
		lut[((d1 >> 16) & 0x0f) | 0x40] +
		lut[(d1 >> 24) & 0xff] +
		'-' +
		lut[(d2 & 0x3f) | 0x80] +
		lut[(d2 >> 8) & 0xff] +
		'-' +
		lut[(d2 >> 16) & 0xff] +
		lut[(d2 >> 24) & 0xff] +
		lut[d3 & 0xff] +
		lut[(d3 >> 8) & 0xff] +
		lut[(d3 >> 16) & 0xff] +
		lut[(d3 >> 24) & 0xff]
	);
};

const DEVICE_STORAGE_KEY = 'DEVICE_STORAGE_KEY';

export const useDeviceStore = defineStore('m-device', {
	/**
	 * @typedef state
	 * @property {String} id Unique session device id.
	 * @property {Boolean} isPWA Return true if the current session is from an installed progressive web app.
	 */
	state: () => {
		let deviceId = localStorage.getItem(DEVICE_STORAGE_KEY);

		if (!deviceId) {
			deviceId = generateUUID();
			localStorage.setItem(DEVICE_STORAGE_KEY, deviceId);
		}

		return {
			id: deviceId,
			isPWA: Boolean(matchMedia('(display-mode: standalone)')?.matches || navigator.standalone),
			deviceId: '',
			biometricToken: '',
			deviceToken: '',
			hasBiometry: false,
			useBiometry: false,
			mobileOs: '',
			lastLogin: '',
			biometryType: '',
			theme: '',
			appVersion: '',
			isMyDevice: false,
		};
	},

	getters: {
		getDeviceId() {
			return this.id;
		},
		getMobileOs() {
			return this.mobileOs;
		},
		getAppVersion() {
			return this.appVersion;
		},
		getIsPWA() {
			return this.isPWA;
		},
	},

	actions: {
		setDeviceData(value) {
			this.deviceId = value?.deviceId;
			this.biometricToken = value?.biometricToken;
			this.deviceToken = value?.deviceToken;
			this.hasBiometry = value?.hasBiometry;
			this.useBiometry = value?.useBiometry;
			this.mobileOs = value?.mobileOs;
			this.lastLogin = value?.lastLogin;
			this.biometryType = value?.biometryType;
			this.theme = value?.theme;
			this.appVersion = value?.appVersion;
			this.isMyDevice = value?.isMyDevice;
		},
		getDeviceData() {
			return new Promise((resolve, reject) => {
				const channel = new MessageChannel();
				const requestTimer = setTimeout(() => {
					reject(new Error('TIMEOUT'));
				}, 10000);

				/* istanbul ignore next */
				channel.port1.onmessage = ({ data }) => {
					clearTimeout(requestTimer);

					this.setDeviceData(data);

					resolve(data);
				};

				setTimeout(() => {
					window.postMessage({ name: 'native-get-device-data' }, '*', [channel.port2]);
				}, 400);
			});
		},
	},
});
