import { Factory } from 'miragejs';
import { faker } from '@faker-js/faker';

export default Factory.extend({
	campaignId: (i) => `0000${i + 1}`,
	description: () => faker.lorem.words(2),
	messageType: () => faker.helpers.arrayElement(['operational', 'commercial', 'marketing']),
	vertical: () =>
		faker.number.int({
			min: 1,
			max: 2,
		}),
	collective: () =>
		faker.number.int({
			min: 1,
			max: 2,
		}),
	channels: () => Array.from({ length: 4 }, () => ({
			id: faker.string.uuid(),
			type: faker.helpers.arrayElement(['email', 'sms', 'push']),
			priority: faker.number.int({ min: 1, max: 3 }),
			subject: faker.lorem.words(10),
			bodyIntro: faker.lorem.words(15),
			body: faker.lorem.words(20),
			language: faker.helpers.arrayElement(['es', 'en', 'cat']),
		})),
});
