import { createRouter, createWebHistory } from 'vue-router';
import { importLocale } from '@plugins/i18n';
import routes from '@cbnk/router/routes';
import MChunkError from '@modals/m-chunk-error.vue';
import MNoInternet from '@modals/m-no-internet.vue';
import MLogout from '@modals/m-logout.vue';
import MSomethingWrong from '@modals/m-something-wrong.vue';
import scrollBehavior from './scroll-behavior';

const router = createRouter({
	history: createWebHistory(),
	routes,
	scrollBehavior,
});

router.onError(async (error) => {
	if (error.name === 'ChunkLoadError') {
		if (error.type === 'missing') {
			const modalStore = useModalStore();
			await modalStore.open(MChunkError);
		}

		if (error.type === 'error') {
			const serviceStore = useServiceStore();
			serviceStore.setOffline(true);
		}
	}
});

router.beforeEach(async (to, from, next) => {
	/**
	 * There are two ways to set a route as "public":
	 * 1. add the name to the publicPages sentence
	 * 2. add the prefix "public-" to the view
	 */
	const { params, query, meta } = to;
	const publicPages = ['login', 'sso-rsi-form'];
	const authRequired = !publicPages.includes(to.name);

	const appStore = useAppStore();
	const isHybrid = appStore.getIsHybrid;
	const isHybridSky = appStore.getIsHybridSky;
	const isEmbedded = appStore.getIsEmbedded;
	const serviceStore = useServiceStore();
	const offline = serviceStore.getOffline;
	const authStore = useAuthStore();
	const isLoggedIn = authStore.getIsLoggedIn;
	const sessionStore = useSessionStore();
	const lang = sessionStore.getLang;
	const vertical = sessionStore.getVertical;

	const isDev = process.env.NODE_ENV === 'development';
	const redirect = {
		name: 'login',
		query: { redirect: to.fullPath },
		replace: true,
	};

	const modalStore = useModalStore();
	const hasModalInQueue = Boolean(Object.keys(modalStore.getQueue).length);
	const blockedNavigation = Boolean(from.query?.blocked);

	//TODO MEJORA DE RENDIMIENTO
	if (lang && (!isHybrid || !isHybridSky)) {
		await importLocale(lang, vertical);
	}

	// We cancel navigation in offline mode or in blocked navigation
	if (offline || blockedNavigation) {
		return;
	}

	// We close any modal if there is a navigation with a modal opened
	if (hasModalInQueue && !params?.action && !query?.entryPoint) {
		await modalStore.closeAll();

		return next(false);
	}

	// We request the exit and logout after navigate to login on any hybrid version
	if (to?.name === 'login' && (isHybrid || isHybridSky || isEmbedded)) {
		// if a password recovery is requested we continue
		if (query?.action === 'pwd-recovery') {
			return next();
		}

		if (isLoggedIn) {
			await authStore.logout();
		}

		return;
	}

	if (isHybrid && (to.name === 'global' || to.name === 'sso-rsi')) {
		window.postMessage(
			{
				name: 'bridge-exit-app',
				to: 'global',
			},
			'*'
		);

		return next(false);
	}

	/**
	 * We request the exit after navigate to the entry point when:
	 * The user request an active logout
	 * The session gets expired
	 * The user request an active logout by pressing back
	 */
	if ((query?.entryPoint || meta?.entryPoint) && isLoggedIn) {
		if (isHybridSky) {
			const isLogoutConfirmed = await modalStore.open(MLogout);

			if (isLogoutConfirmed) {
				window.dispatchEvent(new Event('native-exit-app'));
			}
		}

		if (isHybrid) {
			window.postMessage({ name: 'bridge-exit-app' }, '*');
		}

		return next(false);
	}

	/**
	 * We allow access to private routes when:
	 * 1. It is hybrid mode and there is a query.session or
	 * 2. It is hybrid sky mode and
	 * 3. The login by token returns ok
	 */

	if (authRequired && !isLoggedIn) {
		if (query?.entryPoint) {
			return next();
		}

		const loadingStore = useLoadingStore();
		const userStore = useUserStore();
		const otpStore = useOtpStore();

		// CD-11983: The new version does not do anything with the query.session value
		if ((isHybrid && query?.session) || isHybridSky) {
			if (isHybridSky) {
				window.dispatchEvent(new Event('splashscreen-unload'));
				loadingStore.start();
			}

			/**
			 * When is a hybrid version we create an entry point
			 * that allows users close the app when they return to the entry point
			 */
			if (!from?.name) {
				return next({
					name: 'entry',
					query: {
						redirect: to.fullPath,
						entryPoint: true,
					},
				});
			}

			if (from?.name) {
				const sessionData = {};

				return authStore
					.loginByToken({ session: query?.session })
					.then((data) => Object.assign(sessionData, data))
					.then(() =>
						userStore
							.getPersonalDetails()
							.catch(() => Promise.resolve({ data: { name: '' } }))
					)
					.then(({ data: { name } }) => {
						if (isHybridSky) {
							sessionStore.setVertical({
								vertical: sessionData.vertical,
								collective: sessionData.collective,
							});

							return authStore.getContracts({
								data: { username: name },
							});
						}

						return name;
					})
					.then(async (name) => {
						if (lang) {
							await importLocale(lang, sessionData.vertical);
						}

						sessionStore.setUserSession({ userName: name });
						sessionStore.setAppTheme(sessionData?.theme || 'light');
					})
					.then(() => {
						if (query.externalotp) {
							return otpStore.disableModal();
						}
					})
					.then(() => {
						// CD-11983: Sometime when the app gets second plane and the user does some action
						// that reloads the app, we have to avoid loosing the previous route.
						if (to.name !== 'global' && from.name === 'entry') {
							window.history.replaceState({}, '', router.resolve({ name: 'global' }).href);
						}

						return next();
					})
					.then(() => {
						// We force the removing of the device splash screen on hybrid mode
						if (isHybrid) {
							window.dispatchEvent(new Event('splashscreen-unload'));
						}
					})
					.catch(async (error) => {
						loadingStore.end();

						if (error?.message === 'Network Error') {
							await modalStore.open(MNoInternet);
						} else {
							await modalStore.open(MSomethingWrong);
						}

						authStore.logout();
					});
			}
		}

		// Autologin: used only on mocked servers
		/* istanbul ignore next */
		if (isDev) {
			const session = sessionStorage.getItem('SECURE_SESSION');

			if (session) {
				const { rememberToken, password } = JSON.parse(session);

				if (rememberToken && password) {
					return authStore
						.login({
							rememberToken,
							password,
						})
						.then(({ username: userName, lastLogin, vertical, collective }) => {
							sessionStore.setVertical({
								vertical,
								collective,
							});
							sessionStore.setUserSession({
								userName,
								lastLogin,
							});
						})
						.then(() => next())
						.catch(() => next(redirect));
				}
			}
		}

		return next(redirect);
	}

	next();
});

let once = false;

router.afterEach(async () => {
	if (!once) {
		once = true;

		/**
		 * We don't use the "to" param to avoid redundant navigation error
		 * We create a state on the history
		 */
		if (router.currentRoute?.value?.query?.entryPoint) {
			const to = router.resolve(router.currentRoute.value.query.redirect);

			router.push({
				name: to.name,
				params: to.params,
				query: to.query,
			});
		}
	}
});

export default router;
