import { Factory } from 'miragejs';
import { faker } from '@faker-js/faker';

faker.seed(1);

export default Factory.extend({
	user: {
		userId: () => faker.string.uuid(),
		firstSurname: () => faker.person.lastName(),
		secondSurname: () => faker.person.lastName(),
		documentId: '329076589Z',
	},

	status: () => faker.helpers.arrayElement(['processed', 'rejected']),

	notificationInfo: {
		creationDate: () => faker.date.recent(),
		channel: () => faker.helpers.arrayElement(['sms', 'push', 'email']),
		destination: () => faker.phone.number('+34 ### ### ###'),
		text: () => faker.lorem.words(10),
	},
});
