import { defineStore } from 'pinia';

import { useModalStore } from '@modules/modal/m-modal';
import { useServiceStore } from '@modules/service/m-service';

export const useOtpStore = defineStore('m-otp', {
	state: () => ({
		enableModal: true,
	}),

	actions: {
		setModalState(value) {
			this.$state.enableModal = value;
		},

		requestOtp() {
			return new Promise((resolve) => {
				const channel = new MessageChannel();

				channel.port1.onmessage = ({ data }) => {
					const isSuccess = data?.status === 200;
					const otp = data?.otp?.toString();

					/* istanbul ignore if */
					if (isSuccess) {
						resolve(otp);
					}
				};

				setTimeout(() => {
					window.postMessage(
						{
							name: 'native-request-otp',
							detail: {},
						},
						'*',
						[channel.port2]
					);
				}, 400);
			});
		},

		handle({ component, props }) {
			if (this.$state.enableModal) {
				const modalStore = useModalStore();
				return modalStore.open({
					component,
					props,
				});
			}

			window.postMessage(
				{
					name: 'bridge-request-otp',
					payload: props,
				},
				'*'
			);
			return new Promise((resolve) => {
				const fn = ({ data }) => {
					/* istanbul ignore else */
					if (data?.name === 'bridge-response-otp') {
						window.removeEventListener('message', fn);
						resolve(data.payload);
					}
				};

				window.addEventListener('message', fn);
			});
		},

		async send({ processId, otpValue }) {
			const url = `/validation/${processId}`;
			const method = 'PATCH';
			const serviceStore = useServiceStore();
			return serviceStore.request({
				service: {
					request: {
						url,
						method,
					},
				},
				payload: { otpValue: otpValue.toString() },
			});
		},

		async sendEmailCode({ processId, otpValue }) {
			const url = `/additionalValidation/${processId}`;
			const method = 'PATCH';
			const serviceStore = useServiceStore();

			return serviceStore.request({
				service: {
					request: {
						url,
						method,
					},
				},
				payload: { otpValue: otpValue.toString() },
			});
		},

		async requestCode(processId) {
			const url = `/validation/${processId}`;
			const method = 'PATCH';
			const serviceStore = useServiceStore();

			return serviceStore.request({
				service: {
					request: {
						url,
						method,
					},
				},
				payload: { otpValue: 'resend' },
			});
		},

		disableModal() {
			this.setModalState(false);
		},

		enableModal() {
			this.setModalState(true);
		},
	},
});
