import { Factory } from 'miragejs';
import { faker } from '@faker-js/faker';

faker.seed(1);

export default Factory.extend({
	id: () => faker.finance.routingNumber(),
	reason: () => faker.lorem.words(4),
	amount: {
		amount: () => parseFloat(faker.finance.amount()),
		currency: { id: 'EUR' },
	},
	beneficiary: {
		toAccount: {
			productNumber: {
				format: {
					id: 'IBAN',
					name: 'IBAN',
				},
			},
			value: () => faker.finance.accountNumber(),
		},
		description: () => faker.lorem.words(20),
	},
	date: () => faker.date.past(0.4, '2022-00-00'),
	status: 'Pagada',
	ebContract: () => faker.finance.bic(),
});
