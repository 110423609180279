import { Factory } from 'miragejs';
import { faker } from '@faker-js/faker';

export default Factory.extend({
	connection: {
		connectionDate: () => faker.date.past(1).toISOString(),
		connectionType: () => faker.helpers.arrayElement(['LOGIN', 'REGISTER']),
		connectionStatus: () => faker.helpers.arrayElement(['OK', 'ERROR']),
	},
});
