import { Factory } from 'miragejs';
import { faker } from '@faker-js/faker';

const vertical = [
	{
		id: 1,
		description: 'Ingeniería',
	},
	{
		id: 2,
		description: 'Salud',
	},
	{
		id: 99,
		description: 'Otras verticales',
	},
];

const collective = [
	{
		id: 1,
		description: 'Ingeniero Civil',
	},
	{
		id: 2,
		description: 'Ingeniero Informático',
	},
	{
		id: 3,
		description: 'Médico',
	},
	{
		id: 4,
		description: 'Abogado',
	},
];

export default Factory.extend({
	documentId: () => faker.string.uuid(),
	email: () => faker.internet.email(),
	date: () => faker.date.past(1).toISOString(),
	state: '0',
	reason: '0',
	origin: '0',
	vertical: () => faker.helpers.arrayElement(vertical),
	collective: () => faker.helpers.arrayElement(collective),
});
