 

const iconName = 'noInternet';
const width = 33;
const height = 34;
const svgPathData =
	'M12.767 12.767c.466-.467 1.233-.467 1.733 0 .467.466.467 1.233 0 1.733l-2.833 2.833 3 3L17.5 17.5c.467-.5 1.233-.5 1.733 0 .467.5.467 1.267.034 1.767L16.433 22.1l2.467 2.467c.567.533.567 1.433 0 2-.267.266-.633.4-1 .4s-.733-.134-1-.4l-.433-.434-1.434 1.434c-1.466 1.466-3.366 2.2-5.3 2.2-1.466 0-2.933-.434-4.2-1.3L2.6 31.4c-.267.267-.633.4-1 .4s-.7-.1-1-.4c-.533-.533-.533-1.467 0-2l2.933-2.933c-1.966-2.9-1.666-6.934.9-9.5l1.434-1.434-.434-.433c-.566-.533-.566-1.433 0-2 .567-.567 1.434-.567 2 0l2.5 2.5zm-4.9 4.766l-1.434 1.434c-1.833 1.833-1.833 4.766 0 6.6 1.834 1.833 4.767 1.833 6.6 0l1.434-1.434-6.6-6.6zM29.433.6c.534-.567 1.434-.567 2 0 .534.533.534 1.433-.033 1.967L28.467 5.5c.833 1.233 1.3 2.667 1.3 4.2 0 2-.8 3.9-2.2 5.3l-1.434 1.433.434.434c.566.533.566 1.433 0 2-.267.266-.634.4-1 .4-.367 0-.734-.134-1-.4L13.133 7.433c-.566-.533-.566-1.433 0-2 .567-.566 1.434-.566 2 0l.434.434L17 4.433c2.5-2.5 6.6-2.8 9.5-.9zm-3.866 5.833c-1.767-1.766-4.834-1.766-6.6 0l-1.434 1.434 6.6 6.6 1.434-1.434c.9-.866 1.366-2.066 1.366-3.3 0-1.233-.5-2.433-1.366-3.3z';

export default {
	iconName: iconName,
	icon: [width, height, svgPathData],
};


