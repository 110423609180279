import {
	UUID_EXPIRED,
	USER_WILL_BE_TEMP_BLOCKED,
	USER_WAS_TEMP_BLOCKED,
	USER_WILL_BE_PERMANENTLY_BLOCKED,
	USER_WAS_PERMANENTLY_BLOCKED,
	OTP_REQUIRED,
	REMEMBER_TOKEN_INVALID,
	REQUEST_TIMEOUT,
} from '@modules/service/constants';

import { defineStore } from 'pinia';

import { useAppStore } from '@local-modules/app/m-app';
import { useSecureStore } from '@modules/secure/m-secure';
import { useOtpStore } from '@modules/otp/m-otp';

export const useErrorHandlerStore = defineStore('m-error-handler', () => {
	//Acciones
	const handle = async ({ error, requestConfig }) => {
		const { useAuthStore } = await import('@modules/auth/m-authn'); //Dependencia circular
		const { useServiceStore } = await import('@modules/service/m-service'); //Dependencia circular

		const authStore = useAuthStore();
		const serviceStore = useServiceStore();
		const appStore = useAppStore();
		const secureStore = useSecureStore();
		const otpStore = useOtpStore();
		const modalStore = useModalStore();
		const sessionStore = useSessionStore();

		const isCBNK = appStore.getIsCbnk;

		const prefix = isCBNK ? 'cbnk-' : '';

		if (error?.message === 'Network Error') {
			serviceStore.setOffline(true);
		}
		if (error.code === REQUEST_TIMEOUT) {
			const component = await import('@modals/m-request-timeout.vue');
			modalStore.open(component);
			return error;
		}

		/* istanbul ignore next */
		if (!error.response) {
			// TODO mostrar una modal de servidor caído o algo así
			// TODO deberíamos advertir a bugsnag de esto.
			return error;
		}

		const { response } = error;
		const { errorCode } = response.data;
		let returnReq = error;
		switch (errorCode) {
			case UUID_EXPIRED: {
				const agent = null; //TODOVUE3 -> MODIFICAR MODULO ASSISTED CHANNEL
				const authn = authStore.getIsLoggedIn;

				if (authn) {
					await authStore.passiveLogout();
				} else if (agent) {
					await agentStore.passiveLogout();
				} else {
					await secureStore.refreshSession();
					return new Promise((resolve) => {
						serviceStore
							.request(requestConfig)
							.then((res) => {
								resolve(res);
							})
							.catch((err) => {
								resolve(err);
							});
					});
				}
				break;
			}

			case REMEMBER_TOKEN_INVALID: {
				await sessionStore.removeUserSession();
				await sessionStore.forgetUserSession();

				break;
			}

			case USER_WILL_BE_TEMP_BLOCKED: {
				const component = await import(`@modals/m-${prefix}sign-temp-error.vue`);

				await modalStore.open({ component });

				break;
			}

			case USER_WAS_TEMP_BLOCKED: {
				const { unlockingTime = 0 } = response.data.additionalInfo;
				const minutes = Math.max(1, Math.ceil(unlockingTime / 1000 / 60));
				const props = { minutes };
				const component = await import(`@modals/m-${prefix}sign-temp-blocked.vue`);

				await modalStore.open({ component, props });
				break;
			}

			case USER_WILL_BE_PERMANENTLY_BLOCKED: {
				const component = await import(`@modals/m-${prefix}sign-error.vue`);

				await modalStore.open({ component });
				break;
			}

			case USER_WAS_PERMANENTLY_BLOCKED: {
				const component = await import(`@modals/m-${prefix}sign-blocked.vue`);

				const resp = await modalStore.open({ component });

				if (resp) {
					returnReq.response.data.errorCode = resp;
				}
				break;
			}

			case OTP_REQUIRED: {
				const { processId } = response.data?.additionalInfo || {};
				const component = await import(`@modals/m-cbnk-otp.vue`);

				const otpHandle = await otpStore.handle({
					component,
					props: {
						processId,
						sca: !authStore.getIsLoggedIn,
					},
				});

				/* istanbul ignore else */
				if (otpHandle) {
					returnReq = otpHandle;
				}
				break;
			}

			case UNPROCESSABLE_ENTITY_BLOCKED_OPERATION: {
				const component = await import(`@modals/m-unprocessable-error.vue`);
				await modalStore.open({ component });
				break;
			}
		}

		return returnReq;
	};

	return {
		handle,
	};
});
