import { Factory } from 'miragejs';
import { faker } from '@faker-js/faker';

faker.seed(1);

export default Factory.extend({
	id: () => faker.string.uuid(),
	iban: () => faker.helpers.replaceSymbols('ES##0234################'),
	bic: () => '',
	beneficiary: () => faker.person.fullName(),
	alias: () => faker.lorem.sentence(3),
});
