import { Factory } from 'miragejs';
import { faker } from '@faker-js/faker';

faker.seed(1);

const types = [
	{
		id: '01',
		name: 'TITULAR',
	},
	{
		id: '02',
		name: 'USUFRUCTUARIO',
	},
	{
		id: '03',
		name: 'AVALISTA',
	},
	{
		id: '05',
		name: 'BENEFICIARIO',
	},
	{
		id: '06',
		name: 'AUTORIZADO',
	},
	{
		id: '07',
		name: 'APODERADO',
	},
	{
		id: '11',
		name: 'REPRESENTANTE LEGAL',
	},
	{
		id: '42',
		name: 'ASOCIADO BE',
	},
	{
		id: '43',
		name: 'CONSULTA BE',
	},
	{
		id: '99',
		name: 'OTROS',
	},
];

const personSubTypes = ['F', 'J'];

export default Factory.extend({
	documentId: () => faker.string.uuid(),
	id: () => faker.string.uuid(),
	name: () => `${faker.person.firstName()} ${faker.person.lastName()}`,
	relationType: () => faker.helpers.arrayElement(types),
	personSubtype: () => faker.helpers.arrayElement(personSubTypes),
});
