import { defineStore } from 'pinia';
import { useServiceStore } from '@modules/service/m-service';
const USER_SESSION_KEY = 'userSession';
const THEME_SESSION_KEY = 'themeSession';
const LANG_SESSION_KEY = 'langSession';
const NEWS_SESSION_KEY = 'newsSession';

export const useSessionStore = defineStore('m-session', {
	state: () => {
		const userSession = JSON.parse(localStorage.getItem(USER_SESSION_KEY)) || {};
		const themeSession = JSON.parse(localStorage.getItem(THEME_SESSION_KEY)) || {};
		const langSession = JSON.parse(localStorage.getItem(LANG_SESSION_KEY)) || {};
		const newsSession = localStorage.getItem(NEWS_SESSION_KEY) || '';

		const { userName = '', lastLogin = null, rememberToken = '' } = userSession;
		const { theme = 'light' } = themeSession;
		const { lang = 'es' } = langSession;
		const isNewsOmitted = newsSession;
		return {
			userGreeted: false,
			userName,
			lastLogin,
			rememberToken,
			theme,
			lang,
			isNewsOmitted,
			isMinor: false,
			userId: null,
			vertical: 0,
			collective: 0,
		};
	},

	getters: {
		getRememberToken() {
			return this.rememberToken;
		},
		getUserName() {
			return this.userName;
		},
		getLang() {
			return this.lang;
		},
		getVertical() {
			return this.vertical;
		},
	},

	actions: {
		setVertical({ vertical, collective }) {
			this.vertical = vertical;
			this.collective = collective;
		},

		setUserData(data) {
			this.userName = data.userName;
			this.lastLogin = data.lastLogin;
			this.userId = data?.userId;
		},

		setUserRole(isMinor) {
			this.isMinor = isMinor;
		},

		setUserSession(sessionData) {
			this.setUserData(sessionData);
			this.userGreeted = false;
		},

		rememberUserSession({ userName, lastLogin, rememberToken }) {
			this.setUserSession({
				userName,
				lastLogin,
			});
			this.rememberToken = rememberToken;

			localStorage.setItem(
				USER_SESSION_KEY,
				JSON.stringify({
					userName,
					lastLogin,
					rememberToken,
				})
			);
		},

		removeUserSession() {
			this.userName = '';
			this.lastLogin = null;
			this.rememberToken = null;
			sessionStorage.removeItem('SECURE_SESSION');
		},

		/* istanbul ignore next */
		forgetUserSession() {
			localStorage.removeItem(USER_SESSION_KEY);
		},

		/* istanbul ignore next */
		loadUserSession() {
			const userSession = JSON.parse(localStorage.getItem(USER_SESSION_KEY)) || {};
			const { userName = '', lastLogin = null, rememberToken = '' } = userSession;

			this.setUserSession({
				userName,
				lastLogin,
			});
			this.rememberToken = rememberToken;
		},

		markUserGreeted() {
			this.userGreeted = true;
		},

		setAppTheme(theme) {
			this.theme = theme;
			localStorage.setItem(THEME_SESSION_KEY, JSON.stringify({ theme }));
		},

		changeLanguage({ lang }) {
			this.lang = lang;
			localStorage.setItem(LANG_SESSION_KEY, JSON.stringify({ lang }));

			// To notify backend the language change so that if any documents are downloaded these will be translated.
			const method = 'PATCH';
			const url = `/current/user/language`;

			const serviceStore = useServiceStore();

			serviceStore.request({
				service: {
					request: {
						url,
						method,
					},
				},
				payload: { language: lang },
			});
		},

		skipNews(version) {
			this.isNewsOmitted = version;
			localStorage.setItem(NEWS_SESSION_KEY, version);
		},

		deleteSession() {
			const method = 'DELETE';
			const url = '/session';

			const serviceStore = useServiceStore();

			return serviceStore.request({
				service: {
					request: {
						url,
						method,
					},
				},
			});
		},

		deleteCache() {
			const method = 'DELETE';
			const url = '/cache';

			const serviceStore = useServiceStore();

			return serviceStore.request({
				service: {
					request: {
						url,
						method,
					},
				},
			});
		},
	},
});
