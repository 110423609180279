
			var version = window.VITE_APP_CONFIG?.version;
			console.log('Version:', version || 'dev');

			import noScriptHTML from '/_browser-support.html?url&raw';
			const noscriptElement = document.getElementById('browser-not-supported');
			noscriptElement.innerHTML = noScriptHTML;

			import indexSplashHTML from '/_splash.html?url&raw';
			const indexSplashElement = document.getElementById('index-splash');
			indexSplashElement.innerHTML = indexSplashHTML;

			//Fade animation functions
			let splashElement = document.getElementById('splash');
			function removeSplashScreen() {
				splashElement.remove();

				splashElement = null;
				window.dispatchEvent(new Event('splashscreen-unload'));
			}
			function fadeOutSplashScreen() {
				splashElement.addEventListener('transitionend', removeSplashScreen);
				splashElement.classList.add('fadeout');
			}

			function doubleRAF(cb) {
				requestAnimationFrame(function () {
					requestAnimationFrame(cb);
				});
			}

			function disassembleSplashScreen() {
				doubleRAF(function () {
					splashElement.classList.add('stop');
					setTimeout(function () {
						splashElement.classList.add('disassemble');
						setTimeout(fadeOutSplashScreen, 500);
					}, 16);
				});
			}

			try {
				var modernBrowser =
					'fetch' in window && 'assign' in Object && 'finally' in Promise.prototype;
				if (modernBrowser) {
					window.addEventListener('load', () => {
						disassembleSplashScreen();
					});
				} else {
					throw new Error('browser is not supported');
				}
			} catch (error) {
				var notSupporterContainer = document.getElementById('browser-not-supported');
				var container = document.createElement('div');
				container.innerHTML = notSupporterContainer.innerText;
				notSupporterContainer.parentNode.replaceChild(container, notSupporterContainer);
			}
		