import { Factory } from 'miragejs';
import { faker } from '@faker-js/faker';

export default Factory.extend({
	idConsent: () => faker.string.uuid(),
	status: 'ACCEPTED',
	signupDate: () => faker.date.past(1).toISOString(),
	lastConnectionDate: () => faker.date.past(1).toISOString(),
	expirationDate: () => faker.date.past(2).toISOString(),
	lastUpdateDate: () => faker.date.past(1).toISOString(),
	ecommerceId: () => faker.number.int(2),
	ecommerceName: () => faker.company.name(),
	acceptedConsents: ['NAME', 'PHONE_NUM', 'ID_TYPE', 'ID_NUM', 'ALIAS'],
	mandatoryConsents: [
		{
			id: 'NAME',
			value: () => faker.person.firstName(),
		},
		{
			id: 'PHONE_NUM',
			value: () => faker.phone.number('+34 ### ### ###'),
		},
		{
			id: 'ID_TYPE',
			value: 'NIF',
		},
		{
			id: 'ID_NUM',
			value: () =>
				faker.number.int({
					min: 10000000,
					max: 99999999,
				}),
		},
		{
			id: 'ALIAS',
			value: () => faker.person.firstName().toUpperCase(),
		},
	],
	optionalConsents: [],
});
