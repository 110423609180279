import { Factory, trait } from 'miragejs';
import { faker } from '@faker-js/faker';

faker.seed(1);

export default Factory.extend({
	owner: trait({
		id: () => faker.string.uuid(),
		description: () => `${faker.person.firstName()} ${faker.person.lastName()}`,
		type: 'owner',
		status: 'active',
	}),
	user: trait({
		id: () => faker.string.uuid(),
		description: () => `${faker.person.firstName()} ${faker.person.lastName()}`,
		type: 'user',
		status: 'banned',
	}),
	insurance: trait({
		id: () => faker.string.uuid(),
		description: 'SEGUROS',
		type: 'owner',
		status: 'active',
	}),
});
