import { Factory } from 'miragejs';
import { faker } from '@faker-js/faker';

faker.seed(1);

export default Factory.extend({
	biometricActive: () => faker.datatype.boolean(),
	deviceModel: () => faker.word.sample(),
	isMyDevice: () => faker.datatype.boolean(),
	lastScaDate: () => faker.date.past().toISOString(),
	lastLoginAccessDate: () => faker.date.past().toISOString(),
	maxUsers: () =>
		faker.number.int({
			min: 1,
			max: 10,
		}),
	operationSystem: () => faker.word.sample(),
	pushActive: () => faker.datatype.boolean(),
});
