 

const iconName = 'whatsapp';
const width = 16;
const height = 16;
const svgPathData =
	'M10.78 9.164c-.322-.192-.74-.406-1.118-.251-.29.118-.476.573-.664.805-.097.12-.211.138-.36.078-1.09-.434-1.925-1.162-2.526-2.164-.102-.156-.084-.279.039-.423.181-.214.41-.457.459-.745.049-.289-.086-.626-.205-.882-.153-.328-.323-.796-.651-.982-.303-.17-.7-.075-.97.145-.464.378-.688.97-.681 1.557.002.168.022.334.061.495.094.388.273.75.475 1.094.152.258.317.51.494.752a7.656 7.656 0 0 0 2.149 1.995c.42.257.871.482 1.339.637.525.173.992.354 1.56.246.592-.113 1.178-.48 1.413-1.052a.917.917 0 0 0 .066-.536c-.08-.37-.581-.59-.88-.769 M8.062 14.256a6.32 6.32 0 0 1-3.231-.895l-.268-.16-2.373.637.633-2.36-.165-.27a6.316 6.316 0 0 1-.925-3.281c0-3.49 2.839-6.33 6.329-6.33 3.49 0 6.33 2.84 6.33 6.33s-2.84 6.329-6.33 6.329zm0-14.135C3.759.121.257 3.623.257 7.927a7.78 7.78 0 0 0 .98 3.77L.102 15.924l4.249-1.139a7.78 7.78 0 0 0 3.71.946c4.304 0 7.805-3.5 7.805-7.804S12.366.12 8.062.12z';

export default {
	iconName: iconName,
	icon: [width, height, svgPathData],
};


