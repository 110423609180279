import { defineStore } from 'pinia';

import { useBugsnagStore } from '@modules/bugsnag/m-bugsnag';

export const useNotificationsStore = defineStore('notifications', {
	state: () => ({
		name: 'notification',
		queue: {},
		lastUID: 0,
		timeout: 4000,
	}),

	actions: {
		/**
		 * Abre una vista modal.
		 *
		 * @param {Object} data
		 * @param {Object} data.component Componente Modal.
		 * @param {Object} data.props Props para pasar al componente del modal.
		 */
		async open(data) {
			const bugsnagStore = useBugsnagStore();
			return new Promise((resolve) => {
				const syncComponent = data.component ?? data;
				const modal = syncComponent.default ?? syncComponent;
				const props = data.component && data.props ? data.props : {};
				const layer = data.layer ?? 1;

				// Extiende los datos solo si es un componente
				const Data = modal.render ? modal : modal;
				const componentOptions =
					Data && Data.options ? { name: Data.options.name } : { text: data.text };

				// Aquí se loguea el evento
				bugsnagStore.log({
					title: `${this.name} open`,
					...componentOptions,
				});

				// Aumenta el último UID y agrega el modal a la cola
				this.lastUID += 1;
				this.queue[this.lastUID] = {
					component: Data,
					props,
					layer,
				};

				const modalId = this.lastUID;

				const onRemove = (params) => {
					const { closedId, payload } = params;

					if (closedId === modalId) {
						this.$emitter.off('remove', onRemove);

						resolve(payload);
					}
				};

				this.$emitter.on('remove', onRemove);

				const timeoutDuration = modal.timeout || props.timeout || this.timeout;

				// Si el modal tiene un timeout, lo configuramos
				if (modal.timeout !== Infinity && timeoutDuration) {
					setTimeout(() => {
						this.close({ id: modalId });
					}, timeoutDuration);
				}
			});
		},

		/**
		 * Cierra un modal.
		 *
		 * @param {Number} id ID del modal a cerrar.
		 */
		close({ id = this.lastUIDOpened, payload } = {}) {
			delete this.queue[id];

			this.$emitter.emit('remove', { closedId: id, payload });
		},

		/**
		 * Reemplaza el modal actual con otro.
		 *
		 * @param {Object} modal Componente del nuevo modal.
		 */
		async replace(modal) {
			const id = this.lastUIDOpened;

			delete this.queue[id];
			await this.open(modal);
			this.$emitter.emit('remove', id);
		},

		/**
		 * Cierra todos los modales.
		 */
		closeAll() {
			Object.keys(this.queue).forEach(() => {
				this.close();
			});
		},
	},

	getters: {
		/**
		 * Obtiene el último UID abierto.
		 */
		lastUIDOpened() {
			const [lastUID] = Object.keys(this.queue).slice(-1);
			return parseInt(lastUID, 10);
		},

		/**
		 * Obtiene el último modal abierto.
		 */
		lastOpened() {
			return this.queue[this.lastUIDOpened];
		},
		getQueue() {
			return this.queue;
		},
	},
});
