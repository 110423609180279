import { Factory } from 'miragejs';
import { faker } from '@faker-js/faker';

faker.seed(1);

const images = [
	'/news/w1cut.jpg',
	'/news/m1cut.jpg',
	'/news/w2cut.jpg',
	'/news/m2cut.jpg',
	'/news/w3cut.jpg',
	'/news/m3cut.jpg',
	'/news/m4cut.jpg',
];

export default Factory.extend({
	id: () => faker.lorem.words(2),
	companyId: () => faker.helpers.arrayElement(['BC', 'FA']),
	spaceId: () =>
		faker.number
			.int({
				min: 1,
				max: 3,
			})
			.toString(),
	typeId: () =>
		faker.number
			.int({
				min: 1,
				max: 4,
			})
			.toString(),
	status: () => faker.helpers.arrayElement(['Published', 'Unpublished', 'Draft']),
	description: () => faker.lorem.words(2),
	startDate: () => faker.date.past(1).toISOString(),
	expirationDate: () => faker.date.past(1).toISOString(),
	channel: () => faker.helpers.arrayElement(['IOS', 'ANDROID', 'WEB']),
	fromVersion: () =>
		faker.number
			.int({
				min: 1,
				max: 5,
			})
			.toString(),
	toVersion: () =>
		faker.number
			.int({
				min: 5,
				max: 10,
			})
			.toString(),
	allUsers: () => faker.datatype.boolean(),
	impressionsLimit: () =>
		faker.number.int({
			min: 0,
			max: 5,
		}),
	translations: () => {
		const tr = [
			{
				language: 'es',
				title: faker.lorem.words(2),
				body: faker.lorem.sentence(),
				imageLink: faker.helpers.arrayElement(images),
				ctaText: faker.lorem.sentence(),
				ctaType: faker.helpers.arrayElement([
					'open_internal',
					'open_external',
					'open_file',
					'acceptance',
				]),
				ctaRedirect: 'https://'.concat(faker.lorem.sentence()),
			},
		];
		if (faker.datatype.boolean()) {
			tr.push({
				language: 'en',
				title: faker.lorem.words(2),
				body: faker.lorem.sentence(),
				imageLink: 'https://'.concat(faker.lorem.sentence()),
				ctaText: faker.lorem.sentence(),
				ctaType: faker.helpers.arrayElement([
					'open_internal',
					'open_external',
					'open_file',
					'acceptance',
				]),
				ctaRedirect: 'https://'.concat(faker.lorem.sentence()),
			});
		}
		if (faker.datatype.boolean()) {
			tr.push({
				language: 'ca',
				title: faker.lorem.words(2),
				body: faker.lorem.sentence(),
				imageLink: 'https://'.concat(faker.lorem.sentence()),
				ctaText: faker.lorem.sentence(),
				ctaType: faker.helpers.arrayElement([
					'open_internal',
					'open_external',
					'open_file',
					'acceptance',
				]),
				ctaRedirect: 'https://'.concat(faker.lorem.sentence()),
			});
		}
		return tr;
	},
});
