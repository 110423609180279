import { defineStore } from 'pinia';

const ua = navigator.userAgent;
let isHybridSky = ua.includes('HybridSky');

if (import.meta.env.VITE_APP_ENDPOINT_MODE === 'mck') {
	isHybridSky = import.meta.env.VITE_APP_HYBRID === 'true';
}

export const useAppStore = defineStore('m-app', {
	state: () => ({
		isCBNK: true,
		isEmbedded: false,
		isHybrid: ua.includes('Skybrid'),
		isHybridSky,
		isIOS: /iPad|iPhone|iPod/.test(ua),
		isCvvPanActive: window.VITE_APP_CONFIG?.cvvPanActive === 'true',
		isNavigationDisabled: false,
		companyId: 'BC',
		onboardingUrl:
			'https://onboarding-caminos.grupocaminos.es/?utm_source=bco&utm_medium=haztecliente&utm_campaign=cuenta_transparente_bco&utm_term=CCWN4B',
		telephone: '+34 900 107 411 / +34 913 109 550',
		whatsapp: '+34 628 500 200',
		email: '',
		cookiesPolicyLink: 'https://www.cbnk.es/aviso-legal#politica-de-cookies',
		bizumSafetyAdvice: 'https://bizum.es/blog/consejos-de-seguridad-de-bizum/',
		measurementId: '',
		legalIdentity:
			'CBNK Banco de Colectivos, S.A. Reg. Mercantil Madrid, T.23454, F.173, S.8, H.M-81730, inscripción 95, Código B.E. 0234, N.I.F: A28520666',
		legalDisclaimerLink: 'http://www.cbnk.es/aviso-legal',
		liveagent: {},
	}),

	getters: {
		getIsCbnk() {
			return this.isCBNK;
		},
		getCompanyId() {
			return this.companyId;
		},
		getIsHybrid() {
			return this.isHybrid;
		},
		getIsHybridSky() {
			return this.isHybridSky;
		},
		getLiveagent() {
			return this.liveagent;
		},
		getIsEmbedded() {
			return this.isEmbedded;
		},
	},
	actions: {
		setIsEmbedded() {
			this.isEmbedded = true;
		},

		setIsNavigationDisabled(value) {
			this.isNavigationDisabled = value;
		},

		mergeConfig({ config }) {
			Object.assign(this, config);
		},
	},
});
