import { defineStore } from 'pinia';
import axios from 'axios';
import { useSecureStore } from '@modules/secure/m-secure';
import { useErrorHandlerStore } from '@modules/service/m-error-handler'; // Suponiendo que esta es la importación correcta
const MAX_REQ_TIMEOUT = 120000;

export const useServiceStore = defineStore('m-service', {
	// Estado reactivo
	state: () => ({
		baseURL:
			window.VITE_APP_CONFIG.endpoint ||
			import.meta.env.VITE_APP_ENDPOINT ||
			'https://api.grupocaminos.es',
		req: axios.create(),
		lang: 'es-ES',
		timeout: MAX_REQ_TIMEOUT,
		lastRequestTimestamp: new Date(),
		offline: false,
	}),

	getters: {
		getBaseURL(state) {
			return state.baseURL;
		},
		getOffline() {
			return this.offline;
		},
	},

	// Acciones
	actions: {
		// Acción para actualizar el timestamp
		updateTimestamp(value) {
			this.lastRequestTimestamp = value;
		},

		// Acción para establecer el estado offline
		setOffline(value) {
			this.offline = value;
		},

		// Acción para actualizar el timeout
		updateTimeout(value) {
			this.timeout = value;
		},

		// Acción para restablecer el timeout
		resetTimeout() {
			this.timeout = MAX_REQ_TIMEOUT;
		},

		// Acción para realizar una solicitud
		async request({ service, params, queryParams, payload, encrypt = true }) {
			const servicePro = { request: { ...service.request } };
			let payloadRequest = {};

			const secure = useSecureStore();

			// Encriptar el payload si es necesario
			if (payload && encrypt) {
				const encryptedPayload = await secure.encrypt(payload);
				payloadRequest.data = { payload: encryptedPayload };
			}

			// Si no se encripta, enviar el payload directamente
			if (payload && !encrypt) {
				payloadRequest.data = payload;
			}

			// Reemplazar los parámetros en la URL
			if (params) {
				servicePro.request.url = parseStringTemplate(servicePro.request.url, params);
			}
			return new Promise((resolve, reject) => {
				const defaultHeaders = {
					'Content-Type': 'application/json',
					'uuid': secure.getUuid,
				};

				const headers = service.headers || defaultHeaders;
				// Actualizar el timestamp de la última solicitud
				this.updateTimestamp(new Date());

				// Manejador de éxito
				const onSuccess = async (response) => {
					if (
						window.VITE_APP_CONFIG?.env === 'dev' ||
						import.meta?.env.MODE === 'development'
					) {
						const { groupCollapsed } = console;
						groupCollapsed(
							'%cVuesoma: ['
								.concat(response?.status, '] ')
								.concat(response?.config?.method.toUpperCase(), ' ')
								.concat(response?.config?.baseURL, response?.config.url),
							'color: #3eaf7c;'
						);
					}

					const { data } = response;
					if (data && typeof data === 'string' && encrypt) {
						const decryptedData = await secure.decrypt(response.data);
						response.data = decryptedData;
					}

					/* istanbul ignore next */
					if (
						window.VITE_APP_CONFIG?.env === 'dev' ||
						import.meta?.env.MODE === 'development'
					) {
						const { groupCollapsed, log, groupEnd } = console;

						groupCollapsed('Request (url)');
						log(response?.request?.responseURL);
						groupEnd();

						if (payload) {
							groupCollapsed('Request (data)');
							log(JSON.parse(JSON.stringify(payload)));
							groupEnd();
						}

						if (response.data) {
							groupCollapsed('Response');
							log(response.data);
							groupEnd();
						}

						groupEnd();
					}

					return resolve(response);
				};

				// Manejador de fallo
				const onFailure = async (err) => {
					/* istanbul ignore next */
					if (
						window?.VITE_APP_CONFIG?.env === 'dev' ||
						import.meta.env?.MODE === 'development'
					) {
						const { groupCollapsed, log, groupEnd } = console;
						const status = err.response?.status;
						let method = err.response?.config?.method.toUpperCase();
						let url = err.response?.config?.baseURL + err.response?.config.url;

						if (err?.description === 'Mirage: undefined') {
							[, method, url] = err.message.match(/tried to ([A-Z]*) '(.+)'/);
						}

						groupCollapsed(
							'%cVuesoma: ['.concat(status, '] ').concat(method, ' ').concat(url),
							'color: #3eaf7c;'
						);

						groupCollapsed('Request (url)');
						log(err.request?.responseURL);
						groupEnd();

						if (payload) {
							groupCollapsed('Request (data)');
							log(JSON.parse(JSON.stringify(payload)));
							groupEnd();
						}

						if (err.response) {
							groupCollapsed('Response');
							log(err.response);
							groupEnd();
						}

						groupEnd();
					}
					const errorHandlerStore = useErrorHandlerStore();
					const error = await errorHandlerStore.handle({
						error: err,
						requestConfig: { service, params, queryParams, payload },
					});
					if (error?.config?.validateStatus(error?.status)) {
						return onSuccess(error);
					}
					reject(error);
				};
				// Realizar la solicitud
				this.req
					.request({
						baseURL: this.baseURL,
						headers,
						timeout: this.timeout,
						params: queryParams,
						...servicePro.request,
						...payloadRequest,
					})
					.then(onSuccess)
					.catch(onFailure);
			});
		},
	},
});
