<template>
	<div
		class="c-contact-support-info"
		:class="{ '--is-vertical': isVertical }"
	>
		<div class="c-contact-support-info__container">
			<div class="c-contact-support-info__wrapper">
				<c-icon
					class="c-contact-support-info__icon"
					src="@icons/phone"
					size="l"
				/>

				<p class="c-contact-support-info__title text-m-book">
					{{ $t('CONTACT_SUPPORT.TEL_BANK') }}
				</p>

				<div class="c-contact-support-info__telephone-numbers">
					<c-telephone :number="telephones.number1" />

					<span
						v-if="telephones.number2"
						class="c-contact-support-info__slash text-m-book"
					>
						{{ String('/') }}
					</span>

					<c-telephone
						v-if="telephones.number2"
						:number="telephones.number2"
					/>
				</div>

				<p class="c-contact-support-info__desc text-m-book">
					{{ $t('CONTACT_SUPPORT.TELEPHONE_TIME') }}
				</p>
			</div>
		</div>

		<span
			v-if="isVertical"
			class="c-contact-support-info__line"
		></span>

		<div class="c-contact-support-info__container">
			<div class="c-contact-support-info__wrapper">
				<c-icon
					class="c-contact-support-info__icon"
					src="@icons/whatsapp"
					size="l"
				/>

				<p class="c-contact-support-info__title text-m-book">
					{{ $t('WHATSAPP') }}
				</p>

				<c-telephone
					:number="whatsapp"
					type="whatsapp"
				/>

				<p class="c-contact-support-info__desc text-m-book">
					{{ $t('CONTACT_SUPPORT.WHATSAPP_TIME') }}
				</p>
			</div>
		</div>
	</div>
</template>

<script>
import { mapState } from 'pinia';
import { useAppStore } from '@local-modules/app/m-app';
import CIcon from '@components/c-icon.vue';
import CTelephone from '@components/c-telephone.vue';

export default {
	name: 'c-contact-support-info',

	components: {
		CIcon,
		CTelephone,
	},

	props: {
		isVertical: {
			type: Boolean,
			default: true,
		},
	},

	computed: {
		...mapState(useAppStore, ['whatsapp', 'telephone']),

		telephones({ telephone }) {
			const [number1, number2] = telephone.replace(/\s+/g, '').concat('/').split('/');

			return {
				number1,
				number2,
			};
		},
	},
};
</script>

<style lang="scss" scoped>
.c-contact-support-info {
	display: flex;
	position: relative;
	width: 100%;
	border: 1px solid RGB(var(--color-surface-dark));
}

.c-contact-support-info.--is-vertical {
	flex-direction: column;
}

.c-contact-support-info.--is-vertical .c-contact-support-info__container {
	width: 100%;
}

.c-contact-support-info__container {
	display: flex;
	position: relative;
	width: 50%;
	justify-content: center;
	padding: var(--spacing-l);
}

.c-contact-support-info__wrapper {
	display: flex;
	position: relative;
	flex-direction: column;
	align-items: center;
	text-align: center;
}

.c-contact-support-info__wrapper > * {
	margin-bottom: var(--spacing-s);
}

.c-contact-support-info__line {
	border: 1px solid RGB(var(--color-secondary));
	margin: var(--spacing-none) var(--spacing-l);
}

.c-contact-support-info__telephone-numbers {
	display: flex;
	flex-wrap: nowrap;
}

.c-contact-support-info__slash {
	padding: var(--spacing-none) var(--spacing-s);
}
</style>
