import { Factory } from 'miragejs';
import { faker } from '@faker-js/faker';

faker.seed(1);

export default Factory.extend({
	userId: () => faker.string.uuid(),
	name: () => faker.lorem.words(1),
	firstSurname: () => faker.lorem.words(2),
	impressions: () =>
		faker.number
			.int({
				min: 0,
				max: 10,
			})
			.toString(),
	impressionLimit: () =>
		faker.number
			.int({
				min: 0,
				max: 100,
			})
			.toString(),
	documentId: () =>
		faker.number
			.int({
				min: 10000000,
				max: 99999999,
			})
			.toString()
			.concat(faker.helpers.arrayElement(['A', 'B', 'C', 'S', 'M', 'X', 'Y', 'Z'])),
	visible: () => true,
});
