import { defineStore } from 'pinia';
import SessionCache from '@plugins/cache/index';
import { OTP_NO_TELEPHONE_ERROR, OTP_NO_ACTIVE_USER_ERROR } from '@modules/service/constants';

const cache = new SessionCache('user');

import { useServiceStore } from '@modules/service/m-service';
import { useAppStore } from '@local-modules/app/m-app';
import { useSecureStore } from '@modules/secure/m-secure';

export const useUserStore = defineStore('m-user', {
	state: () => ({
		actualView: 'home',
		isDevicesListUpdated: null,
		relatedCustomerData: {},
	}),

	actions: {
		setActualView(value) {
			this.actualView = value;
		},

		setIsDevicesListUpdated(value) {
			this.isDevicesListUpdated = value;
		},

		setRelatedCustomerData(value) {
			this.relatedCustomerData = value;
		},

		doSwitch(value) {
			this.setActualView(value);
		},

		changePassword(password) {
			const url = '/current/user/password';
			const method = 'PUT';

			const serviceStore = useServiceStore();

			return serviceStore.request({
				service: {
					request: {
						url,
						method,
					},
				},
				payload: { password },
			});
		},

		generatePassword({ document_id, birth_date }) {
			const appStore = useAppStore();
			const companyId = appStore.getCompanyId;
			const url = '/user/recoverypwd';
			const method = 'POST';

			const serviceStore = useServiceStore();

			return serviceStore.request({
				service: {
					request: {
						url,
						method,
					},
				},
				payload: {
					document_id,
					birth_date,
					channel: 'WEB',
					company_id: companyId,
				},
			});
		},

		generateTemporaryPassword({ email, phone }) {
			const url = '/user/recoverypwd';
			const method = 'PUT';

			const serviceStore = useServiceStore();

			return serviceStore.request({
				service: {
					request: {
						url,
						method,
					},
				},
				payload: { email, phone },
			});
		},

		getPersonalDetails() {
			const url = '/current/user/';
			const method = 'GET';

			const cacheKey = 'personalDetail';
			if (cache.has(cacheKey)) {
				return Promise.resolve(cache.get(cacheKey));
			}

			const serviceStore = useServiceStore();

			return serviceStore
				.request({
					service: {
						request: {
							url,
							method,
						},
					},
				})
				.then((data) => {
					cache.set(cacheKey, data);

					return cache.get(cacheKey);
				});
		},

		getDevices() {
			const url = '/current/user/devices';
			const method = 'GET';

			const cacheKey = 'devices';

			if (cache.has(cacheKey)) {
				return Promise.resolve(cache.get(cacheKey));
			}

			const serviceStore = useServiceStore();

			return serviceStore
				.request({
					service: {
						request: {
							url,
							method,
						},
					},
				})
				.then(({ data: { data } }) => {
					cache.set(cacheKey, data);

					return cache.get(cacheKey);
				});
		},

		async getDevice(deviceId) {
			let items = cache.get('devices');

			/* istanbul ignore next */
			if (!items) {
				items = await this.getDevices();
			}

			return items.find((device) => device.id === deviceId);
		},

		getDeviceSessions(deviceId) {
			const url = `/current/user/devices/${deviceId}`;
			const method = 'GET';

			const serviceStore = useServiceStore();

			return serviceStore.request({
				service: {
					request: {
						url,
						method,
					},
				},
			});
		},

		deleteDevice(deviceId) {
			const url = `/current/user/devices/${deviceId}`;
			const method = 'DELETE';

			const serviceStore = useServiceStore();

			return serviceStore
				.request({
					service: {
						request: {
							url,
							method,
						},
					},
				})
				.then(() => cache.clear());
		},

		deleteDeviceBiometric(deviceId) {
			const url = `/current/user/devices/${deviceId}/biometric`;
			const method = 'DELETE';

			const serviceStore = useServiceStore();

			return serviceStore
				.request({
					service: {
						request: {
							url,
							method,
						},
					},
				})
				.then(() => cache.clear());
		},

		getAssistedDevices({ data, source, origin }) {
			const secureStore = useSecureStore();
			const userId = data?.userId;
			const userUUID = secureStore.getUuid;

			const url = '/current/user/devices';
			const method = 'GET';

			const serviceStore = useServiceStore();

			return serviceStore
				.request({
					service: {
						request: {
							url,
							method,
						},
					},
				})
				.then((res) => {
					source.postMessage(
						{
							name: 'get-user-devices',
							payload: res.data,
							userUUID,
							userId,
						},
						origin
					);
				});
		},

		ssoLogin() {
			const url = '/current/user/sso-login';
			const method = 'GET';

			const serviceStore = useServiceStore();

			return serviceStore.request({
				service: {
					request: {
						url,
						method,
					},
				},
			});
		},

		getBiometricToken({ userId, deviceId }) {
			const url = `/current/user/devices/${deviceId}/biometric`;
			const method = 'POST';

			const serviceStore = useServiceStore();

			return serviceStore
				.request({
					service: {
						request: {
							url,
							method,
						},
					},
					payload: { userId },
				})
				.then(({ data }) => data);
		},

		getMifidConvenience() {
			const url = '/current/user/mifid/convenience';
			const method = 'GET';

			const serviceStore = useServiceStore();

			return serviceStore
				.request({
					service: {
						request: {
							url,
							method,
						},
					},
				})
				.then(({ data }) => data);
		},

		getMifidClassification({ userId }) {
			const url = `/users/${userId}/mifid/classification`;
			const method = 'GET';

			const serviceStore = useServiceStore();

			return serviceStore.request({
				service: {
					request: {
						url,
						method,
					},
				},
			});
		},

		setMifidClassification({ userId }) {
			const url = `/users/${userId}/mifid/classification`;
			const method = 'POST';

			const serviceStore = useServiceStore();

			return serviceStore.request({
				service: {
					request: {
						url,
						method,
					},
				},
			});
		},

		getNotificationMode({ data, source, origin }) {
			const secureStore = useSecureStore();
			const userId = data?.userId;
			const userUUID = secureStore.getUuid;

			const method = 'GET';
			const url = `/notifications/${userId}`;

			const serviceStore = useServiceStore();

			return serviceStore
				.request({
					service: {
						request: {
							url,
							method,
						},
					},
				})
				.then(({ data: { smsByEmail, otpDefaultMethod } }) =>
					source.postMessage(
						{
							name: 'update-session',
							payload: {
								isNotificationByEmail: smsByEmail,
								pushInfo: otpDefaultMethod,
							},
							userUUID,
							userId,
						},
						origin
					)
				);
		},

		changeNotificationMode({ data, source, origin }) {
			const secureStore = useSecureStore();
			const { userId, smsByEmail, channel } = data;
			const userUUID = secureStore.getUuid;

			const method = 'PATCH';
			const url = `/notifications/${userId}`;

			const payload = {
				smsByEmail,
				channel,
			};

			const serviceStore = useServiceStore();

			return serviceStore
				.request({
					service: {
						request: {
							url,
							method,
						},
					},
					payload,
				})
				.then(() =>
					source.postMessage(
						{
							name: 'update-session',
							payload: {
								isNotificationByEmail: smsByEmail,
								pushInfo: channel,
							},
							userUUID,
							userId,
						},
						origin
					)
				);
		},

		getNotificationState({ userId }) {
			const method = 'GET';
			const url = `/notifications/${userId}`;

			const serviceStore = useServiceStore();

			return serviceStore
				.request({
					service: {
						request: {
							url,
							method,
						},
					},
				})
				.then(({ data }) => data);
		},

		getPushNotificationState() {
			const method = 'GET';
			const url = '/notifications/push';

			const serviceStore = useServiceStore();

			return serviceStore
				.request({
					service: {
						request: {
							url,
							method,
						},
					},
				})
				.then(({ data }) => data);
		},

		setPushNotificationState(payload) {
			const method = 'POST';
			const url = '/notifications/push';

			const serviceStore = useServiceStore();

			return serviceStore
				.request({
					service: {
						request: {
							url,
							method,
						},
					},
					payload,
				})
				.then(() => {
					this.setIsDevicesListUpdated(Date.now());
					cache.clear();
				});
		},

		modifyUserDevice({ data, source, origin }) {
			const secureStore = useSecureStore();
			const { userId } = data;
			const userUUID = secureStore.getUuid;

			const method = 'POST';
			const url = '/current/user/devices';

			const { modifications } = data;

			const serviceStore = useServiceStore();

			return serviceStore
				.request({
					service: {
						request: {
							url,
							method,
						},
					},
					payload: modifications,
				})
				.then((res) =>
					source.postMessage(
						{
							name: 'modify-user-device',
							payload: res.data,
							userUUID,
							userId,
						},
						origin
					)
				);
		},

		getUserMessages({ paginationKey }) {
			const queryParams = {};
			const method = 'GET';
			const url = '/notifications/audit';

			/* istanbul ignore next */
			if (paginationKey) {
				Object.assign(queryParams, {
					paginationKey,
				});
			}

			const serviceStore = useServiceStore();

			return serviceStore
				.request({
					service: {
						request: {
							url,
							method,
						},
					},
					queryParams,
				})
				.then(({ data: { data = [], paginationKey = null } }) => ({ data, paginationKey }));
		},

		getAssistedUserMessages({ data, source, origin, paginationKey }) {
			const secureStore = useSecureStore();
			const userId = data?.userId;
			const userUUID = secureStore.getUuid;

			const queryParams = {};

			/* istanbul ignore next */
			if (paginationKey) {
				Object.assign(queryParams, {
					...queryParams,
					paginationKey,
				});
			}

			const method = 'GET';
			const url = '/notifications/audit';

			const serviceStore = useServiceStore();

			return serviceStore
				.request({
					service: {
						request: {
							url,
							method,
						},
					},
					queryParams,
				})
				.then((response) => {
					source.postMessage(
						{
							name: 'get-user-messages',
							payload: response.data,
							userUUID,
							userId,
						},
						origin
					);
				});
		},

		requestOption({ data, source, origin }) {
			const secureStore = useSecureStore();
			const { userId, action } = data;
			const userUUID = secureStore.getUuid;
			const url = {
				unlock: `/assisted-channels/users/${userId}/${action}`,
				resetPassword: `/assisted-channels/users/${action}`,
				generateOtp: `/assisted-channels/users/${action}`,
			}[action];
			const method = {
				unlock: 'PATCH',
				resetPassword: 'PATCH',
				generateOtp: 'POST',
			}[action];
			const actionUpperCase = {
				unlock: 'UNLOCK_USER',
				resetPassword: 'RESET_PASSWORD',
				generateOtp: 'GENERATE_OTP',
			}[action];

			const serviceStore = useServiceStore();

			return serviceStore
				.request({
					service: {
						request: {
							url,
							method,
						},
					},
				})
				.then(() => {
					source.postMessage(
						{
							name: 'open-notification',
							text: `INFO.${actionUpperCase}.SUCCESS`,
							userUUID,
							userId,
						},
						origin
					);
					if (action === 'unlock') {
						source.postMessage(
							{
								name: 'update-session',
								payload: { state: 'ACTIVE', loginErrorCount: 0 },
								userUUID,
								userId,
							},
							origin
						);
					}
				})
				.catch((error) => {
					let text = `INFO.${actionUpperCase}.ERROR`;

					/* istanbul ignore else */
					if (error?.response) {
						const { errorCode, details } = error.response?.data || {};
						const UNLOCKING_ERROR = 'C4000000';

						if (errorCode === OTP_NO_TELEPHONE_ERROR) {
							text = 'OTP_ERROR_NO_TELEPHONE';
						}

						if (errorCode === OTP_NO_ACTIVE_USER_ERROR) {
							text = 'OTP_ERROR_USER_NOT_ACTIVE';
						}

						/* istanbul ignore else */
						if (details && errorCode === UNLOCKING_ERROR) {
							const [{ relatedFields }] = details;

							text =
								relatedFields[0] === 'passwordHash'
									? 'INFO.UNLOCK_USER.PASSWORD_ERROR'
									: 'INFO.UNLOCK_USER.PHONE_ERROR';
						}
					}

					source.postMessage(
						{
							name: 'open-notification',
							text,
							userUUID,
							userId,
						},
						origin
					);
				});
		},

		getSirvaseRequests({ data, source, origin, paginationKey, requestId, payload }) {
			const queryParams = {};
			let url = '/customer-support/request';
			const method = 'GET';

			/* istanbul ignore else */
			if (paginationKey) {
				Object.assign(queryParams, {
					...queryParams,
					paginationKey,
				});
			}

			if (requestId) {
				url = url.concat(`/${requestId}`);
			}

			const secureStore = useSecureStore();

			const userUUID = secureStore.getUuid;
			const { userId } = data;

			Object.assign(queryParams, {
				...queryParams,
				type: 'extended',
			});

			const serviceStore = useServiceStore();

			return serviceStore
				.request({
					service: {
						request: {
							url,
							method,
						},
					},
					queryParams,
					payload,
				})
				.then((res) => {
					source.postMessage(
						{
							name: 'sirvase-request',
							payload: res.data,
							userUUID,
							userId,
						},
						origin
					);
				});
		},

		recoverPassword({ documentId, pan, pin }) {
			const appStore = useAppStore();
			const companyId = appStore.getCompanyId;
			const url = '/users/password';
			const method = 'POST';

			const serviceStore = useServiceStore();

			return serviceStore.request({
				service: {
					request: {
						url,
						method,
					},
				},
				payload: {
					document_id: documentId,
					pan,
					pin,
					channel: 'WEB',
					company_id: companyId,
				},
			});
		},

		resetPassword({ oldPassword, password }) {
			const url = '/users/password';
			const method = 'PUT';

			const serviceStore = useServiceStore();

			return serviceStore.request({
				service: {
					request: {
						url,
						method,
					},
				},
				payload: {
					oldPassword,
					password,
				},
			});
		},

		getUserAddress() {
			const url = '/users/user';
			const method = 'GET';

			const serviceStore = useServiceStore();

			return serviceStore
				.request({
					service: {
						request: {
							url,
							method,
						},
					},
				})
				.then(({ data }) => data);
		},

		getUserConsents() {
			const url = `/current/user/gdpr`;
			const method = 'GET';

			const serviceStore = useServiceStore();

			return serviceStore
				.request({
					service: {
						request: {
							url,
							method,
						},
					},
				})
				.then(({ data }) => data);
		},

		updateUserAcceptance({ service }) {
			const url = `/current/user/service-acceptance`;
			const method = 'POST';

			const serviceStore = useServiceStore();

			return serviceStore.request({
				service: {
					request: {
						url,
						method,
					},
				},
				payload: { service },
			});
		},

		updateUserConsents({ consentsGDPR }) {
			const url = `/current/user/gdpr`;
			const method = 'PUT';

			const serviceStore = useServiceStore();

			return serviceStore.request({
				service: {
					request: {
						url,
						method,
					},
				},
				payload: { consentsGDPR },
			});
		},

		blockUser() {
			const url = `/users/user/lock`;
			const method = 'PATCH';

			const serviceStore = useServiceStore();

			return serviceStore.request({
				service: {
					request: {
						url,
						method,
					},
				},
			});
		},

		getRole() {
			const url = `/current/user/role`;
			const method = 'GET';

			const serviceStore = useServiceStore();

			return serviceStore.request({
				service: {
					request: {
						url,
						method,
					},
				},
			});
		},
		//Más CBNK
		getRelatedCustomer() {
			const url = `/current/user/relatedCustomer`;
			const method = 'GET';

			const serviceStore = useServiceStore();

			return serviceStore
				.request({
					service: {
						request: {
							url,
							method,
						},
					},
				})
				.then(({ data }) => {
					const customerData = data?.data;
					this.setRelatedCustomerData(customerData);
					return customerData;
				});
		},

		clearCache() {
			cache.clear();
		},
	},
});
