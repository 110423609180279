import { Factory } from 'miragejs';
import { faker } from '@faker-js/faker';

faker.seed(1);

export default Factory.extend({
	channel: 'push',
	date: () => faker.date.past().toISOString(),
	id: '12345678',
	messageText: () => faker.lorem.paragraph(),
	status: 'sent',
	type: 'OTP',
});
