import { Server, Model, Response, belongsTo, hasMany } from 'miragejs';
import IdentityManager from './identity-manager';
import seeds from './seeds';
import FactoryAgent from './factory-agent';
import FactoryUser from './factory-user';
import FactoryProduct from './factory-products';
import FactoryMovements from './factory-movements';
import FactoryAssets from './factory-assets';
import FactoryTransfers from './factory-transfers';
import FactoryMessages from './factory-message';
import FactoryDocuments from './factory-document';
import FactoryNotifications from './factory-notification';
import FactoryCommunications from './factory-communication';
import FactoryContract from './factory-contracts';
import FactorySignature from './factory-signature';
import FactoryCorreosCash from './factory-correos-cash';
import FactoryProfile from './factory-profile';
import FactorySirvase from './factory-sirvase';
import FactoryCreditor from './factory-creditor';
import FactoryDebit from './factory-debit';
import FactoryMarketplace from './factory-marketplace';
import FactorySubscription from './factory-subscription';
import FactoryConsent from './factory-consent';
import FactoryIntervener from './factory-intervener';
import FactoryConnection from './factory-connection';
import FactoryDevice from './factory-device';
import FactoryDeviceSession from './factory-device-session';
import FactoryOnboarding from './factory-onboarding';
import FactoryCampaigns from './factory-campaigns';
import FactoryCampaignCommunications from './factory-campaign-communications';
import FactoryCampaignsOutbound from './factory-campaigns-outbound';
import FactoryCommunicationSegmentations from './factory-communication-segmentations';
import FactoryOutboundSegmentation from './factory-outbound-segmentation';
import FactoryUserConsent from './factory-user-consent';
import FactoryCancellableList from './factory-cancellable-list';
import FactorySavedAccount from './factory-saved-accounts';
import AgentSession from './mock-agent-session';
import AgentLogin from './mock-agent-login';
import AgentUsers from './mock-agent-users';
import AgentUserActions from './mock-agent-user-actions';
import AgentImpersonations from './mock-agent-impersonations';
import AgentUpdate from './mock-agent-onboarding';
import AgentAssociateUuids from './mock-associate-uuids';
import AgentOnboardings from './mock-onboardings';
import AgentCollectiveVertical from './mock-collective-vertical';
import AgentCampaigns from './mock-campaigns';
import AgentCampaignCommunication from './mock-campaign-communication';
import AgentCommunicationSegmentation from './mock-communication-segmentation';
import AgentOutboundSegmentation from './mock-outbound-segmentation';
import AgentCampaignOutbound from './mock-campaign-outbound';
import AgentOnboardingDetail from './mock-onboarding-detail';
import Login from './mock-login';
import KeyExchange from './mock-key-exchange';
import UserPassword from './mock-user-password';
import Validation from './mock-validation';
import Products from './mock-products';
import ProductsAlias from './mock-product-alias';
import ProductPosition from './mock-product-position';
import ProductCardCVV from './mock-product-card-cvv';
import ProductCardPIN from './mock-product-card-pin';
import ProductCardPAN from './mock-product-card-pan';
import Portfolio from './mock-product-portfolio';
import Movements from './mock-movements';
import Movement from './mock-movement';
import Documents from './mock-documents';
import Repayments from './mock-product-repayments';
import Receipts from './mock-product-receipts';
import Assets from './mock-assets';
import Cashflow from './mock-cashflow';
import Transfers from './mock-transfers';
import PersonalDetails from './mock-personal-details';
import MoveMoneyOrigins from './mock-move-money-origins';
import RelatedCustomer from './mock-related-customer';
import MoveMoneyDestinations from './mock-move-money-destinations';
import MoveMoneySimulate from './mock-move-money-simulate';
import MoveMoneyTransfer from './mock-move-money-transfer';
import MoveMoneyLimits from './mock-move-money-limits';
import MoveMoneyValidateIBAN from './mock-move-money-validate-iban';
import MoveMoneyDelete from './mock-move-money-delete';
import MoveMoneyModify from './mock-move-money-modify';
import MoveMoneyFavoriteValidate from './mock-move-money-favorite-validate';
import MoveMoneyFavoriteAccounts from './mock-move-money-saved-accounts';
import MoveMoneySaveFavoriteAccount from './mock-move-money-saved-accounts-add';
import MoveMoneyEditFavoriteAccount from './mock-move-money-saved-accounts-edit';
import MoveMoneyDeleteFavoriteAccount from './mock-move-money-saved-accounts-delete';
import MoveMoneyCurrencies from './mock-move-money-currencies';
import CommunicationsFeedback from './mock-communications-feedback';
import CommunicationsUnreadMessages from './mock-communications-unread-messages';
import CommunicationsMessages from './mock-communications-messages';
import CommunicationsDocuments from './mock-communications-documents';
import NotificationsAudit from './mock-notifications-audit';
import CommunicationsEditMessages from './mock-communications-edit-messages';
import CommunicationsEditDocuments from './mock-communications-edit-documents';
import CommunicationsDownloadDocument from './mock-communications-download-document';
import CommunicationsCommunications from './mock-communications-communications';
import CommunicationsPatch from './mock-communications-patch';
import CommunicationsTypes from './mock-communications-types';
import BizumONGs from './mock-bizum-ongs';
import Contracts from './mock-contracts';
import ContractsPatch from './mock-contracts-patch';
import WebviewLogin from './mock-webview-login';
import SSOLogin from './mock-sso-login';
import Session from './mock-session';
import Notifications from './mock-notifications';
import NotificationsPush from './mock-notifications-push';
import NotificationsRestrictions from './mock-notifications-restrictions';
import Signatures from './mock-signatures';
import BizumActive from './mock-bizum-active';
import BizumSettings from './mock-bizum-settings';
import BizumSignUp from './mock-bizum-signup';
import BizumDelete from './mock-bizum-delete';
import BizumTerms from './mock-bizum-terms';
import BizumSendMoney from './mock-bizum-send-money';
import BizumWhitelist from './mock-bizum-whitelist';
import BizumAddressBook from './mock-bizum-addressbook';
import BizumSaveMovement from './mock-bizum-save-movement';
import BizumSelae from './mock-bizum-selae';
import BizumSubscription from './mock-bizum-subscriptions';
import BizumConnection from './mock-bizum-connections';
import BizumConnectionHistory from './mock-bizum-connections-history';
import CorreosCash from './mock-correos-cash';
import CancellableList from './mock-cancellable-list';
import TransfersCancel from './mock-transfer-cancel';
import Withholdings from './mock-product-withholdings';
import Profiles from './mock-profiles';
import MovementComment from './mock-comment';
import KeepAlive from './mock-keep-alive';
import DeleteCache from './mock-delete-cache';
import Ontime from './mock-ontime';
import SirvaseEfectuar from './mock-sirvase';
import LighthouseHybridization from './mock-lighthouse-hybridization';
import CardOperative from './mock-card-operative';
import CardDeposit from './mock-card-deposit';
import AccountTransferSimulate from './mock-account-transfer-simulate';
import SimulateSettlement from './mock-settlement-simulation';
import Debits from './mock-debits';
import Marketplace from './mock-marketplace';
import Bolsacaminos from './mock-bolsacaminos';
import UserBiometric from './mock-user-biometric';
import UserDevices from './mock-user-devices';
import ApplePay from './mock-apple-pay';
import GooglePay from './mock-google-pay';
import GooglePayValidate from './mock-google-pay-validate';
import Interveners from './mock-interveners';
import Provisioning from './mock-provisioning';
import SheetModeWhitelist from './mock-sheet-mode-whitelist';
import UserConsents from './mock-user-consents';
import UserBlock from './mock-user-block';
import HybridEventsHandler from './hybrid-events-handler';
import FinancedOperations from './mock-financed-operations';
import Preferences from './mock-preferences';
// eslint-disable-next-line no-duplicate-imports
import Campaigns from './mock-campaigns';
import SignatureState from './mock-signature-state';
import AgentDetailCampaignOutboud from './mock-campaign-detail-outbound';

export function makeServer({ environment = 'development' } = {}) {
	const server = new Server({
		environment,

		models: {
			agent: Model.extend({
				session: belongsTo(),
				onboardings: hasMany(),
				campaigns: hasMany(),
			}),
			user: Model.extend({
				session: belongsTo(),
				products: hasMany(),
				messages: hasMany(),
				documents: hasMany(),
				notifications: hasMany(),
				contracts: hasMany(),
				signatures: hasMany(),
				correosCash: hasMany(),
				cancellableList: hasMany(),
				profiles: hasMany(),
				sirvase: hasMany(),
				marketplace: hasMany(),
				subscriptions: hasMany(),
				consents: hasMany(),
				connections: hasMany(),
				devices: hasMany(),
				communications: hasMany(),
				userConsents: hasMany(),
				campaign: hasMany(),
				savedAccounts: hasMany(),
			}),
			session: Model.extend({
				agent: belongsTo(),
				user: belongsTo(),
			}),
			process: Model,
			product: Model.extend({
				user: belongsTo(),
				interveners: hasMany(),
				movements: hasMany(),
				transfers: hasMany(),
				assets: hasMany(),
				products: hasMany(),
				creditors: hasMany(),
			}),
			movement: Model.extend({ product: belongsTo() }),
			asset: Model.extend({ product: belongsTo() }),
			transfer: Model.extend({ product: belongsTo() }),
			message: Model.extend({ user: belongsTo() }),
			document: Model.extend({ user: belongsTo() }),
			notification: Model.extend({ user: belongsTo() }),
			communication: Model.extend({ user: belongsTo() }),
			contract: Model.extend({
				user: belongsTo(),
				profiles: hasMany(),
			}),
			signature: Model.extend({ user: belongsTo() }),
			correosCash: Model.extend({ user: belongsTo() }),
			cancellableList: Model.extend({ user: belongsTo() }),
			profile: Model.extend({
				user: belongsTo(),
				contract: belongsTo(),
			}),
			sirvase: Model.extend({ user: belongsTo() }),
			creditor: Model.extend({
				products: belongsTo(),
				debits: hasMany(),
			}),
			debit: Model.extend({ creditors: belongsTo() }),
			marketplace: Model.extend({ user: belongsTo() }),
			subscription: Model.extend({ user: belongsTo() }),
			consent: Model.extend({ user: belongsTo() }),
			connection: Model.extend({ user: belongsTo() }),
			device: Model.extend({
				user: belongsTo(),
				deviceSessions: hasMany(),
			}),
			deviceSession: Model.extend({ device: belongsTo() }),
			userConsent: Model.extend({ user: belongsTo() }),
			intervener: Model.extend({ product: belongsTo() }),
			onboarding: Model.extend({
				agent: belongsTo(),
				onboardingData: hasMany(),
			}),
			onboardingData: Model.extend({
				onboarding: belongsTo(),
			}),
			campaign: Model.extend({ user: belongsTo() }),
			campaignCommunication: Model.extend({ user: belongsTo() }),
			// communicationSegmentation: Model.extend({ user: belongsTo() }),
			savedAccount: Model.extend({ user: belongsTo() }),
		},

		factories: {
			agent: FactoryAgent,
			user: FactoryUser,
			product: FactoryProduct,
			movement: FactoryMovements,
			transfer: FactoryTransfers,
			asset: FactoryAssets,
			message: FactoryMessages,
			document: FactoryDocuments,
			notification: FactoryNotifications,
			communication: FactoryCommunications,
			contract: FactoryContract,
			signature: FactorySignature,
			correosCash: FactoryCorreosCash,
			profile: FactoryProfile,
			sirvase: FactorySirvase,
			creditor: FactoryCreditor,
			debit: FactoryDebit,
			marketplace: FactoryMarketplace,
			subscription: FactorySubscription,
			consent: FactoryConsent,
			intervener: FactoryIntervener,
			connection: FactoryConnection,
			device: FactoryDevice,
			deviceSession: FactoryDeviceSession,
			onboarding: FactoryOnboarding,
			campaign: FactoryCampaigns,
			campaignOutbound: FactoryCampaignsOutbound,
			campaignCommunication: FactoryCampaignCommunications,
			communicationSegmentation: FactoryCommunicationSegmentations,
			outboundSegmentation: FactoryOutboundSegmentation,
			userConsent: FactoryUserConsent,
			cancellableList: FactoryCancellableList,
			savedAccount: FactorySavedAccount,
		},

		identityManagers: {
			application: IdentityManager,
		},

		seeds,

		routes() {
			this.urlPrefix = import.meta.env.VITE_APP_ENDPOINT;
			this.namespace = '';
			this.timing = 400;

			this.post('/key-exchange', KeyExchange.bind(this));
			this.post('/associate-uuids', AgentAssociateUuids.bind(this));
			this.post('/login', Login.bind(this));
			this.post('/assisted-channels/login', AgentLogin.bind(this));
			this.post('/assisted-channels/v2/login', AgentLogin.bind(this));
			this.patch('/session', AgentSession.bind(this));
			this.get('/assisted-channels/users', AgentUsers.bind(this));
			this.patch('/assisted-channels/users/:action', AgentUserActions.bind(this));
			this.patch('/assisted-channels/users/:userId/:action', AgentUserActions.bind(this));
			this.post('/assisted-channels/users/:action', AgentUserActions.bind(this));
			this.post('/assisted-channels/impersonations', AgentImpersonations.bind(this));
			this.get('/onboardings', AgentOnboardings.bind(this));
			this.get('/users/verticals', AgentCollectiveVertical.bind(this));
			this.get('/users/collectives', AgentCollectiveVertical.bind(this));
			this.get('/campaigns', AgentCampaigns.bind(this));
			this.get(
				'/communications/campaign/:campaignId/communication',
				AgentCampaignCommunication.bind(this)
			);
			this.get(
				'/communications/campaign/:campaignId/communication/:communicationId/Segmentation',
				AgentCommunicationSegmentation.bind(this)
			);
			this.get('/notifications/campaign/:companyId', AgentCampaignOutbound.bind(this));
			this.get(
				'/notifications/campaign/:companyId/:campaignId/segmentation',
				AgentOutboundSegmentation.bind(this)
			);
			this.get(
				'/notifications/campaign/:companyId/:campaignId',
				AgentDetailCampaignOutboud.bind(this)
			);
			this.delete(
				'/notifications/campaign/:companyId/:campaignId/channel/:type/language/:language',
				AgentDetailCampaignOutboud.bind(this)
			);
			this.get('/onboardings/:onboardingId', AgentOnboardingDetail.bind(this));
			this.patch('/onboardings/:onboardingId', AgentUpdate.bind(this));
			this.post('/users/password', UserPassword.bind(this));
			this.put('/users/password', UserPassword.bind(this));
			this.get('/users/user', PersonalDetails.bind(this));
			this.get('/current/user/role', PersonalDetails.bind(this));
			this.get('/users/:userId/relatedCustomer', RelatedCustomer.bind(this));
			this.get('/current/user/relatedCustomer', RelatedCustomer.bind(this));
			this.get('/users/:userId/gdpr', UserConsents.bind(this));
			this.put('/users/:userId/gdpr', UserConsents.bind(this));
			this.get('/current/user/gdpr', UserConsents.bind(this));
			this.put('/current/user/gdpr', UserConsents.bind(this));
			this.patch('/users/user/lock', UserBlock.bind(this));
			this.patch('/validation/:processId', Validation.bind(this));
			this.get('/products', Products.bind(this));
			this.get('/products/:productId', Products.bind(this));
			this.get('/products/:productId/position', ProductPosition.bind(this));
			this.get('/products/:productId/managedProducts', Portfolio.bind(this));
			this.get('/products/:productId/repayments', Repayments.bind(this));
			this.get('/products/:productId/receipts', Receipts.bind(this));
			this.get('/products/:productId/cvv', ProductCardCVV.bind(this));
			this.get('/products/:productId/pin', ProductCardPIN.bind(this));
			this.get('/products/:productId/pan', ProductCardPAN.bind(this));
			this.put('/products/:productId/alias', ProductsAlias.bind(this));
			this.get('/products/:productId/holderCertificate', Documents.bind(this));
			this.get('/products/:productId/movements', Movements.bind(this));
			this.get('/products/:productId/impositions', Movements.bind(this));
			this.get('/products/:productId/movements/withholdings', Withholdings.bind(this));
			this.get('/products/:productId/movements/:movementId', Movement.bind(this));
			this.get('/products/:productId/impositions/:movementId', Movement.bind(this));
			this.get('/products/:productId/movements/:movementId/document', Documents.bind(this));
			this.get(
				'/products/:productId/movements/:movementId/transferCertificate',
				Documents.bind(this)
			);
			this.get('/products/:productId/assets', Assets.bind(this));
			this.get('/products/:productId/assets/:assetId', Products.bind(this));
			this.get('/products/:productId/assets/:assetId/movements', Movements.bind(this));
			this.put('/products/movements/comments/:commentId', MovementComment.bind(this));
			this.delete('/products/movements/comments/:commentId', MovementComment.bind(this));
			this.post(
				'/products/:productId/movements/:movementId/comment',
				MovementComment.bind(this)
			);
			this.get(
				'/products/:productId/lighthouse-hybridization',
				LighthouseHybridization.bind(this)
			);
			this.get('/products/:productId/limits', CardOperative.bind(this));
			this.put('/products/:productId/cardActivation', CardOperative.bind(this));
			this.put('/move-money/:productId/accounttocard', CardDeposit.bind(this));
			this.post(
				'/move-money/:productId/cardToAccountSimulate',
				AccountTransferSimulate.bind(this)
			);
			this.put('/products/:productId/cardBlock', CardOperative.bind(this));
			this.put('/products/:productId/changeCardLimit', CardOperative.bind(this));
			this.put('/products/:productId/usagelimit', CardOperative.bind(this));
			this.get('/products/:productId/paymentmethod', CardOperative.bind(this));
			this.put('/products/:productId/paymentmethod', CardOperative.bind(this));
			this.put('/products/:productId/temporalyCardBlock', CardOperative.bind(this));
			this.post('/products/:productId/simulate-settlement', SimulateSettlement.bind(this));
			this.get('/products/:productId/scps', FinancedOperations.bind(this));
			this.get('/products/:productId/direct-debits/creditors', Debits.bind(this));
			this.get(
				'/products/:productId/direct-debits/creditors/:creditorId/direct-debits',
				Debits.bind(this)
			);
			this.get(
				'/products/:productId/direct-debits/creditors/:creditorId/direct-debits/:directDebitId',
				Debits.bind(this)
			);
			this.get('/products/:productId/elegibility', GooglePay.bind(this));
			this.post('/products/:productId/xpay-validate', GooglePayValidate.bind(this));
			this.get('/products/bolsacaminos', Bolsacaminos.bind(this));
			this.get('/products/sso/bolsacaminos', Bolsacaminos.bind(this));
			this.get('/products/:productId/interveners', Interveners.bind(this));
			this.post('/products/:productId/provision', Provisioning.bind(this));
			this.get('/products/sheetModeAllowList', SheetModeWhitelist.bind(this));
			this.get('/move-money/:productId/direct-debits/:directDebitId', Debits.bind(this));
			this.patch('/move-money/:productId/direct-debits/:directDebitId', Debits.bind(this));
			this.get('/cashflow', Cashflow.bind(this));
			this.get('/move-money/origins', MoveMoneyOrigins.bind(this));
			this.get('/move-money/destinations', MoveMoneyDestinations.bind(this));
			this.get('/move-money/validateBICIBAN', MoveMoneyValidateIBAN.bind(this));
			this.get('/move-money/transfers/limits', MoveMoneyLimits.bind(this));
			this.post('/move-money/transfers/simulate', MoveMoneySimulate.bind(this));
			this.post('/move-money/transfers', MoveMoneyTransfer.bind(this));
			this.get('/move-money/:productId/transfers', Transfers.bind(this));
			this.get('/move-money/:productId/transfers/scheduled', Transfers.bind(this));
			this.get('/move-money/:productId/transfers/favorites', Transfers.bind(this));
			this.get('/move-money/:productId/transfers/:transferId', Transfers.bind(this));
			this.get('/move-money/transfers/detail', Transfers.bind(this));
			this.get('/move-money/favorites', Transfers.bind(this));
			this.get('/move-money/favorites/validate', MoveMoneyFavoriteValidate.bind(this));
			this.get('/move-money/saved-accounts', MoveMoneyFavoriteAccounts.bind(this));
			this.post('/move-money/saved-accounts', MoveMoneySaveFavoriteAccount.bind(this));
			this.patch(
				'/move-money/saved-accounts/:savedAccountId',
				MoveMoneyEditFavoriteAccount.bind(this)
			);
			this.delete(
				'/move-money/saved-accounts/:savedAccountId',
				MoveMoneyDeleteFavoriteAccount.bind(this)
			);
			this.delete('/move-money/transfers/:transferId', MoveMoneyDelete.bind(this));
			this.delete('/move-money/transfers/scheduled/:transferId', MoveMoneyDelete.bind(this));
			this.delete('/move-money/favorites/:transferId', MoveMoneyDelete.bind(this));
			this.put('/move-money/transfers/scheduled/:transferId', MoveMoneyModify.bind(this));
			this.get('/move-money/currencies', MoveMoneyCurrencies.bind(this));
			this.get('/move-money/:productId/transfers/:transferId/document', Documents.bind(this));
			this.post('/move-money/correos-cash/deposit', CorreosCash.bind(this));
			this.get('/move-money/correos-cash/deposits', CorreosCash.bind(this));
			this.get('/move-money/transfers/cancellableList', CancellableList.bind(this));
			this.put('/move-money/transfers/multitransferCancelation', TransfersCancel.bind(this));
			this.post('/user/language', PersonalDetails.bind(this));
			this.post('/user/recoverypwd', UserPassword.bind(this));
			this.put('/user/recoverypwd', UserPassword.bind(this));
			this.get('/current/user/', PersonalDetails.bind(this));
			this.get('/current/user/devices', UserDevices.bind(this));
			this.delete('/current/user/devices/:deviceId', UserDevices.bind(this));
			this.get('/current/user/devices/:deviceId', UserDevices.bind(this));
			this.get('/current/user/sso-login', SSOLogin.bind(this));
			this.put('/current/user/password', UserPassword.bind(this));
			this.post('/current/user/devices/:deviceId/biometric', UserBiometric.bind(this));
			this.delete('/current/user/devices/:deviceId/biometric', UserBiometric.bind(this));
			this.post('/communications/feedback', CommunicationsFeedback.bind(this));
			this.get('/communications/unread-messages', CommunicationsUnreadMessages.bind(this));
			this.get('/communications/messages', CommunicationsMessages.bind(this));
			this.get('/communications/messages/:messageId', CommunicationsMessages.bind(this));
			this.patch('/communications/messages/:messageId', CommunicationsEditMessages.bind(this));
			this.get('/communications/documents', CommunicationsDocuments.bind(this));
			this.get('/notifications/audit', NotificationsAudit.bind(this));
			this.get('/communications/documents/types', CommunicationsTypes.bind(this));
			this.get(
				'/communications/documents/:documentId',
				CommunicationsDownloadDocument.bind(this)
			);
			this.patch(
				'/communications/documents/:documentId',
				CommunicationsEditDocuments.bind(this)
			);
			this.get('/prelogin/communications', CommunicationsCommunications.bind(this));
			this.get('/communications/communications', CommunicationsCommunications.bind(this));
			this.patch(
				'/communications/communications/:communicationId',
				CommunicationsPatch.bind(this)
			);
			this.get('/contracts', Contracts.bind(this));
			this.patch('/contracts/:contractId', ContractsPatch.bind(this));
			this.get('/contracts/:contractId/interveners', Interveners.bind(this));
			this.get('/customer-support/request', SirvaseEfectuar.bind(this));
			this.get('/customer-support/request/:requestId', SirvaseEfectuar.bind(this));
			this.patch('/customer-support/request/:requestId', SirvaseEfectuar.bind(this));
			this.post('/customer-support/request', SirvaseEfectuar.bind(this));
			this.get('/customer-support/typology', SirvaseEfectuar.bind(this));
			this.get('/customer-support/current/user/personal-managers', SirvaseEfectuar.bind(this));
			this.post('/customer-support/current/user/personal-managers', SirvaseEfectuar.bind(this));
			this.get('/notifications/push', NotificationsPush.bind(this));
			this.post('/notifications/push', NotificationsPush.bind(this));
			this.get('/notifications/:userId', Notifications.bind(this));
			this.patch('/notifications/:userId', Notifications.bind(this));
			this.get('/preferences/notificationRestrictions', NotificationsRestrictions.bind(this));
			this.patch('/preferences/notificationRestrictions', NotificationsRestrictions.bind(this));
			this.delete('/preferences/notificationRestrictions', NotificationsRestrictions.bind(this));
			this.post('/webview-login', WebviewLogin.bind(this));
			this.patch('/current/user/language', Session.bind(this));
			this.delete('/session', Session.bind(this));
			this.get('/bizum/active', BizumActive.bind(this));
			this.get('/bizum/settings', BizumSettings.bind(this));
			this.get('/bizum/terms', BizumTerms.bind(this));
			this.get('/bizum/signup', BizumSignUp.bind(this));
			this.post('/bizum/signup', BizumSignUp.bind(this));
			this.put('/bizum/signup', BizumSignUp.bind(this));
			this.delete('/bizum/signup', BizumDelete.bind(this));
			this.get('/bizum/movements', Movements.bind(this));
			this.get('/bizum/movements/:movementId', Movement.bind(this));
			this.put('/bizum/movements/:movementId', BizumSaveMovement.bind(this));
			this.post('/bizum/addressbook', BizumAddressBook.bind(this));
			this.post('/bizum/send-money', BizumSendMoney.bind(this));
			this.put('/bizum/send-money/:bizumId', BizumSendMoney.bind(this));
			this.put('/bizum/send-money/:bizumId/accept', BizumSendMoney.bind(this));
			this.post('/bizum/request-money', BizumSendMoney.bind(this));
			this.put('/bizum/request-money/:bizumId', BizumSendMoney.bind(this));
			this.put('/bizum/request-money/:bizumId/accept', BizumSendMoney.bind(this));
			this.get('/bizum/whitelist', BizumWhitelist.bind(this));
			this.get('/bizum/ongs', BizumONGs.bind(this));
			this.get('/bizum/mits', BizumSubscription.bind(this));
			this.delete('/bizum/mits/:subscriptionId', BizumSubscription.bind(this));
			this.get('/bizum-id/consents', BizumConnection.bind(this));
			this.delete('/bizum-id/consents/:consentId', BizumConnection.bind(this));
			this.get('/bizum-id/consents/:consentId/connections', BizumConnectionHistory.bind(this));
			this.post('/bizum/selae', BizumSelae.bind(this));
			this.get('/bizum/selae/:operationId', BizumSelae.bind(this));
			this.get('/signature', Signatures.bind(this));
			this.delete('/signature/:signatureId', Signatures.bind(this));
			this.put('/signature/:signatureId', Signatures.bind(this));
			this.patch('/signature/:signatureId', Signatures.bind(this));
			this.get('/profiles', Profiles.bind(this));
			this.post('/profiles', Profiles.bind(this));
			this.put('/profiles/:profileId', Profiles.bind(this));
			this.patch('/profiles/:profileId', Profiles.bind(this));
			this.delete('/profiles/:profileId', Profiles.bind(this));
			this.get('/keep-alive', KeepAlive.bind(this));
			this.delete('/cache', DeleteCache.bind(this));
			this.get('/ontime', Ontime.bind(this));
			this.get('/ontime/movements', Ontime.bind(this));
			this.patch('/ontime', Ontime.bind(this));
			this.get('/marketplace/categories', Marketplace.bind(this));
			this.get('/marketplace/offers', Marketplace.bind(this));
			this.get('/marketplace/products', Marketplace.bind(this));
			this.get('/marketplace/products/:productId', Marketplace.bind(this));
			this.get('/marketplace/products/:productId/reviews', Marketplace.bind(this));
			this.post('/marketplace/products/:productId/request', Marketplace.bind(this));
			this.get('/marketplace/requests/:requestId', Marketplace.bind(this));
			this.get('/marketplace/newmoneyamount', Marketplace.bind(this));
			this.get('/xpay/active', ApplePay.bind(this));
			this.get('/xpay/active/:cardId', ApplePay.bind(this));
			this.get('/preferences', Preferences.bind(this));
			this.patch('/preferences', Preferences.bind(this));
			this.get('/communications/campaign', Campaigns.bind(this));
			this.get('/documents/:processId/signature', SignatureState.bind(this));
		},
	});

	if (import.meta.env.VITE_APP_HYBRID === 'true') {
		window.addEventListener('message', (event) => {
			HybridEventsHandler(event, server);
		});
	}

	return server;
}

export function makeServerForCypress({ environment = 'test' } = {}) {
	return new Server({
		environment,
		routes() {
			this.urlPrefix = import.meta.env.VITE_APP_ENDPOINT;
			this.namespace = '';

			const methods = ['get', 'put', 'patch', 'post', 'delete'];
			methods.forEach((method) => {
				this[method]('/*', async (schema, request) => {
					const [status, headers, body] = await window.handleFromCypress(request);
					return new Response(status, headers, body);
				});
			});
		},
	});
}
