import { Factory } from 'miragejs';
import { faker } from '@faker-js/faker';

faker.seed(1);

export default Factory.extend({
	id: () => faker.string.uuid(),
	name: () => faker.word.sample(),
	default: false,
});
