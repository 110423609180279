<template>
	<div
		:data-theme="verticalTheme"
		id="vuesoma"
	>
		<img
			class="printable-logo media-screen-hide"
			src="@local-assets/img/logo-horizontal-alt.svg"
			alt=""
			aria-hidden="true"
		/>
		<span
			class="printable-margin-text media-screen-hide"
			aria-hidden="true"
		>
			{{ legalIdentity }}
		</span>
		<router-view :inert="inert" />
		<w-notification />
		<w-modal />
		<router-view name="modal" />
		<c-fullscreen-spinner
			v-if="status"
			:type="type"
			data-testid="spinner"
		/>
		<component
			v-if="verticalThemeComponent"
			:is="verticalThemeComponent"
		/>
	</div>
</template>

<script>
import WModal from '@/widgets/w-modal.vue';
import { mapState, getActivePinia } from 'pinia';
import { importLocale } from '@plugins/i18n';
import { throttle } from 'lodash';
import CFullscreenSpinner from '@components/c-fullscreen-spinner';
import WNotification from '@widgets/w-notification';
import MConfirm from '@modals/m-confirm';
import MNoInternet from '@modals/m-no-internet';
import { defineAsyncComponent } from 'vue';
import bugsnagClient from '@plugins/bugsnag';

const ONE_SECOND = 1000;
const TEN_SECONDS = 10000;
const MAX_MINUTES_FOR_SESSION_EXPIRATION = 5;
const MAX_MINUTES_FOR_SESSION_EXPIRATION_ALERT = 4.5;
let ONCE = true;

const isPrd = window.VITE_APP_CONFIG?.env === 'prd';
const isVitest = import.meta.env?.VITEST === 'true';

export default {
	name: 'App',

	components: {
		CFullscreenSpinner,
		WNotification,
		WModal,
	},

	computed: {
		...mapState(useAuthStore, ['isLoggedIn']),
		...mapState(useSessionStore, ['theme', 'lang', 'userId', 'vertical']),
		...mapState(useAppStore, ['legalIdentity', 'isHybridSky', 'isHybrid', 'isEmbedded']),
		...mapState(useLoadingStore, ['status', 'type']),
		...mapState(useServiceStore, ['lastRequestTimestamp', 'offline']),
		...mapState(useDeviceStore, ['isPWA']),
		...mapState(useSecureStore, ['uuid']),
		...mapState(useModalStore, ['lastOpened']),

		inert({ lastOpened }) {
			return Boolean(lastOpened);
		},

		verticalThemeComponent({ vertical }) {
			const verticalType = {
				1: 'eng',
				2: 'sal',
			}[vertical];

			if (verticalType) {
				return defineAsyncComponent(
					() => import(`@cbnk/vertical/${verticalType}/vertical-theme.vue`)
				);
			}

			return null;
		},
	},

	data() {
		return {
			expiredSessionTimeOut: 0,
			alertSessionTimeout: 0,
			expiredSessionMaxMinutes: MAX_MINUTES_FOR_SESSION_EXPIRATION,
			alertSessionMaxMinutes: MAX_MINUTES_FOR_SESSION_EXPIRATION_ALERT,
			verticalTheme: 'cbnk-light',
		};
	},
	methods: {
		setVerticalTheme(theme) {
			const verticalType = {
				0: 'cbnk',
				1: 'eng',
				2: 'sal',
			}[this.vertical];
			this.verticalTheme = `${verticalType}-${theme}`;

			return importLocale(this.lang, this.vertical);
		},

		async alertForSessionAboutToExpire() {
			const { lastRequestTimestamp, alertSessionMaxMinutes } = this;
			const minutesFromLastRequest =
				(new Date(Date.now()).getTime() - lastRequestTimestamp) / 1000 / 60;

			/* istanbul ignore else */
			if (minutesFromLastRequest > alertSessionMaxMinutes) {
				const props = {
					title: this.$t('INFO.SESSION_ABOUT_EXPIRE.TITLE'),
					text: this.$t('INFO.SESSION_ABOUT_EXPIRE.DESC'),
					acceptText: this.$t('ACTIONS.CONTINUE_SESSION'),
					cancelText: this.$t('ACTIONS.CLOSE_SESSION'),
					modal: true,
				};

				clearInterval(this.alertSessionTimeout);
				const confirmation = await useModalStore().open({
					component: MConfirm,
					props: props,
				});
				// Refresh session if user clicks accept or closes the modal
				// TODO: When we close a modal, should emit an event such as 'modal-closed'
				if (confirmation || confirmation === null) {
					this.alertSessionTimeout = setInterval(
						this.alertForSessionAboutToExpire,
						ONE_SECOND
					);

					const updateRSISession = this.$route.name !== 'sso-rsi';

					return useAuthStore().refresh({
						skipLogout: false,
						updateRSISession,
					});
				}

				useAuthStore().logout();
			}
		},

		checkForSessionExpiration() {
			const { lastRequestTimestamp, expiredSessionMaxMinutes } = this;
			const minutesFromLastRequest =
				(new Date(Date.now()).getTime() - lastRequestTimestamp) / 1000 / 60;

			/* istanbul ignore else */
			if (minutesFromLastRequest > expiredSessionMaxMinutes) {
				clearInterval(this.expiredSessionTimeOut);

				return useAuthStore().passiveLogout();
			}
		},

		handleRefresh() {
			if (this.isLoggedIn) {
				useAuthStore().refresh({
					skipLogout: false,
					updateRSISession: false,
				});
			}
		},
	},

	watch: {
		/* istanbul ignore next */
		isPWA: {
			handler(value) {
				if (value) {
					document.addEventListener(
						'contextmenu',
						(e) => {
							/* istanbul ignore next */
							if (e.target.tagName.toLowerCase() !== 'input') {
								e.preventDefault();
							}
						},
						false
					);
				}
			},
		},

		/* istanbul ignore next */
		isLoggedIn: {
			handler(value) {
				var cookieBotWidget = document.getElementById('CookiebotWidget');
				if (!value) {
					if (cookieBotWidget) {
						cookieBotWidget.style.display = 'inline';
					}
					clearInterval(this.expiredSessionTimeOut);
					clearInterval(this.alertSessionTimeout);

					if (this.$route.name !== 'login') {
						this.$router.push({ name: 'login' });
					}
				} else {
					if (cookieBotWidget) {
						cookieBotWidget.style.display = 'none';
					}
					this.expiredSessionTimeOut = setInterval(this.checkForSessionExpiration, ONE_SECOND);
					this.alertSessionTimeout = setInterval(
						this.alertForSessionAboutToExpire,
						ONE_SECOND
					);
				}
			},
		},

		theme: {
			immediate: true,
			handler(value) {
				document.documentElement.dataset.theme = value;
				this.setVerticalTheme(value);
			},
		},

		/* istanbul ignore next */
		lang: {
			handler(value) {
				importLocale(value, this.vertical);
			},
		},

		/* istanbul ignore next */
		uuid: {
			handler(next, prev) {
				// This is needed for assisted channels,
				// when the session is falsy we have to notify assisted channels
				if (prev && !next && window.parent) {
					window.parent.postMessage(
						{
							name: 'error',
							userId: this.userId,
							sessionUUID: prev,
							error: 'session-expired',
						},
						'*'
					);
				}
			},
		},

		offline: {
			async handler(value) {
				if (value) {
					await useModalStore().open(MNoInternet);
					useServiceStore().setOffline(false);

					if (this.isHybridSky || this.isHybrid) {
						useAuthStore().logout();
					}
				}
			},
		},

		vertical: {
			immediate: true,
			handler() {
				this.setVerticalTheme(this.theme);
			},
		},
	},

	updated() {
		this.$nextTick(() => {
			// When skyline is embedded sometimes the frame is mounted
			// but it is not visible so the splash transition can't be listened
			// to fix it we remove the splash screen after the first update
			if (ONCE && this.isEmbedded && document.getElementById('splash')) {
				document.getElementById('splash')?.remove();
				ONCE = false;
			}
		});
	},

	mounted() {
		// We guarantee that all child components have been mounted
		// and the entire view has been rendered
		this.$nextTick(() => {
			window.dispatchEvent(new Event('app-is-mounted'));
		});
	},

	created() {
		/* istanbul ignore next */
		this.$router.afterEach((to, from) => {
			useBugsnagStore().log({
				type: 'navigation',
				title: 'Router push',
				from: from.path,
				to: to.path,
			});
		});

		window.dispatchEvent(new Event('app-about-to'));

		/* istanbul ignore next */
		if (!isPrd && !isVitest && !this.isEmbedded) {
			const { log } = console;

			[
				'native-hide-loading',
				'native-copy-text',
				'native-open-external-link',
				'native-save-file',
				'native-share-file',
				'native-exit-app',
				'native-open-whatsapp',
				'native-open-email',
				'native-open-telephone',
				'native-set-theme',
				'native-get-wallet-status',
				'native-get-wallet-state',
				'native-get-wallet-data',
				'native-add-card-to-wallet',
				'native-start-card-provisioning',
				'splashscreen-unload',
			].forEach((eventName) => {
				window.addEventListener(
					eventName,
					(event) => {
						log({
							type: event?.type,
							detail: { ...event?.detail },
						});
					},
					false
				);
			});

			window.addEventListener(
				'message',
				(event) => {
					if (event?.data?.name) {
						log({
							name: event.data.name,
							data: event?.data,
						});
					}
				},
				false
			);
		}
		this.handleThrottleRefresh = throttle(this.handleRefresh, TEN_SECONDS);
		window.addEventListener('click', this.handleThrottleRefresh, true);
		window.addEventListener('scroll', this.handleThrottleRefresh, true);
		window.addEventListener('keydown', this.handleThrottleRefresh, true);

		window.addEventListener('CookiebotOnDecline', () => {
			if (window.Cookiebot.changed) {
				window.location.reload();
			}
		});

		useBugsnagStore().install(bugsnagClient);

		window.addEventListener('offline', () => {
			useServiceStore().setOffline(true);
		});
		window.addEventListener('online', () => {
			useServiceStore().setOffline(false);
		});

		window.addEventListener('message', ({ data, source, origin, ports }) => {
			if (data?.name === 'bridge-store-dispatch') {
				const store = {
					dispatch(action, payload) {
						const [storeName, methodName] = action.split('/'); // Separamos el store y la acción
						const realStoreName = `m-${storeName}`;
						const store = getActivePinia()._s.get(realStoreName);
						if (typeof store[methodName] === 'function') {
							store[methodName](payload);
						}
					},
				};
				return store
					.dispatch(data.action, data.payload)
					.then((response) => {
						const message = {
							name: 'bridge-store-response',
							request: {
								action: data.action,
								payload: data.payload,
							},
							response: JSON.parse(JSON.stringify(response)),
						};

						return message;
					})
					.catch((error) => {
						const message = {
							name: 'bridge-store-error',
							request: {
								action: data.action,
								payload: data.payload,
							},
							response: error,
						};

						return message;
					})
					.then((message) => {
						ports?.[0]?.postMessage(message);
						window.postMessage(message, '*');
					});
			}

			if (data?.name === 'bridge-network-request') {
				return useServiceStore()
					.request(data.payload)
					.then(({ data: response }) => {
						const message = {
							name: 'bridge-network-response',
							request: data.payload,
							response: JSON.parse(JSON.stringify(response)),
						};

						return message;
					})
					.catch(({ response }) => {
						const message = {
							name: 'bridge-network-error',
							request: data.payload,
							response: JSON.parse(JSON.stringify(response)),
						};

						return message;
					})
					.then((message) => {
						ports?.[0]?.postMessage(message);
						window.postMessage(message, '*');
					});
			}

			if (data?.name === 'create-session') {
				useAppStore().setIsEmbedded();

				return useAuthStore().createUserSession({
					data,
					source,
					origin,
				});
			}

			if (data?.name === 'new-update') {
				const userUUID = useSecureStore().getUuid;

				if (window.parent) {
					window.parent.postMessage(
						{
							name: 'reload',
							userUUID,
						},
						'*'
					);
				}
			}

			if (data?.name === 'request-access') {
				return useAuthStore()
					.authorizeAccess({
						data,
						source,
						origin,
					})
					.then(() => this.$router.push({ name: 'home' }));
			}

			if (data?.name === 'get-notification-mode') {
				return useUserStore().getNotificationMode({
					data,
					source,
					origin,
				});
			}

			if (data?.name === 'change-notification-mode') {
				return useUserStore().changeNotificationMode({
					data,
					source,
					origin,
				});
			}

			if (data?.name === 'set-push-notification') {
				return useUserStore().setPushNotificationState({
					activated: data?.activated,
				});
			}

			if (data?.name === 'modify-user-device') {
				return useUserStore().modifyUserDevice({
					data,
					source,
					origin,
				});
			}

			if (data?.name === 'get-user-devices') {
				return useUserStore().getAssistedDevices({
					data,
					source,
					origin,
				});
			}

			if (data?.name === 'get-user-messages') {
				const paginationKey = data.payload;
				return useUserStore().getAssistedUserMessages({
					data,
					source,
					origin,
					paginationKey,
				});
			}

			if (data?.name === 'request-option') {
				return useUserStore().requestOption({
					data,
					source,
					origin,
				});
			}

			if (data?.name === 'sirvase-request') {
				const params = data.payload;
				return useUserStore().getSirvaseRequests({
					data,
					source,
					origin,
					...params,
				});
			}
		});

		if (window.parent) {
			window.parent.postMessage({ name: 'vuesoma-is-ready' }, '*');
		}

		window.postMessage({ name: 'vuesoma-is-ready' }, '*');
	},
};
</script>
<style lang="scss" scoped>
#vuesoma {
	height: 100%;
	display: flex;
}

[inert] {
	overflow: hidden;
}
</style>

<style lang="scss">
@use '@design/index.scss';

body {
	@extend %typo-m-book;
}

.v__internal-a11y-hide {
	position: absolute;
	clip: rect(1px, 1px, 1px, 1px);
	background: 0 0;
	color: transparent;
	width: 1px;
	height: 1px;
	overflow: hidden;
}

:not([tabindex='-1']):not([type='radio']):focus:focus-visible::before {
	content: '';
	display: block;
	position: absolute;
	border-radius: var(--focus-ring-radius, inherit);
	top: calc(-1 * var(--focus-ring-padding, 0px) - 1px);
	bottom: calc(-1 * var(--focus-ring-padding, 0px) - 1px);
	left: calc(-1 * var(--focus-ring-padding, 0px) - 1px);
	right: calc(-1 * var(--focus-ring-padding, 0px) - 1px);
	border: 1px solid RGB(var(--color-secondary));
	box-shadow: 0 0 0 1px RGB(var(--color-secondary));
}

@media (pointer: fine), (hover) {
	::-webkit-scrollbar {
		width: 16px;
	}

	::-webkit-scrollbar-thumb {
		border-radius: 10px;
		box-shadow:
			inset 0 0 3px rgba(0, 0, 0, 0.2),
			inset 0 0 0 30px rgba(0, 0, 0, 0.3);
		border: 4px solid transparent;
	}

	::-webkit-scrollbar-thumb:window-inactive {
		visibility: hidden;
	}
}

.media-screen-hide {
	display: none !important;
}

@media print {
	body {
		--color-gradient-header: none;
		background: white !important;
	}

	.media-screen-hide {
		display: block !important;
	}

	.media-print-hide {
		display: none !important;
	}

	.printable-margin-text {
		position: fixed;
		z-index: var(--z-index-base) 0;
		left: 0;
		top: 0;
		bottom: 0;
		text-align: center;
		writing-mode: vertical-rl;
		transform: rotate(-180deg);
		color: RGB(var(--color-text-primary));
		opacity: 0.6;
	}

	.printable-logo {
		position: fixed;
		top: 0;
		left: 0;
		z-index: var(--z-index-over-page);
		height: 32px;
	}
}
</style>
