import { Factory, trait } from 'miragejs';
import { faker } from '@faker-js/faker';

faker.seed(1);

const types = [
	{
		id: '1',
		name: 'Cuentas',
	},
	{
		id: '2',
		name: 'Depósitos',
	},
	{
		id: '3',
		name: 'Inversión',
	},
	{
		id: '4',
		name: 'Paquetes',
	},
	{
		id: '5',
		name: 'Tarjetas',
	},
	{
		id: '6',
		name: 'Financiación',
	},
	{
		id: '7',
		name: 'A contratar',
	},
];

const notes = `
# La cuenta corriente para tú día a día.
##Descubre todas sus ventajas:
- **0€ de comisión de mantenimiento de la cuenta(1).**
- **Transferencias SEPA online Gratuitas.**
- Envía y recibe pagos de manera sencilla y rápida@ **usando Bizum**.
- Si lo necesitas, podrás asociar una Tarjeta de Débito Transparente (2) @una vez contratada.
> (1) **0€ de comisión de mantenimiento en la cuenta** en el caso de que algún titular de la cuenta cumpla con alguno de los siguientes requisitos a cierre del trimestre natural anterior a la fecha de aplicación:
TAB:
- La suma de sus posiciones en Banco Caminos como titular en cuentas corrientes, depósitos a la vista, depósitos a plazo, fondos de inversión, valores, seguros, planes de pensiones, préstamos (importe pendiente de pago) y créditos (importe dispuesto) es igual o superior a 100.000€.
- Ser licenciado en Farmacia o Ingeniería de Caminos, Canales y Puertos.
- Ser accionista de Banco Caminos y tener un número de acciones igual o superior a 500.
- Tener una edad inferior a 35 años. En este caso, para la no aplicación de la comisión es necesario que todos los titulares de la cuenta cumplan con esta condición.

> **En caso de no cumplir con al menos 1 de los requisitos mencionados, el coste de mantenimiento de cuenta será de 35 € al trimestre, por cuenta de pago, siempre que el cliente tenga una antigüedad mínima en la Entidad de 6 meses. La fecha de adeudo será el día 5 de cada trimestre natural.**

> **Ejemplos representativos:**
TAB:
- **TAE Escenario cobro de la comisión de mantenimiento de cuenta 0 € / trimestre y la comisión de emisión/renovación de la tarjeta de débito 0€ / anuales:**

>TAB: **Tipo interés acreedor del 0%. TAE = 0,00%. Total comisiones = 0 euros. Total interés = 0 €**

>TAB:
- **TAE Escenario cobro de la comisión de mantenimiento de cuenta 35 € / trimestre y la comisión de emisión/renovación de la tarjeta de débito 15€ / anuales:**

>TAB:  **Tipo interés acreedor del 0%. TAE = -3,08%. Total comisiones =155 euros. Total interés = 0 €**

>TAB: **(*Calculadas siempre bajo un saldo de 5.000 euros)**

> (2) **0€ de comisión de mantenimiento de la tarjeta de débito** en el caso de que algún titular de la cuenta cumpla con alguno de los siguientes requisitos a cierre del trimestre natural anterior a la fecha de aplicación:
TAB:
- La suma de sus posiciones en Banco Caminos como titular en cuentas corrientes, depósitos a la vista, depósitos a plazo, fondos de inversión, valores, seguros, planes de pensiones, préstamos (importe pendiente de pago) y créditos (importe dispuesto) es igual o superior a 100.000€.
-  Ser licenciado en Farmacia o Ingeniería de Caminos, Canales y Puertos.
- Ser accionista de Banco Caminos y tener un número de acciones igual o superior a 500.
- Tener una edad inferior a 35 años. En este caso, para la no aplicación de la comisión es necesario que todos los titulares de la cuenta cumplan con esta condición.

>En caso de no cumplir con al menos 1 de los requisitos mencionados, el coste de mantenimiento de la tarjeta de débito será de 15 € anual por tarjeta emitida.

>**(2) Sustitución/duplicado de tarjeta de débito: 8€.**

>**(2) Disposición de efectivo en cajeros a débito:** sin comisión en las 4 primeras disposiciones al mes en cualquier cajero de España, sin límite de importe. A partir del 5º reintegro, para cualquier importe, se cobrará la comisión que cobre la entidad propietaria del cajero.
`;

const riskAlerts =
	'||@@|El cobro de la prestación o el ejercicio del derecho de rescate sólo es posible en caso de acaecimiento de alguna de las contingencias o supuestos excepcionales de liquidez regulados en la normativa de planes y fondos de pensiones.||@@|El valor de los derechos de movilización, de las prestaciones y de los supuestos excepcionales de liquidez depende del valor de mercado de los activos del fondo de pensiones y puede provocar pérdidas relevantes.||!|Producto financiero que no es sencillo y puede ser difícil de comprender.||';

const relatedAccountTypeId = faker.string.alphanumeric(11);

export default Factory.extend({
	product: trait({
		category: () => faker.helpers.arrayElement(types),
		description: () => faker.lorem.paragraph(),
		id: () => faker.string.uuid(),
		requirements: {
			allowInterveners: () => faker.datatype.boolean(),
			requireDeposit: () => faker.datatype.boolean(),
			requireSubscription: () => faker.datatype.boolean(),
			requireContribution: () => faker.datatype.boolean(),
		},
		name: () => faker.finance.accountName(),
		smallImage: () =>
			faker.image.url({
				category: 'nature',
				width: 260,
				height: 135,
			}),
		type: () => faker.helpers.arrayElement(types),
		rate: () =>
			faker.number.int({
				min: 1,
				max: 5,
			}),
		validFrom: () => faker.date.recent().toISOString(),
		validTo: () => faker.date.soon().toISOString(),
	}),

	reviews: trait({
		userName: () => faker.person.firstName(),
		comment: () => faker.lorem.paragraph(),
		rate: () =>
			faker.number.int({
				min: 1,
				max: 5,
			}),
		date: () => faker.date.past().toISOString(),
	}),

	offers: trait({
		id: () => faker.string.uuid(),
		category: () => faker.helpers.arrayElement(types),
		type: () => faker.helpers.arrayElement(types),
		name: () => faker.finance.accountName(),
		description: () => faker.lorem.paragraph(),
		rate: () =>
			faker.number.float({
				min: 1,
				max: 5,
			}),
		smallImage: () =>
			faker.image.url({
				category: 'nature',
			}),
		image: () =>
			faker.image.url({
				category: 'nature',
			}),
		validFrom: faker.date.past().toISOString(),
		validTo: faker.date.soon().toISOString(),
	}),

	detail: trait({
		category: () => faker.helpers.arrayElement(types),
		contract:
			'https://www.bancofar.es/documents/66903/315431/TyC+Bizum/ed07cc17-39c5-48fc-a8a5-f57720fef4f9',
		coreProductTypeId: () => faker.string.alphanumeric(11),
		description: () => faker.lorem.paragraph(),
		id: () => faker.number.int(10),
		smallImage: () =>
			faker.image.url({
				category: 'nature',
			}),
		image: () =>
			faker.image.url({
				category: 'nature',
			}),
		requirements: {
			allowInterveners: () => faker.datatype.boolean(),
			requireDeposit: () => faker.datatype.boolean(),
			requireSubscription: () => faker.datatype.boolean(),
			requireContribution: () => faker.datatype.boolean(),
		},
		name: () => faker.finance.accountName(),
		notes,
		related_account_type_id: relatedAccountTypeId,
		type: () => faker.helpers.arrayElement(types),
		rate: () =>
			faker.number.int({
				min: 1,
				max: 5,
			}),
		rateDistribution: [0, 3, 2, 1, 2],
		riskAlerts,
		riskGuaranteeFund: () => faker.lorem.paragraphs(1),
		riskLevelMax: () => faker.number.int(6),
		riskLevelMin: 1,
		riskLevelText: () => faker.lorem.paragraphs(1),
		totalReviews: () => faker.number.int(10),
		validTo: () => faker.date.soon().toISOString(),
		validFrom: () => faker.date.recent().toISOString(),
	}),
});
