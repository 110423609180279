<template>
	<transition-group
		name="slide"
		tag="div"
		class="w-notification"
		role="status"
		aria-live="polite"
	>
		<article
			v-for="({ component: { text, template, props } }, id) in notificationStore.queue"
			:key="`n-${id}`"
			role="alert"
			aria-role="alert"
			@click="notificationStore.close({ id })"
			class="w-notification__message text-m-medium"
		>
			<span v-if="text">{{ text }}</span>
			<span v-else-if="template">
				<component
					:is="template"
					v-bind="props"
				/>
			</span>
		</article>
	</transition-group>
</template>

<script>
/* istanbul ignore file */
import { useNotificationsStore } from '@modules/notifications/m-notifications';
export default {
	name: 'w-notification',
	computed: {
		notificationStore() {
			return useNotificationsStore();
		},
	},
};
</script>

<style lang="scss" scoped>
.w-notification {
	display: flex;
	position: fixed;
	width: calc(100% - 20px);
	right: 10px;
	bottom: 80px;
	left: 10px;
	flex-direction: column;
	align-items: center;
	z-index: var(--z-index-notification);
}

.w-notification__message {
	color: RGB(var(--color-text-white));
	width: 100%;
	max-width: 600px;
	padding: 20px;
	border-radius: var(--border-radius-s);
	margin-bottom: 10px;
	background-color: RGB(var(--color-primary-dark));
	flex-grow: 1;
}

.slide {
	will-change: transform;
}

.slide-enter-active {
	transition:
		opacity 300ms ease,
		transform 300ms ease;
}

.slide-leave-active {
	transition:
		opacity 200ms ease,
		transform 200ms ease;
}

.slide-enter,
.slide-leave-to {
	opacity: 0;
	transform: translateY(30px);
}
</style>
