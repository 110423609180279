<template>
	<div class="w-modal">
		<section
			v-for="(layer, index) in layers"
			:key="index"
		>
			<keep-alive>
				<component
					:id="layer.name"
					:ref="`externalComponent-${layer.uid}`"
					:is="layer.item.component"
					:key="layer.uid"
					v-bind="layer.item.props"
					role="dialog"
					aria-labelledby="modal-title"
					data-testid="modal"
					@keydown.esc="closeModal(layer.uid)"
					@keydown.tab="trapTabKey"
					@close="closeModal(layer.uid)"
				/>
			</keep-alive>
		</section>
	</div>
</template>

<script>
import { useModalStore } from '@modules/modal/m-modal';
import { mapState } from 'pinia';

export default {
	name: 'w-modal',

	computed: {
		...mapState(useModalStore, {
			lastOpenedModal: (state) => state.lastOpened,
			queue: (state) => state.queue,
		}),
		layers({ queue }) {
			return Object.entries(queue).reduce(
				(reducer, [uid, item]) => ({
					...reducer,
					[item.layer]: {
						uid,
						item,
						name: item?.component?.options?.name,
					},
				}),
				{}
			);
		},
	},

	data() {
		return {
			focusedElementBeforeModal: null,
			focusableElementsString: [
				'[a11y-focusable]',
				'a[href]',
				'input:not([disabled])',
				'select:not([disabled])',
				'textarea:not([disabled])',
				'button:not([disabled])',
				'iframe',
				'object',
				'embed',
				'[tabindex]:not([tabindex="-1"])',
				'[contenteditable]',
			].join(', '),
		};
	},

	methods: {
		/**
		 * Closes current modal
		 */
		closeModal(id) {
			const modalStore = useModalStore();
			if (this.$refs[`externalComponent-${id}`][0] !== undefined) {
				modalStore.close({
					id,
					payload: this.$refs[`externalComponent-${id}`][0].value,
				});
			}
		},

		/**
		 * Returns a list of focusable children elements
		 */
		getFocusableItems() {
			return [...this.$el.querySelectorAll(this.focusableElementsString)];
		},

		trapTabKey(evt) {
			const focusableItems = this.getFocusableItems();
			const focusedItemIndex = focusableItems.indexOf(this.$el.ownerDocument.activeElement);

			/* istanbul ignore next */
			if (!focusableItems.length) {
				return;
			}

			const {
				0: firstElement,
				length: numberOfFocusableItems,
				[numberOfFocusableItems - 1]: lastElement,
			} = focusableItems;

			// if focused on first item and user preses back-tab, go to the last focusable item
			/* istanbul ignore else */
			if (evt.shiftKey && focusedItemIndex === 0) {
				lastElement.focus();
				evt.preventDefault();

				// if focused on the last item and user preses tab, go to the first focusable item
			} else if (!evt.shiftKey && focusedItemIndex === numberOfFocusableItems - 1) {
				firstElement.focus();
				evt.preventDefault();
			}
		},

		setFocusToFirstItemInModal() {
			const nodes = this.getFocusableItems();

			if (nodes.length) {
				nodes[0].focus();
			}
		},
	},

	watch: {
		lastOpenedModal(modal) {
			if (modal) {
				/* istanbul ignore else */
				if (!this.focusedElementBeforeModal) {
					this.focusedElementBeforeModal = this.$el.ownerDocument.activeElement;
				}

				this.$nextTick(this.setFocusToFirstItemInModal);
			} else {
				// set focus back to element that had it before the modal was opened
				if (this.focusedElementBeforeModal) {
					this.focusedElementBeforeModal.focus();
				}

				this.focusedElementBeforeModal = null;
			}
		},
	},
};
</script>

<style lang="scss" scoped>
.w-modal {
	z-index: 999;
}
</style>
