const a11yHide = (el) => {
	el.setAttribute('tabIndex', 0);
	el.classList.add('v__internal-a11y-hide');
};

const a11yTel = (el, { value }) => {
	el.setAttribute('aria-label', value.split(' ').join('.').split('').join(' '));
};

const directives = {
	'a11y-tabindex': a11yHide,
	'a11y-tel': a11yTel,
};

export default directives;
