import { defineStore } from 'pinia';

export const useLoadingStore = defineStore('m-loading', {
	state: () => ({
		type: '',
		status: null,
	}),

	actions: {
		updateStatus(value) {
			this.status = value;
		},
		updateType(value) {
			this.type = value;
		},
		start(type) {
			this.updateType(type);
			this.updateStatus(true);
		},
		end() {
			this.updateType('');
			this.updateStatus(false);
		},
	},
});
