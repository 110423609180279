import { Factory } from 'miragejs';
import { faker } from '@faker-js/faker';

faker.seed(1);

const types = [
	{
		id: 1,
		name: 'Regulatorias',
		priority: 1,
	},
	{
		id: 2,
		name: 'Comerciales',
		priority: 2,
	},
	{
		id: 3,
		name: 'Operativas',
		priority: 3,
	},
];
const spaces = [
	{
		id: 1,
		name: 'prelogin',
	},
	{
		id: 2,
		name: 'globalpos',
	},
	{
		id: 3,
		name: 'banner',
	},
];
const images = [
	'/news/w1cut.jpg',
	'/news/m1cut.jpg',
	'/news/w2cut.jpg',
	'/news/m2cut.jpg',
	'/news/w3cut.jpg',
	'/news/m3cut.jpg',
	'/news/m4cut.jpg',
];

export default Factory.extend({
	type: () => faker.helpers.arrayElement(types),
	space: () => faker.helpers.arrayElement(spaces),
	creationDate: () => faker.date.past().toISOString(),
	title: () => faker.lorem.sentence(),
	body: () => faker.lorem.sentences(10),
	imageURL: () => faker.helpers.arrayElement(images),
	feedback: () => faker.helpers.arrayElement([-1, 0, 1]),
	impressions: 0,
	ctaText: 'Pulse aquí',
	ctaAction: 'open_internal',
	ctaRedirect: () =>
		faker.helpers.arrayElement(['/main/product-group/credit-card', null]),
	deeplinks: { webLink: '/main/personal-area/gdpr' },
	blocking: false,
});
