import { createI18n } from 'vue-i18n';
/*
 * All i18n resources specified in the plugin 'include' option
 * can be loaded at once using the import syntax
 */
import messages from '@intlify/unplugin-vue-i18n/messages';

const datetimeFormats = {
	es: {
		numeric: {
			year: 'numeric',
			month: '2-digit',
			day: '2-digit',
		},
		short: {
			year: 'numeric',
			month: 'short',
			day: 'numeric',
		},
		custom: {
			year: 'numeric',
			month: '2-digit',
			day: '2-digit',
			hour: '2-digit',
			minute: '2-digit',
			second: '2-digit',
		},
		long: {
			year: 'numeric',
			month: 'short',
			day: 'numeric',
			weekday: 'short',
			hour: 'numeric',
			second: 'numeric',
			minute: 'numeric',
			hour12: true,
		},
		month: {
			month: 'long',
		},
		shortMonth: {
			month: 'short',
		},
		a11ydate: {
			year: 'numeric',
			month: 'long',
			day: 'numeric',
		},
		weekday: {
			year: 'numeric',
			month: '2-digit',
			day: '2-digit',
			weekday: 'long',
		},
		withTime: {
			year: 'numeric',
			month: '2-digit',
			day: '2-digit',
			hour: 'numeric',
			minute: '2-digit',
		},
	},
	en: {
		numeric: {
			year: 'numeric',
			month: '2-digit',
			day: '2-digit',
		},
		short: {
			year: 'numeric',
			month: 'short',
			day: 'numeric',
		},
		custom: {
			year: 'numeric',
			month: '2-digit',
			day: '2-digit',
			hour: '2-digit',
			minute: '2-digit',
			second: '2-digit',
		},
		long: {
			year: 'numeric',
			month: 'short',
			day: 'numeric',
			weekday: 'short',
			hour: 'numeric',
			second: 'numeric',
			minute: 'numeric',
			hour12: true,
		},
		month: {
			month: 'long',
		},
		shortMonth: {
			month: 'short',
		},
		a11ydate: {
			year: 'numeric',
			month: 'long',
			day: 'numeric',
		},
		weekday: {
			year: 'numeric',
			month: 'short',
			day: 'numeric',
			weekday: 'short',
		},
		withTime: {
			year: 'numeric',
			month: '2-digit',
			day: '2-digit',
			hour: 'numeric',
			minute: '2-digit',
		},
	},
};

const numberFormats = {
	es: {
		'%': {
			style: 'percent',
			minimumFractionDigits: 2,
		},
	},
	en: {
		'%': {
			style: 'percent',
			minimumFractionDigits: 2,
		},
	},
};

export const i18n = createI18n({
	legacy: false,
	globalInjection: true,
	locale: 'es',
	fallbackLocale: 'es',
	availableLocales: ['es', 'en'],
	messages: messages,
	datetimeFormats,
	numberFormats,
});

export const loadedLanguages = new Set();

const setI18nLanguage = (lang) => {
	i18n.global.locale.value = lang;

	const htmlElement = document.querySelector('html');

	if (htmlElement) {
		htmlElement.setAttribute('lang', lang);
	}

	return lang;
};

const getLocales = async (lang) => {
	// load global lang
	const locales = await import(`@locales/${lang}.json`);

	try {
		// try loading project lang
		const localLocales = await import(`@local-locales/${lang}.json`);

		Object.assign(locales.default, localLocales.default);
	} catch {
		//
	}

	return locales;
};

export const importLocale = async (lang, vertical) => {
	if (vertical) {
		const verticalType = {
			1: 'eng',
			2: 'sal',
		}[vertical];

		const verticalLocales = await import(`@cbnk/vertical/${verticalType}/locales/${lang}.json`);

		return getLocales(lang)
			.then((locales = {}) => {
				const mergedLocales = {
					...locales?.default,
					...verticalLocales.default,
				};

				i18n.global.setLocaleMessage(lang, mergedLocales);

				loadedLanguages.add(lang);

				return setI18nLanguage(lang);
			})
			.catch(() => {});
	}

	// If the language was already loaded
	if (loadedLanguages.has(lang)) {
		return Promise.resolve(setI18nLanguage(lang));
	}
	return getLocales(lang)
		.then((msgs) => {
			i18n.global.setLocaleMessage(lang, msgs.default);
			loadedLanguages.add(lang);

			return setI18nLanguage(lang);
		})
		.catch(() => {});
};
