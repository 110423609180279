/**
 * Devuelve un número en formato moneda.
 *
 * @param {Object} number Objecto con información de valor y moneda.
 * @param {Number} number.amount Valor numérico.
 * @param {Object} number.currency Objeto que dispone la moneda.
 * @param {String} number.currency.id Código ISO de la moneda.
 * @param {Object} options (optional)
 * @param {Boolean} options.sign Devuelve el número junto con su signo.
 * @param {Boolean} options.absolute Devuelve el número sin su signo.
 */
export const formatCurrency = ({ amount, currency }, { sign, absolute, ...options } = {}) => {
	let currencyDisplay = 'symbol';

	if (currency?.id === 'UNDEFINED') {
		return '';
	}

	if (currency?.id === 'USD') {
		currencyDisplay = 'code';
	}

	const value = absolute ? Math.abs(amount) : amount;
	const money = new Intl.NumberFormat('es-ES', {
		style: 'currency',
		currency: currency?.id,
		currencyDisplay: currencyDisplay,
		useGrouping: true,
		...options,
	}).format(value);

	if (sign && !absolute) {
		const isPositive = value > 0;
		if (isPositive) {
			return `+${money}`;
		}
	}

	return money;
};
