import SessionCache from '@plugins/cache';
import { defineStore } from 'pinia';

const cache = new SessionCache('communications');

const hashCode = (str) =>
	str.split('').reduce((red, value) => ((red << 5) - red + value.charCodeAt(0)) | 0, 0);

/* istanbul ignore next */
const compareVersions = (version1, version2) => {
	const validPattern = /^(\d+)\.(\d+)\.(\d+)$/;

	if (!validPattern.test(version1) || !validPattern.test(version2)) {
		return { isValid: false };
	}

	const [, major1, minor1, patch1] = version1.match(validPattern);
	const [, major2, minor2, patch2] = version2.match(validPattern);

	const isGreaterThan = () => {
		if (parseInt(major1, 10) > parseInt(major2, 10)) {
			return true;
		}
		if (parseInt(major1, 10) < parseInt(major2, 10)) {
			return false;
		}

		if (parseInt(minor1, 10) > parseInt(minor2, 10)) {
			return true;
		}
		if (parseInt(minor1, 10) < parseInt(minor2, 10)) {
			return false;
		}

		return parseInt(patch1, 10) > parseInt(patch2, 10);
	};

	const isLowerThan = () => {
		if (parseInt(major1, 10) < parseInt(major2, 10)) {
			return true;
		}
		if (parseInt(major1, 10) > parseInt(major2, 10)) {
			return false;
		}

		if (parseInt(minor1, 10) < parseInt(minor2, 10)) {
			return true;
		}
		if (parseInt(minor1, 10) > parseInt(minor2, 10)) {
			return false;
		}

		return parseInt(patch1, 10) < parseInt(patch2, 10);
	};

	const isEqualTo = () =>
		parseInt(major1, 10) === parseInt(major2, 10) &&
		parseInt(minor1, 10) === parseInt(minor2, 10) &&
		parseInt(patch1, 10) === parseInt(patch2, 10);

	return {
		isValid: true,
		isGreaterThan,
		isLowerThan,
		isEqualTo,
	};
};

import { useServiceStore } from '@modules/service/m-service';
import { useAppStore } from '@local-modules/app/m-app';
import { useDeviceStore } from '@modules/device/m-device';
import { useSecureStore } from '@modules/secure/m-secure';
import { useSessionStore } from '@modules/session/m-session';

export const useCommunicationsStore = defineStore('m-communications', {
	state: () => ({
		unreadMessages: 0,
		isAcceptancesShown: false,
		isCommunicationShown: false,
		hasBlockingCampaigns: null,
		currentCampaign: {},
		blockedCampaignData: {},
	}),

	actions: {
		setBlockedCampaignData(data) {
			this.blockedCampaignData = data;
		},
		setUnreadMessages(value) {
			this.unreadMessages = value;
		},

		setIsAcceptancesShown(value) {
			this.isAcceptancesShown = value;
		},

		setIsCommunicationShown(value) {
			this.isCommunicationShown = value;
		},

		setHasBlockingCampaigns(value) {
			this.hasBlockingCampaigns = value;
		},

		setCurrentCampaign(value) {
			this.currentCampaign = value;
		},

		acceptanceCampaignDisplayed(value) {
			this.setIsAcceptancesShown(value);
		},

		postLoginDisplayed(value) {
			this.setIsCommunicationShown(value);
		},

		postFeedback(payload) {
			const url = '/communications/feedback';
			const method = 'POST';

			const serviceStore = useServiceStore();

			return serviceStore.request({
				service: {
					request: {
						url,
						method,
					},
				},
				payload,
			});
		},

		getUnreadMessages() {
			const appStore = useAppStore();

			if (appStore.getIsEmbedded) {
				return Promise.resolve({ data: { unreadMessages: 0 } });
			}

			const url = '/communications/unread-messages';
			const method = 'GET';

			const serviceStore = useServiceStore();

			return serviceStore
				.request({
					service: {
						request: {
							url,
							method,
						},
					},
				})
				.then(({ data: { unreadMessages } }) => {
					/* istanbul ignore else */
					if (unreadMessages !== this.unreadMessages) {
						this.unreadMessages = unreadMessages;
						cache.clear();
					}

					return unreadMessages;
				})
				.catch(() => {
					this.unreadMessages = 0;
					return 0;
				});
		},

		getCommunicationItems({ type, paginationKey, dateFrom, dateTo, searchType }) {
			const appStore = useAppStore();
			if (appStore.getIsEmbedded) {
				return Promise.resolve({ data: [] });
			}

			const key = hashCode('message'.concat(dateFrom, dateTo, searchType));
			const url = `/communications/${type}`;
			const method = 'GET';
			const cacheKey = `${type}/${key}`;
			const queryParams = {};

			/* istanbul ignore next */
			if (cache.has(cacheKey) && !paginationKey) {
				return Promise.resolve(cache.get(cacheKey));
			}

			if (paginationKey) {
				Object.assign(queryParams, { paginationKey });
			}

			if (dateFrom) {
				Object.assign(queryParams, { dateFrom });
			}

			if (dateTo) {
				Object.assign(queryParams, { dateTo });
			}

			if (searchType) {
				Object.assign(queryParams, { type: searchType });
			}

			const serviceStore = useServiceStore();

			return serviceStore
				.request({
					service: {
						request: {
							url,
							method,
						},
					},
					queryParams,
				})
				.then(({ data }) => {
					const response = {
						paging: data.paging,
						data: data.data
							.sort((a, b) => b.creationDate.localeCompare(a.creationDate))
							.map((item) => {
								// We create cache the each item
								cache.set(`${type}/${item?.id}`, item);
								return item;
							}),
					};

					/* istanbul ignore next */
					if (cache.has(cacheKey)) {
						const result = cache.get(cacheKey);
						Object.assign(response.data, result.data.concat(response.data));
					}

					cache.set(cacheKey, response);

					return response;
				});
		},

		getDocuments({ paginationKey, dateFrom, dateTo, searchType } = {}) {
			return this.getCommunicationItems({
				type: 'documents',
				paginationKey,
				dateFrom,
				dateTo,
				searchType,
			});
		},

		getMessages({ paginationKey, dateFrom, dateTo } = {}) {
			return this.getCommunicationItems({
				type: 'messages',
				paginationKey,
				dateFrom,
				dateTo,
			});
		},

		async getDocument(id) {
			let item = cache.get(`documents/${id}`);
			if (!item) {
				const { data } = await this.getDocuments();

				item = data.find(({ id: itemId }) => itemId === id);
			}
			return Promise.resolve(item);
		},

		getDocumentAttachment({ id, iban }) {
			const url = `/communications/documents/${id}`;
			const method = 'GET';

			const serviceStore = useServiceStore();

			return serviceStore
				.request({
					service: {
						request: {
							url,
							method,
						},
					},
					queryParams: { iban },
				})
				.then(({ data }) => data);
		},

		getMessage(id) {
			const url = `/communications/messages/${id}`;
			const method = 'GET';

			const serviceStore = useServiceStore();

			return serviceStore
				.request({
					service: {
						request: {
							url,
							method,
						},
					},
				})
				.then(({ data }) => data);
		},

		markCommunicationItemAsRead({ itemType, type, id }) {
			const url = `/communications/${itemType}/${id}`;
			const method = 'PATCH';
			const cacheData = cache.get(`${itemType}/${id}`);

			cache.set(`${itemType}/${id}`, {
				...cacheData,
				reviewDate: new Date().toISOString(),
			});

			const serviceStore = useServiceStore();

			return serviceStore
				.request({
					service: {
						request: {
							url,
							method,
						},
					},
					payload: { type },
				})
				.catch(() => {});
		},

		markDocumentAsRead({ id, type }) {
			return this.markCommunicationItemAsRead({
				itemType: 'documents',
				id,
				type,
			});
		},

		markMessageAsRead({ id, type }) {
			return this.markCommunicationItemAsRead({
				itemType: 'messages',
				id,
				type,
			});
		},

		async getAnnouncements({ spaceName, refresh }) {
			const isPrelogin = spaceName === 'prelogin';
			const appStore = useAppStore();
			if (appStore.getIsEmbedded) {
				return Promise.resolve({ data: [] });
			}
			if (spaceName === 'postlogin' && this.isCommunicationShown) {
				return Promise.resolve({ data: [] });
			}

			const spaces = { prelogin: 'prelogin' };
			const spaceIds = {
				postlogin: 2,
				banner: 3,
			};
			const expURL = spaces[spaceName] || 'communications';
			const url = `/${expURL}/communications`;
			const queryParams = isPrelogin
				? {
						language: useSessionStore().lang,
						company: appStore.getCompanyId,
					}
				: { spaceId: spaceIds[spaceName] };
			const key = `communications/${spaceName}`;
			const MAX_SLIDES = 3;

			if (refresh) {
				SessionCache.clear(key);
			}

			/* istanbul ignore next */
			if (cache.has(key)) {
				return Promise.resolve(cache.get(key));
			}

			/* istanbul ignore next */
			if (isPrelogin) {
				await useSecureStore().createSession();
			}

			const serviceStore = useServiceStore();

			return serviceStore
				.request({
					service: {
						request: {
							url,
							method: 'GET',
						},
					},
					queryParams,
				})
				.then(({ data: { data } }) => {
					const filteredData = data.filter((slide) => {
						if (slide.destinationChannel) {
							const deviceStore = useDeviceStore();
							const isWebBlocking = slide.destinationChannel === 'WEB';
							const userOnMobile = appStore.getIsHybrid || appStore.getIsHybridSky;

							// If it is blocking for web users and user is on mobile or it blocks mobiles and user is on web, it will not be showed.
							/* istanbul ignore else */
							if ((isWebBlocking && userOnMobile) || (!isWebBlocking && !userOnMobile)) {
								return false;
							}

							// If it is blocking for mobile and user is on mobile we have to check a few more fields such as OS and appVersion
							if (!isWebBlocking && userOnMobile) {
								const deviceData = deviceStore;

								const isIOSBlockingAndUserOnAndroid =
									deviceData.getMobileOs === 'IOS' &&
									slide.destinationChannel === 'ANDROID';
								const isAndroidBlockingAndUserOnIOS =
									deviceData.getMobileOs === 'ANDROID' &&
									slide.destinationChannel === 'IOS';

								// If it affects the other OS, slide should not be showed.
								if (isAndroidBlockingAndUserOnIOS || isIOSBlockingAndUserOnAndroid) {
									return false;
								}

								// If it affects different versions, slide should not be showed.
								const versionComparisonResult1 = compareVersions(
									slide.toVersion,
									deviceData.getAppVersion
								);
								const versionComparisonResult2 = compareVersions(
									slide.fromVersion,
									deviceData.getAppVersion
								);

								/* istanbul ignore next */
								if (
									!versionComparisonResult1.isValid &&
									!versionComparisonResult2.isValid
								) {
									return true;
								}

								/* istanbul ignore next */
								if (!versionComparisonResult1.isValid && versionComparisonResult2.isValid) {
									return (
										versionComparisonResult2.isEqualTo() ||
										versionComparisonResult2.isLowerThan()
									);
								}

								/* istanbul ignore next */
								if (versionComparisonResult1.isValid && !versionComparisonResult2.isValid) {
									return (
										versionComparisonResult1.isEqualTo() ||
										versionComparisonResult1.isGreaterThan()
									);
								}

								return (
									(versionComparisonResult1.isEqualTo() ||
										versionComparisonResult1.isGreaterThan()) &&
									(versionComparisonResult2.isEqualTo() ||
										versionComparisonResult2.isLowerThan())
								);
							}
						}

						// Keep the slide if it doesn't meet any of the conditions above
						return true;
					});

					const hasBlockingSlide = filteredData.some((slide) => slide.blocking);

					const orderedData = filteredData.sort((a, b) => {
						const blockingComparison = b.blocking - a.blocking;
						if (blockingComparison !== 0) {
							return blockingComparison;
						}

						const aIsAcceptance = a.ctaAction === 'acceptance' ? 1 : 0;
						const bIsAcceptance = b.ctaAction === 'acceptance' ? 1 : 0;

						if (bIsAcceptance !== aIsAcceptance) {
							return bIsAcceptance - aIsAcceptance;
						}
						return a.type?.priority - b.type?.priority;
					});

					const slicedData = orderedData.slice(0, MAX_SLIDES);

					cache.set(key, slicedData);

					this.setHasBlockingCampaigns(hasBlockingSlide);
					return slicedData;
				})
				.catch(() => {
					/* istanbul ignore next */
					this.setHasBlockingCampaigns(false);
				});
		},

		changeAnnouncementData({ id, data }) {
			const url = `/communications/communications/${id}`;
			const method = 'PATCH';

			const serviceStore = useServiceStore();

			return serviceStore.request({
				service: {
					request: {
						url,
						method,
					},
				},
				payload: { ...data },
			});
		},

		setAnnauncementCtaPressed({ id, ctaPressed }) {
			return this.changeAnnouncementData({
				id,
				data: { ctaPressed },
			});
		},

		setAnnouncementFeedback({ id, feedback }) {
			return this.changeAnnouncementData({
				id,
				data: { feedback },
			});
		},

		setAnnouncementImpression(id) {
			return this.changeAnnouncementData({
				id,
				data: { impressions: 1 },
			});
		},

		getDocumentsTypes() {
			const url = '/communications/documents/types';
			const method = 'GET';

			const serviceStore = useServiceStore();

			return serviceStore
				.request({
					service: {
						request: {
							url,
							method,
						},
					},
				})
				.then(({ data: { data } }) => data);
		},

		hideBanner() {
			cache.set('communications/banner', null);
		},
	},
});
