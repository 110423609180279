<template>
	<div class="c-telephone">
		<a
			v-if="!isHybridSky"
			@click.stop
			class="c-telephone__link text-m-book"
			:href="href"
			v-a11y-tel="number"
			target="_blank"
			data-testid="link"
		>
			{{ number }}
		</a>

		<button
			v-else
			class="c-telephone__button text-m-book"
			v-a11y-tel="number"
			data-testid="button"
			@click.stop="openTelephone"
		>
			{{ number }}
		</button>
	</div>
</template>

<script>
import { mapState } from 'pinia';
import { useAppStore } from '@local-modules/app/m-app';
export default {
	name: 'c-telephone',

	props: {
		number: { type: [String, Number] },
		type: {
			type: String,
			default: 'telephone',
		},
	},

	computed: {
		...mapState(useAppStore, ['isHybridSky']),

		phoneNumber({ number }) {
			const phoneNumber = number?.replace(/\+34|0034|\(\+34\)|\s+/g, '');

			return phoneNumber;
		},

		href({ type, phoneNumber }) {
			return type === 'whatsapp'
				? `https://web.whatsapp.com/send?phone=${phoneNumber}`
				: `tel:${phoneNumber}`;
		},
	},

	methods: {
		openTelephone() {
			if (this.type === 'whatsapp') {
				return window.dispatchEvent(
					new CustomEvent('native-open-whatsapp', {
						detail: {
							tel: `+34${this.phoneNumber}`,
							text: `+34${this.phoneNumber}`,
						},
					})
				);
			}

			return window.dispatchEvent(
				new CustomEvent('native-open-telephone', {
					detail: {
						tel: `+34${this.phoneNumber}`,
						text: `+34${this.phoneNumber}`,
					},
				})
			);
		},
	},
};
</script>

<style lang="scss" scoped>
.c-telephone {
	display: flex;
	position: relative;
	flex-shrink: 0;
}

.c-telephone__link {
	color: inherit;
	font-size: inherit;
}

.c-telephone__button {
	color: inherit;
	user-select: none;
	outline: none;
	appearance: none;
	margin: 0;
	padding: 0;
	border: none;
	background: transparent;
}

.c-telephone__button:focus {
	text-decoration: underline;
}
</style>
