import { defineStore } from 'pinia';

let client = null;

export const useBugsnagStore = defineStore('bugsnag', {
	actions: {
		install(instance) {
			const deviceStore = useDeviceStore();
			const appStore = useAppStore();
			client = instance;
			/* istanbul ignore else */
			if (deviceStore.getDeviceId) {
				client.setUser(deviceStore.getDeviceId);
			}

			client.addMetadata('app', {
				companyId: appStore.getCompanyId,
				isEmbedded: appStore.getIsEmbedded,
				isHybrid: navigator.userAgent.includes('Skybrid'),
				isHybridSky: navigator.userAgent.includes('HybridSky'),
			});
		},

		log({ title, type, ...data }) {
			/* istanbul ignore else */
			if (client) {
				client.leaveBreadcrumb(title, data, type);
			}
		},

		/* istanbul ignore next */
		notify(e) {
			if (client) {
				client.notify(e);
			}
		},

		/* istanbul ignore next */
		setUser(store, id) {
			if (client) {
				client.user = { id };
			}
		},
	},
});
