import { usePublicServiceStore } from '@modules/service/m-public-service';

import { defineStore } from 'pinia';
import { generateKeyPair, decryptRSA, encryptAES, decryptAES, getAESKey } from './cypher';

export const useSecureStore = defineStore('m-secure', {
	// Estado reactivo
	state: () => ({
		baseURL:
			window.VITE_APP_CONFIG.endpoint ||
			import.meta.env.VITE_APP_ENDPOINT ||
			'https://api.grupocaminos.es',
		aesKey: null,
		seed: null,
		symmetricKey: null,
		uuid: null,
		identity: null,
	}),

	getters: {
		getUuid() {
			return this.uuid;
		},
	},

	// Acciones
	actions: {
		// Establece los datos de la sesión
		setSessionData({ seed, symmetricKey, uuid }) {
			this.seed = seed;
			this.symmetricKey = symmetricKey;
			this.uuid = uuid;
			this.identity = Symbol(uuid);
			this.aesKey = null;
		},

		// Limpia los datos de la sesión
		cleanSessionData() {
			this.seed = null;
			this.symmetricKey = null;
			this.uuid = null;
			this.identity = null;
			this.aesKey = null;
		},

		// Establece la clave AES
		setAESKey(key) {
			this.aesKey = key;
		},

		// Crea una sesión híbrida
		async createHybridSession() {
			return new Promise((resolve, reject) => {
				const channel = new MessageChannel();
				const requestTimer = setTimeout(() => {
					reject(new Error('TIMEOUT'));
				}, 10000);

				channel.port1.onmessage = ({ data }) => {
					clearTimeout(requestTimer);
					const isSuccess = data?.status === 200;
					if (isSuccess) {
						this.setSessionData({
							seed: data?.seed,
							uuid: data?.uuid,
							symmetricKey: data?.symmetricKey,
						});
						resolve(data);
					} else {
						reject(data);
					}
				};

				setTimeout(() => {
					window.postMessage(
						{
							name: 'native-get-secure-session',
							detail: {},
						},
						'*',
						[channel.port2]
					);
				}, 400);
			});
		},

		// Crea una nueva sesión con un nuevo uuid
		async createSession() {
			const { privateKey, publicKey } = await generateKeyPair();
			const url = '/key-exchange';
			const method = 'POST';
			const baseURL = this.baseURL;
			const headers = {
				'Content-Type': 'application/json',
				'public-key': publicKey,
			};

			const publicService = usePublicServiceStore();
			return new Promise((resolve, reject) => {
				publicService
					.request({
						url,
						method,
						baseURL,
						headers,
					})
					.then(async (req) => {
						const { data } = req;
						if (data) {
							const response = await decryptRSA(privateKey, data);

							this.setSessionData(response);

							resolve(response);
						} else {
							reject(req);
						}
					})
					.catch(reject);
			});
		},

		// Elimina la sesión actual
		removeSession() {
			this.cleanSessionData();
		},

		// Refresca la sesión, creando una nueva y un nuevo uuid
		async refreshSession() {
			this.removeSession();
			return this.createSession();
		},

		// Devuelve la clave AES
		/*istanbul ignore next*/
		async getAESKey() {
			if (!this.uuid) {
				await this.createSession();
			}

			if (!this.aesKey) {
				const cryptoKey = await getAESKey(this.symmetricKey);
				this.setAESKey(cryptoKey);
				return cryptoKey;
			}

			return this.aesKey;
		},

		// Encripta datos con la clave AES
		async encrypt(data) {
			const key = await this.getAESKey();
			const { seed } = this;

			return encryptAES({
				seed,
				key,
				data,
			});
		},

		// Desencripta una cadena base64 con la clave AES
		async decrypt(data) {
			const key = await this.getAESKey();
			const { seed } = this;

			return decryptAES({
				seed,
				key,
				data,
			});
		},
	},
});
