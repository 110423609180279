import { defineStore } from 'pinia';

import { useServiceStore } from '@modules/service/m-service';

/* istanbul ignore next */
function sortByName(items = []) {
	return items.sort((a, b) => {
		const nameA = a.name.toUpperCase();
		const nameB = b.name.toUpperCase();

		if (nameA < nameB) {
			return -1;
		}

		if (nameA > nameB) {
			return 1;
		}

		return 0;
	});
}

export const useProfilesStore = defineStore('m-profiles', {
	state: () => ({
		defaultProfile: null,
		profiles: [],
		isWelcome: false,
		lastRequestTimestamp: null,
	}),

	getters: {
		getDefaultProfile() {
			return this.defaultProfile;
		},
	},

	actions: {
		setDefaultProfile(profileId) {
			this.defaultProfile = profileId;
		},
		addProfiles(profiles) {
			this.profiles = profiles;
		},
		setWelcome() {
			this.isWelcome = true;
		},
		setLastRequestTimestamp() {
			this.lastRequestTimestamp = +new Date();
		},

		get() {
			const url = '/profiles';
			const method = 'GET';

			this.defaultProfile = null;

			const serviceStore = useServiceStore();

			return serviceStore
				.request({
					service: {
						request: {
							url,
							method,
						},
					},
				})
				.then(({ data }) => {
					const profiles = sortByName(data?.profiles);
					const defaultProfile = profiles?.find(({ isDefault }) => Boolean(isDefault));
					this.addProfiles(profiles);

					if (defaultProfile !== undefined && defaultProfile !== this.defaultProfile)
						this.setDefaultProfile(defaultProfile); //This reloads primary router view on v-main

					return {
						profiles,
						defaultProfile,
					};
				})
				.catch(() => {});
		},

		async getProfile(id) {
			let { profiles } = this;
			const findById = ({ id: profileId }) => profileId === id;
			if (profiles?.length) {
				return profiles?.find(findById);
			}
			profiles = await this.get();

			return profiles?.profiles?.find(findById);
		},

		create({ name, isDefault, productIds }) {
			const url = '/profiles';
			const method = 'POST';
			const payload = {
				name,
				isDefault,
				productIds,
			};

			const serviceStore = useServiceStore();

			return serviceStore
				.request({
					service: {
						request: {
							url,
							method,
						},
					},
					payload,
				})
				.then(() => this.get());
		},

		update({ id, name, isDefault, productIds }) {
			const url = `/profiles/${id}`;
			const method = 'PUT';
			const payload = {
				name,
				isDefault,
				productIds,
			};

			const serviceStore = useServiceStore();

			return serviceStore
				.request({
					service: {
						request: {
							url,
							method,
						},
					},
					payload,
				})
				.then(() => this.get());
		},

		modify({ id, name, isDefault, productIds }) {
			const url = `/profiles/${id}`;
			const method = 'PATCH';
			const payload = {
				name,
				isDefault,
				...productIds,
			};

			const serviceStore = useServiceStore();

			return serviceStore
				.request({
					service: {
						request: {
							url,
							method,
						},
					},
					payload,
				})
				.then(() => this.get());
		},

		delete(id) {
			const url = `/profiles/${id}`;
			const method = 'DELETE';

			const serviceStore = useServiceStore();

			return serviceStore
				.request({
					service: {
						request: {
							url,
							method,
						},
					},
				})
				.then(() => this.get());
		},
	},
});
