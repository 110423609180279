import { Factory } from 'miragejs';
import { faker } from '@faker-js/faker';

export default Factory.extend({
	id: (i) => `0000${i + 1}`,
	name: () =>
		faker.helpers.arrayElement([
			'AVISOS',
			'ADEUDOS Y ABONOS',
			'EXTRACTOS',
			'LIQUIDACIONES',
			'INFORMACIÓN FISCAL',
			'VARIOS',
		]),
	creationDate: () => faker.date.past(1).toISOString(),
});
